<template>
  <div>
    <div class="container">
    <section class="content-header">
      <h1>
        Uredi seznam
        <small></small>
      </h1>
      <ol class="breadcrumb">
        <li><a @click="$router.push('/')">Domov</a></li>
        <!-- <li><a @click="$router.push('/seznami')">Seznami predvajanja</a></li> -->
        <!-- <li><a @click="$router.push('/seznam/' + idSeznama)">{{imeSeznama}}</a></li> -->
        <li v-for="drobtina in breadcrumbs" :key="drobtina.id"><a @click="$router.push(drobtina.naslov)">{{drobtina.ime}}</a></li>
        <li><a @click="$router.push('/seznam/' + idSeznama + '/uredipesmi')">Uredi pesmi seznama</a></li>
      </ol>
    </section>
      <br />
      <div class="col-xs-12">
        <div class="box box-primary">
          <div class="box-header with-border">
            <h3 class="box-title">Uredi seznam</h3>
            <button class="btn btn-danger pull-right" @click="izbrisiSeznam()"><i class="fa fa-trash"></i></button>
          </div>
          <!-- /.box-header -->
          <!-- form start -->
          <table class="table table-bordered">
            <tbody><tr>
                <th>Staro ime<br/>
                  <input v-model="izraz" class="form-control">
                </th>
                <th>Ime<br/>
                  <input v-model="imeIzraz" class="form-control">
                </th>
                <th>Avtor<br/>
                  <input v-model="avtorIzraz" class="form-control">
                </th>
                <th>album<br/>
                  <input v-model="albumIzraz" class="form-control">
                </th>
              </tr>
              <tr v-for="ime, i in imena" :key="ime.id">
                <td>{{ime.staro}} <span v-if="ime.ytId" class="text-blue clickable" @click="resetirajIme(i)"><i class="fa fa-undo"></i></span></td>
                <td>{{ime.ime}}</td>
                <td>{{ime.avtor}}</td>
                <td>{{ime.album}}</td>
              </tr>
            </tbody>  
          </table>
          <button class="btn btn-primary btn-block" @click="posodobiPesmi">Potrdi</button>
        </div>
      </div>

    </div>
  </div>
</template>

<script>
import Rest from "./../../storitve/rest";
import Vue from "vue";
import VueSweetalert2 from 'vue-sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';

Vue.use(VueSweetalert2);

export default {
  components: {
  },
  name: "uredi-seznam",
  props: ["idSeznama"],
  data() {
    return {
      pesmi: [],
      breadcrumbs: [],
      imena: [],
      nalozeno: false,
      imeIzraz: "$1",
      avtorIzraz: "",
      albumIzraz: "",
      izraz: "(.*)"
    };
  },
  created: function() {
    this.naloziSeznam();
  },
  methods: {
    resetirajIme (i) {
      let ytId = this.imena[i].ytId
      let th = this
      Rest.request('GET', `/apiKljuci/youtube`).then((data) => {
        let apiKey = data.kljuc
        Rest.request('GET', 'https://www.googleapis.com/youtube/v3/videos?part=snippet&id=' + ytId + '&key=' + apiKey).then((data) => {
          let naslov = data.items[0].snippet.localized.title
          th.pesmi[i]['ime-pesmi'] = naslov
          th.imena[i].staro = naslov
        }).catch((err) => {
          console.log(err)
        })
      })
    },
    nastaviImena () {
      this.imena = []
      for (let i = 0; i < this.pesmi.length; i++) {
        let ime = {
          staro: this.pesmi[i]['ime-pesmi'],
          id: this.pesmi[i].id,
          skrita: this.pesmi[i].skrita,
          ytId: this.pesmi[i].ytId
        }
        var regex = new RegExp(this.izraz, "g");
        var match = regex.exec(ime.staro);
        if (match && this.imeIzraz.length > 0) {
          let beseda = this.imeIzraz
          for (let j = 0; j < match.length; j++) {
            beseda = beseda.replace("$" + (j), match[j])
          }
          ime.ime = beseda
        } else {
          ime.ime = this.pesmi[i]['ime-pesmi']
        }

        if (match && this.avtorIzraz.length > 0) {
          let beseda = this.avtorIzraz
          for (let j = 0; j < match.length; j++) {
            beseda = beseda.replace("$" + (j), match[j])
          }
          ime.avtor = beseda
        } else {
          ime.avtor = this.pesmi[i].avtor
        }
        if (match && this.albumIzraz.length > 0) {
          let beseda = this.albumIzraz
          for (let j = 0; j < match.length; j++) {
            beseda = beseda.replace("$" + (j), match[j])
          }
          ime.album = beseda
        } else {
          ime.album = this.pesmi[i].album
        }
        this.imena.push(ime)
      }
    },
    naloziSeznam: function() {
      Rest.request("POST", `/seznami/pesmi`, { idSeznama: this.idSeznama })
        .then(data => {
          this.pesmi = data.pesmi;
          this.breadcrumbs = data.breadcrumbs
          this.nalozeno = true
          this.nastaviImena()
        })
        .catch(err => {
          console.log(err);
        });
    },
    posodobiPesmi () {
      for (let i = 0; i < this.imena.length; i++) {
        this.posodobiPesem(this.imena[i])
      }
    },
    posodobiPesem: function(ime) {
      let podatki = {
        idPesmi: ime.id,
        album: ime.album,
        avtor: ime.avtor,
        naslov: ime.ime,
        seznami: [],
        skrita: ime.skrita
      }
      console.log(podatki)
      Rest.request('POST', `/pesem/podrobnosti/posodobi`, podatki).then((data) => {
        this.$swal.fire(
            'Uspešno posodobljeno',
            'Pesem uspešno posodobljena',
            'success'
          )
      }).catch((err) => {
        console.log(err)
      })
    }
  },
  watch: {
    izraz: function (newQuestion, oldQuestion) {
      this.nastaviImena()
    },
    imeIzraz: function (newQuestion, oldQuestion) {
      this.nastaviImena()
    },
    avtorIzraz: function (newQuestion, oldQuestion) {
      this.nastaviImena()
    },
    albumIzraz: function (newQuestion, oldQuestion) {
      this.nastaviImena()
    }
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.pikice{
  text-overflow: ellipsis !important;
  overflow: hidden;
  white-space: nowrap;
}
.ghost {
  background: #f8f8f8;
}
.clickable{
  cursor: pointer; 
}
</style>
