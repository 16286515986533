<template>
  <div>
    <div class="container">
      <section class="content-header">
        <h1>
          Nadzorna plošča
          <small></small>
        </h1>
        <ol class="breadcrumb">
          <li><a @click="$router.push('/')">Domov</a></li>
          <li><a @click="$router.push('/nadzornaPlosca')">Nadzorna plošča</a></li>
        </ol>
      </section>
      <br/>
      <div class="col-md-12">
        <div class="box">
          <div class="box-header">
            <h3 class="box-title">Nadzorna Plošča</h3>
          </div>
          <!-- /.box-header -->
          <div class="box-body ">
            
            <div class="col-md-6" v-for="uporabnik in uporabniki" :key="uporabnik.id">
              <div class="box box-widget widget-user-2">
                <!-- Add the bg color to the header using any of the bg-* classes -->
                <div class="widget-user-header bg-yellow">
                  <div class="widget-user-image">
                    <img class="img-circle" src="/profilnaSlika.png" alt="User Avatar">
                  </div>
                  <!-- /.widget-user-image -->
                  <h3 class="widget-user-username">{{uporabnik.uporabniskoIme}}</h3>
                  <h5 class="widget-user-desc">{{uporabnik.admin ? 'Administrator' : 'Uporabnik'}}</h5>
                </div>
                <div class="box-footer no-padding">
                  <ul class="nav nav-stacked">
                    <li><a href="#">Število seznamov <span class="pull-right badge bg-blue">{{uporabnik.stSeznamov}}</span></a></li>
                    <li><a href="#" @click.prevent="nastaviGeslo(uporabnik.id)">Resetiraj geslo</a></li>
                    <li v-for="zeton in uporabnik.zetoni" :key="zeton.id">
                      <a href="#">
                        <button class="btn btn-sm btn-danger pull-right" style="margin-left: 1ex" @click.prevent="odstraniZeton(zeton.id)"><i class="fa fa-trash"></i></button>
                        <span class="pull-right badge bg-aqua">{{zeton.datumPrijave}}</span>
                        <span class="pull-right badge bg-green">verzija: {{zeton.verzija}}</span>
                        {{zeton.userAgent}}
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>

          </div>
          <!-- /.box-body -->
        </div>
      <!-- /.box -->
      </div>
    </div>
  </div>
</template>

<script>
import Rest from './../../storitve/rest'
import Vue from 'vue'
import { EventBus } from './../../storitve/event-bus'
import Pesem from './../skupno/Pesem.vue'

export default {
  components: {
  },
  name: 'koren',
  props: {
  },
  data () {
    return {
      uporabniki: []
    }
  },
  created: function () {
    this.pridobiZgodovino()
  },
  methods: {
    nastaviGeslo(idUporabnika) {
      this.$swal({
        title: "Nastavi geslo",
        text: "Izberi novo geslo",
        input: 'text',
        showCancelButton: true,
        closeOnConfirm: false,
        animation: "slide-from-top",
        inputPlaceholder: "Geslo"
      }).then((inputValue) => {
        if (inputValue.dismiss === "cancel") return false;
        if (inputValue.value === false) return false;

        if (inputValue.value === "") {
          swal.showInputError("Ne sme biti prazno");
          return false
        }

        Rest.request('POST', `/admin/nastaviGeslo`, {idUporabnika: idUporabnika, geslo: inputValue.value}).then((data) => {
          this.$swal.fire(
            'Uspeh',
            'Geslo resetirano',
            'success'
          )
        }).catch((err) => {
          console.log(err)
        })
      });
    },
    odstraniZeton(idZetona) {
      Rest.request('POST', `/admin/odjavi`, {idZetona: idZetona}).then((data) => {
        this.pridobiZgodovino()
      }).catch((err) => {
        console.log(err)
      })
    },
    pridobiZgodovino: function() {
      Rest.request('GET', `/admin/nadzornaPlosca`).then((data) => {
        this.uporabniki = data.uporabniki
        console.log(data)
      }).catch((err) => {
        console.log(err)
      })
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
