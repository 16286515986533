<template>
  <div>
    <div class="wrapper">
      <div class="content-wrapper" :class="[(rojstniDan ? 'rojstni-dan' : '')]">
        <router-view @predvajaj="predvajaj($event)" @predvajajPodcast="predvajajPodcast($event)" @nastavitve="preberiNastavitve()" @predvajajNaslednjo="predvajajNaslednjo($event)"></router-view>
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <div v-if="trenutna && trenutna.podcast">
          <br />
          <br />
          <br />
        </div>
      </div>
    </div>

    <!-- Ustvari zaznamek modal -->
    <div class="modal fade" id="modal-ustvari-zaznamek">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
            <h4 class="modal-title">Ustvari zaznamek</h4>
          </div>
          <div class="modal-body">
            <div class="form-group">
              <label>Čas zaznamka [s]</label>
              <input type="number" class="form-control" placeholder="0" v-model="zaznamekCas" disabled>
            </div>
            <div class="form-group">
              <label>Ime zaznamka</label>
              <input type="text" class="form-control" placeholder="Ime zaznamka" v-model="zaznamekIme">
            </div>
            <div class="form-group">
              <label>Opis zaznamka</label>
              <input type="text" class="form-control" placeholder="Opis zaznamka" v-model="zaznamekOpis">
            </div>

            <div class="form-group">
              <div class="checkbox">
                <label>
                  <input type="checkbox" v-model="zaznamekZasebno">
                  <i class="fa fa-lock" v-if="zaznamekZasebno"></i>
                  <i class="fa fa-unlock" v-else></i>
                  Zasebno
                </label>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-primary pull-right" @click.prevent="dodajZaznamek()">Potrdi</button>
            <button type="button" class="btn btn-default pull-left" data-dismiss="modal">Zapri</button>
          </div>
        </div>
        <!-- /.modal-content -->
      </div>
      <!-- /.modal-dialog -->
    </div>

    <div class="modal fade" id="modal-besedilo" v-if="trenutna && trenutna.besedilo">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
            <h4 class="modal-title">{{trenutna['ime-pesmi']}}</h4>
          </div>
          <div class="modal-body">
            <div style="white-space: pre; text-align: center">
              {{trenutna.besedilo}}
            </div>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-default pull-left" data-dismiss="modal">Zapri</button>
          </div>
        </div>
        <!-- /.modal-content -->
      </div>
      <!-- /.modal-dialog -->
    </div>

    <footer
      class="main-footer footer"
      v-if="predvajalnik"
      v-shortkey.once="['space']"
      @shortkey="playPavza()"
    >
      <div id="podrobno" class="collapse">
        <div style="text-align: center;">
          <div data-toggle="collapse" data-target="#podrobno" style="cursor:pointer; font-size: 30px; padding-bottom: 1ex;">
            <i class="fa fa-angle-down"></i>
          </div>
          <div class="container">
            <div class="col-md-12 col-xs-12" style="text-align: left" v-if="trenutna.podcast">
              <!-- podcast opis -->
              <span style="font-size: 18px">{{trenutna.ime}}</span><br />
              <div style="overflow: auto; max-height:400px; text-align: left;">
                <span v-html="trenutna.opis"></span>
                <span v-if="(pokaziYtLink && $refs.plyr && $refs.plyr.player)">
                  <br/>
                  <a @click="prestaviNaPredvajanjeVidea()">
                    <button class="btn btn-danger"><i class="fa fa-youtube-play"></i> Oglej si na YouTube</button>
                  </a>
                </span>
                <br/>
                <br/>
              </div>
            </div>
            <div class="col-md-12 col-xs-12" v-else>
              <div style="max-height: 400px; overflow: auto;">
                <!-- pesem slika -->
                <div class="ikona-pesmi" v-if="!urlSlike">
                  <i class="fa fa-music"></i>
                </div>
                <div v-else style>
                  <div class="img-thumbnail">
                    <div
                      :style="`background:url('` + urlSlike + `') center no-repeat; height:204px; width:204px;`"
                    ></div>
                  </div>
                </div>
                <!-- pesem opis -->
                <span style="font-size: 18px">{{trenutna["ime-pesmi"]}}</span>
                <br />
                <span>Avtor: {{trenutna.avtor}}</span>
                <br />
                <span>Album: {{trenutna.album}}</span>
                <br/>
                <br/>
                <div v-if="pokaziVrsto">
                  <div>
                    <ul class="list-group" v-if="vrsta">
                      <li v-for="(pesem, index) in vrsta.slice(0, trenutnaPesem + 1)" :key="pesem['id']" class="list-group-item col-xs-12">
                        <div class="" style="z-index:10">
                          <!-- drag handle -->
                          <span class="handle col-xs-2">
                            <i v-if="!predvajaSeIzVrste && index == trenutnaPesem" class="fa fa-play"></i>
                          </span>
                          <!-- todo text -->
                          <span class="pikice col-xs-8" @click="nastaviNaPesem(index)" style="z-index:10">{{ pesem['ime-pesmi'] }}</span>
                          <span class=" col-xs-2" style="color: #dd4b39; z-index: 10">
                            <i v-if="predvajaSeIzVrste || index != trenutnaPesem" class="pull-right fa fa-trash-o" @click.prevent.stop="odstraniPesem(index, false)"></i>
                          </span>
                        </div>
                      </li>
                      <li v-if="predvajaSeIzVrste" class="list-group-item col-xs-12 bg-gray">
                        <div class="">
                          <!-- drag handle -->
                          <span class="handle col-xs-2">
                            <i class="fa fa-play"></i>
                          </span>
                          <!-- todo text -->
                          <span class="pikice col-xs-8">{{ trenutna['ime-pesmi'] }}</span>
                        </div>
                      </li>
                    </ul>
                    <ul class="list-group" v-if="vrsta">
                      <draggable :list="naslednjaVrsta" class="list-group" ghost-class="ghost" handle=".handle">
                        <li v-for="(pesem, index) in naslednjaVrsta" :key="pesem['id']" class="list-group-item col-xs-12 bg-gray">
                          <div class="">
                            <!-- drag handle -->
                            <span class="handle col-xs-2">
                              <i class="fa fa-ellipsis-v"></i>
                              <i class="fa fa-ellipsis-v"></i>
                            </span>
                            <!-- todo text -->
                            <span class="pikice col-xs-8">{{ pesem['ime-pesmi'] }}</span>
                            <span class=" col-xs-2" style="color:#dd4b39">
                              <i class="pull-right fa fa-trash-o" @click.prevent.stop="odstraniPesem(index, true)"></i>
                            </span>
                          </div>
                        </li>
                      </draggable>
                    </ul>
                    <ul class="list-group" v-if="vrsta">
                      <li v-for="(pesem, index) in vrsta.slice(trenutnaPesem + 1, vrsta.length)" :key="pesem['id']" class="list-group-item col-xs-12">
                        <div class="">
                          <!-- drag handle -->
                          <span class="handle col-xs-2">
                          </span>
                          <!-- todo text -->
                          <span class="pikice col-xs-8" @click="nastaviNaPesem(trenutnaPesem + 1 + index)">{{ pesem['ime-pesmi'] }}</span>
                          <span class=" col-xs-2" style="color:#dd4b39">
                            <i class="pull-right fa fa-trash-o" @click.prevent.stop="odstraniPesem(trenutnaPesem + 1 + index, false)"></i>
                          </span>
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>

                <!-- <div class=""> -->
                <div class="col-sm-4 col-xs-12">
                  <button class="btn btn-primary form-control" style="margin-top: 1ex" @click="pokaziSkrijVrsto()"><i class="fa fa-server"></i>
                    <span v-if="pokaziVrsto"> Skrij vrsto</span>
                    <span v-else> Pokaži vrsto</span>
                  </button>
                </div>

                <div class="col-sm-4 col-xs-6">
                  <button style="margin-top: 1ex" class="btn btn-primary form-control" @click="naloziPesem(trenutna)"><i class="fa fa-download"></i>
                    <span> Naloži</span>
                  </button>
                </div>
                

                <div class="col-sm-4 col-xs-6">
                  <button style="margin-top: 1ex" class="btn btn-primary form-control" @click="restartaj"><i class="fa fa-ban"></i>
                    <span> Resetiraj</span>
                  </button>
                </div>

                <div class="col-sm-4 col-xs-6">
                  <button v-if="trenutna.ytId" style="margin-top: 1ex" class="btn btn-primary form-control" @click="odpriPesemYT(trenutna)"><i class="fa fa-youtube-play"></i>
                    <span> Odpri YT</span>
                  </button>
                </div>
                
                <div class="col-sm-4 col-xs-6">
                  <button style="margin-top: 1ex" class="btn btn-primary form-control" @click="kopirajPovezavoDoPesmi()"><i class="fa fa-clipboard"></i>
                    <span> Kopiraj povezavo do pesmi</span>
                  </button>
                </div>

                <div v-if="!trenutna.podcast && !trenutna.idSkupnegaPoslusanja" class="col-sm-4 col-xs-6">
                  <button style="margin-top: 1ex" class="btn btn-primary form-control" @click="zacniSkupnoPredvajanje()"><i class="fa fa-rss"></i>
                    <span> Skupno predvajanje</span>
                  </button>
                </div>
                <div v-if="trenutna.idSkupnegaPoslusanja" class="col-sm-4 col-xs-6">
                  <button style="margin-top: 1ex" class="btn btn-primary form-control" @click="ugasniSkupnoPredvajanje()"><i class="fa fa-ban"></i>
                    <span> Skupno predvajanje</span>
                  </button>
                </div>
                <!-- </div> -->
              </div>
            </div>
          </div>
        </div>
      </div>
      <div v-if="trenutna.podcast" class="col-md-12">
        <div class="row" style="z-index: 1; position: relative">
          <div class="col-xs-4 col-md-2">
            <button class="btn btn-default form-control" @click.prevent="spremeniCas(-30)"><i class="fa fa-rotate-left"></i> - 30s</button>
          </div>
          <div class="col-xs-0 col-md-3">
          </div>
          <div class="col-xs-4 col-md-2">
            <button class="btn btn-default form-control" @click.prevent="dodajZaznamekModal()"><i class="fa fa-bookmark"></i></button>
          </div>
          <div class="col-xs-0 col-md-3">
          </div>
          <div class="col-xs-4 col-md-2">
            <button class="btn btn-default form-control" @click.prevent="spremeniCas(30)"><i class="fa fa-rotate-right"></i> + 30s</button>
          </div>
        </div>
      </div>
      <div v-if="idPredvajanja" class="col-md-12">
        <div class="row" style="z-index: 1; position: relative">
          <div class="col-xs-12 col-md-12">
            <div class="input-group">
              <input type="text" class="form-control" :value="'https://' + domena + '/#/poslusajSkupaj/' + idPredvajanja" disabled>
              <span class="input-group-addon"><i class="fa fa-users"></i> {{stPoslusalcev}}</span>
            </div>
          </div>
        </div>
      </div>
      <div v-if="trenutna.idSkupnegaPoslusanja" class="col-md-12">
        <div class="row" style="z-index: 1; position: relative">
          <div class="col-xs-12 col-md-12">
            <input type="text" class="form-control" :value="'https://' + domena + '/#/poslusajSkupaj/' + trenutna.idSkupnegaPoslusanja" disabled>
          </div>
        </div>
      </div>
      <h4 v-if="trenutna" class="pikice col-md-12">
        <button
          v-if="!trenutna.podcast && !trenutna.idSkupnegaPoslusanja"
          class="btn btn-default pull-right"
          @click="preskociPesem()"
          style="z-index: 2; position: relative"
        >
          <i class="fa fa-step-forward"></i>
        </button>
        <button
          v-if="!trenutna.podcast && !trenutna.idSkupnegaPoslusanja"
          class="btn btn-default pull-right"
          @click="zamenjajNacin()"
          style="z-index: 2; position: relative; margin-right:1ex;"
        >
          <i class="fa fa-long-arrow-right" v-if="nacinPredvajanja === 0"></i>
          <i class="fa fa-retweet" v-if="nacinPredvajanja === 1"></i>
          <i class="fa fa-undo" v-if="nacinPredvajanja === 2"></i>
          <i class="fa fa-random" v-if="nacinPredvajanja === 3"></i>
        </button>

        <button
          v-if="!trenutna.podcast && trenutna.besedilo"
          class="btn btn-default pull-right"
          style="z-index: 2; position: relative; margin-right:1ex;"
          data-toggle="modal" data-target="#modal-besedilo"
        >
          <i class="fa fa-microphone"></i>
        </button>
        
        <button
          v-if="trenutna.idSkupnegaPoslusanja"
          :class="['btn', 'btn-default', 'pull-right']"
          @click="skupnoPredvajanjePlay()"
          style="z-index: 2; position: relative; margin-right:1ex;"
        >
          <i class="fa fa-pause" v-if="skupnoPredvajanjeAktivno"></i>
          <i class="fa fa-play" v-else></i>
        </button>
        <button
          v-if="tihiGumbi"
          class="btn btn-default pull-right"
          @click="zeloTiho(0.02)"
          style="z-index: 2; position: relative; margin-right:1ex;"
        >
          <i class="fa fa-volume-down"></i>
        </button>
        <button
          v-if="tihiGumbi"
          class="btn btn-default pull-right"
          @click="zeloTiho(0.01)"
          style="z-index: 2; position: relative; margin-right:1ex;"
        >
          <i class="fa fa-volume-off"></i>
        </button>
        <span data-toggle="collapse" data-target="#podrobno" style="z-index: 1; position: relative;" v-if="trenutna.podcast">{{trenutna.ime}}</span>
        <span data-toggle="collapse" data-target="#podrobno" style="z-index: 1; position: relative;" v-else>{{trenutna["ime-pesmi"]}} - {{trenutna.avtor}}</span>
      </h4>
      <div :style="trenutna.podcast && glejVideoPodcasta ? 'max-width: 600px; margin-left: auto; margin-right: auto' : ''">
        <vue-plyr
          ref="plyr"
          @ended="konecPesmi(); predvajanje = false"
          @playing="predvajanje = true; pripraviNotification(trenutna); posodobiSkupnoPredvajanje()"
          @pause="predvajanje = false; pripraviNotification(trenutna); posodobiSkupnoPredvajanje()"
          @loadedmetadata="loadedmetadata"
          @ready="loadedmetadata"
          @ratechange="spremenjenaHitrost"
          v-if="!trenutna.predogled"
        >
          <div v-if="trenutna.podcast && glejVideoPodcasta" data-plyr-provider="youtube" :data-plyr-embed-id="trenutna.ytId"></div>
          <audio v-else>
            <source
            :src="trenutna.podcast ? nalaganje.pretvoriLokalniUrl(trenutna.epizodaUrl) : (trenutna.lokalniURL ? trenutna.lokalniURL : nalaganje.pretvoriLokalniUrl((trenutna.offline ? 'http://localhost:7777' : '') + '/glasba/'+trenutna.id+'?token='+dostopniZeton()))"
            type="audio/mp3" />
          </audio>
          
        </vue-plyr>
      </div>
    </footer>

  </div>
</template>

<script>
import draggable from "vuedraggable";
import Rest from "./../storitve/rest";
import { EventBus } from "./../storitve/event-bus";
import Vue from "vue";
import VuePlyr from "vue-plyr";
import nalaganje from './../storitve/nalaganje-pesmi'

// import shortkey from 'vue-shortkey'

Vue.use(require("vue-shortkey"), { prevent: ["input", "textarea"] });

Vue.use(VuePlyr, {
  plyr: {
    // fullscreen: { enabled: true },
  },
  emit: ["ended", "playing", "pause", "loadedmetadata", "ratechange", "ready"]
});

export default {
  name: "OsnovnaStran",
  components: {
    draggable
  },
  data() {
    return {
      sekundeOdPrejsnjegaUpdata: 0,
      vrsta: [],
      naslednjaVrsta: [],
      predvajaSeIzVrste: false,
      trenutnaPesem: 0,
      nepredvajane: [],
      trenutna: null,
      predvajalnik: false,
      nacinPredvajanja: 0,
      tihiGumbi: false,
      darkMode: false,
      urlSlike: null,
      shranjujZgodovino: true,
      predvajanje: false,
      intervalid1: null,
      stSekundPredvajanja: 0,
      prejsnjiCas: new Date().getTime(),
      pokaziYtLink: false,
      zaznamekCas: 0,
      zaznamekIme: '',
      zaznamekOpis: '',
      zaznamekZasebno: true,
      pokaziVrsto: false,
      idPredvajanja: null,
      skupnoPredvajanjeAktivno: true,
      glejVideoPodcasta: false,
      idPoslusalca: Math.floor(Math.random() * 1000000000),
      stPoslusalcev: 0,
      nalaganje
    };
  },
  watch: {
    trenutna: function(val) {
      if (this.trenutna.ytId && this.trenutna.ytId.length > 0) {
        this.pridobiUrlSlike(this.trenutna.ytId);
      } else if (this.trenutna.podcast && this.trenutna.slikaUrl && this.trenutna.slikaUrl.length > 0) {
        this.urlSlike = this.trenutna.slikaUrl;
      } else {
        this.urlSlike = null;
      }
    }
  },
  mounted () {
    if (localStorage.getItem("nacin-predvajanja")) {
      this.nacinPredvajanja = parseInt(
        localStorage.getItem("nacin-predvajanja")
      );
    } else {
      localStorage.setItem("nacin-predvajanja", this.nacinPredvajanja);
    }
    this.preberiNastavitve();

    // let dark = window.matchMedia("(prefers-color-scheme: dark)").matches;

    if (this.shranjujZgodovino) {
      let th = this;
      this.$nextTick(function() {
        th.intervalid1 = setInterval(() => {
          let razlikaCasa = (new Date().getTime() - th.prejsnjiCas) / 1000;
          if (razlikaCasa > 5) {
            razlikaCasa = 1
          }
          th.prejsnjiCas = new Date().getTime();
          th.sekundeOdPrejsnjegaUpdata++
          if (th.sekundeOdPrejsnjegaUpdata >= 5) {
            th.sekundeOdPrejsnjegaUpdata = 0
            // na 5 sek
            if (this.skupnoPredvajanjeAktivno) {
              th.posodobiSkupnoPredvajanje()
            }
          }

          if (th.$refs.plyr && th.$refs.plyr.player.playing) {
            th.stSekundPredvajanja += razlikaCasa;
            // console.log(th.stSekundPredvajanja)
            if (th.stSekundPredvajanja >= 30) {
              th.posljiCasPoslusanja();
              if (th.trenutna.podcast) {
                th.posljiCasPodcasta()
              }
            }
          }
        }, 1000);
      });
    }
  },
  beforeDestroy() {
    clearInterval(this.intervalid1);
    this.posljiCasPoslusanja();
  },
  computed: {
    rojstniDan() {
      let date = new Date();
      console.log(date);
      if (date.getMonth() === 4) {
        // 7.5
        return date.getDate() === 7;
      }
      return false;
    },
    domena() {
      if (window.location.host) {
        return window.location.host
      } else {
        return 'glasba.knez.cloud'
      }
    }
  },
  methods: {
    nastaviDarkMode () {
      document.getElementsByTagName("body")[0].classList.add("skin-dark");
    },
    nastaviLightMode () {
      document.getElementsByTagName("body")[0].classList.remove("skin-dark");
    },
    restartaj () {
      this.predvajalnik = false
      let th = this
      setTimeout(function () {
        th.predvajalnik = true
      }, 1000)
    },
    naloziPesem(pesem) {
      nalaganje.naloziPesem(pesem.id)
    },
    odpriPesemYT(pesem) {
      window.open('https://youtu.be/' + pesem.ytId + '?t=' + Math.round(this.$refs.plyr.player.currentTime), "_blank")
    },
    prestaviNaPredvajanjeVidea () {
      this.glejVideoPodcasta = !this.glejVideoPodcasta
      this.trenutna.mestoPoslusanjaSekunde = this.$refs.plyr.player.currentTime
      this.predvajajPodcast(this.trenutna, true)
      if (this.glejVideoPodcasta) {
        let th = this
        // setTimeout(function () {
        //   th.$refs.plyr.player.play();
        //   th.$refs.plyr.player.currentTime = th.trenutna.mestoPoslusanjaSekunde;
        //   th.pokaziYtLink = true
        // }, 2000)
      }
    },
    skupnoPredvajanjePlay () {
      if (this.skupnoPredvajanjeAktivno) {
        this.skupnoPredvajanjeAktivno = false
        this.$refs.plyr.player.pause();
      } else {
        this.skupnoPredvajanjeAktivno = true
        this.$refs.plyr.player.play();
        this.posodobiSkupnoPredvajanje()
      }
    },
    ugasniSkupnoPredvajanje () {
      this.idPredvajanja = null
      this.trenutna = null
      this.ustaviPredavanje()
    },
    zacniSkupnoPredvajanje () {
      if (this.idPredvajanja) {
        Rest.request("POST", `/skupnoPoslusanje/ustaviPoslusanje`, {idPoslusanja: this.idPredvajanja})
        .then(data => {}).catch(err => {console.log(err);});
        this.idPredvajanja = null
      } else {
        if (this.trenutna && this.$refs.plyr.player.currentTime) {
          let zaokrozeneSekunde = Math.round(this.$refs.plyr.player.currentTime);
          let postdata = {
            idPesmi: this.trenutna.id,
            cas: zaokrozeneSekunde
          }
          Rest.request("POST", `/skupnoPoslusanje/ustvariPoslusanje`, postdata)
          .then(data => {
            this.idPredvajanja = data.idPredvajanja
          })
          .catch(err => {
            console.log(err);
          });
        }
      }
    },
    posodobiSkupnoPredvajanje () {
      if (!this.skupnoPredvajanjeAktivno) {
        return;
      }
      if (this.trenutna && this.trenutna.idSkupnegaPoslusanja) {
        let postdata = {
          idPoslusanja: this.trenutna.idSkupnegaPoslusanja,
          idPoslusalca: this.idPoslusalca
        }
        Rest.request('post', `/skupnoPoslusanje/pridobiSkupnoPoslusanje`, postdata).then((data) => {
          let pesem = data.pesem
          pesem.mestoPoslusanjaSekunde = data.sekunde
          pesem.mestoPoslusanjatrenutek = new Date().getTime()
          pesem.idSkupnegaPoslusanja = data.id
          if (this.trenutna && this.trenutna.id === data.idPesmi) {
            let zaokrozeneSekunde = Math.round(this.$refs.plyr.player.currentTime);
            if (data.sePredvaja) {
              this.$refs.plyr.player.play();
            } else {
              this.$refs.plyr.player.pause();
            }
            if (Math.abs(data.sekunde - zaokrozeneSekunde) > 2) {
              this.trenutna.mestoPoslusanjaSekunde = data.sekunde;
              this.$refs.plyr.player.currentTime = data.sekunde;
            }
          } else {
            this.predvajajPesem(pesem);
          }
        }).catch((err) => {
          // konec predvajanja
          this.trenutna = null
          this.ustaviPredavanje()
        })
      } else {
        if (this.idPredvajanja) {
          if (this.trenutna && this.$refs.plyr.player.currentTime) {
            let zaokrozeneSekunde = Math.round(this.$refs.plyr.player.currentTime);
            let predvajanje = this.$refs.plyr.player.playing;
            let postdata = {
              idPoslusanja: this.idPredvajanja,
              idPesmi: this.trenutna.id,
              cas: zaokrozeneSekunde,
              predvajanje: predvajanje
            }
            Rest.request("POST", `/skupnoPoslusanje/posodobiPoslusanje`, postdata)
            .then(data => {
              this.stPoslusalcev = data.stPoslusalcev
            })
            .catch(err => {
              console.log(err);
            });
          }
        }
      }
    },
    spremenjenaHitrost () {
      console.log('spremenjena hitrost', this.$refs.plyr.player.speed)
      let lokacija = '';
      if (this.trenutna.podcast) {
        lokacija = 'podcast'
      } else {
        lokacija = 'pesem'
      }
      this.nastaviHitrostPredvajanja(lokacija, this.$refs.plyr.player.speed)
    },
    pridobiHitrost () {
      let lokacija = '';
      if (this.trenutna.podcast) {
        lokacija = 'podcast'
      } else {
        lokacija = 'pesem'
      }
      return this.hitrostPredvajanja(lokacija)
    },
    hitrostPredvajanja (lokacija) {
      if (localStorage.getItem("hitrost")) {
        let hitrosti = JSON.parse(localStorage.getItem("hitrost"))
        if (hitrosti[lokacija] != undefined) {
          return hitrosti[lokacija]
        } else {
          return 1.0
        }
      }
      return 1.0
    },
    nastaviHitrostPredvajanja (lokacija, hitrost) {
      let hitrosti = {}
      if (localStorage.getItem("hitrost")) {
        hitrosti = JSON.parse(localStorage.getItem("hitrost"))
      }
      hitrosti[lokacija] = hitrost
      localStorage.setItem('hitrost', JSON.stringify(hitrosti))
    },
    odstraniPesem (index, izNaslednjeVrste) {
      if (izNaslednjeVrste) {
        this.naslednjaVrsta.splice(index, 1)
      } else {
        if (index < this.trenutnaPesem) {
          this.trenutnaPesem--;
        }
        for (let i = 0; i < this.nepredvajane.length; i++) {
          if (this.nepredvajane[i] === index) {
            this.nepredvajane.splice(i, 1)
            i--
          } else if (this.nepredvajane[i] > index) {
            this.nepredvajane[i]--
          }
        }
        this.vrsta.splice(index, 1)
      }
    },
    kopirajPovezavoDoPesmi () {
      let th = this
      navigator.clipboard.writeText('https://' + this.domena + '/#/poglej-pesem/' + this.trenutna.id).then(function() {
        th.$swal.fire({
          position: 'top',
          title: 'Povezava do pesmi kopirana',
          icon: 'success',
          showConfirmButton: false,
          timerProgressBar: true,
          backdrop: null,
          timer: 1000
        })
        console.log('Async: Copying to clipboard was successful!');
      }, function(err) {
        console.error('Async: Could not copy text: ', err);
      });
    },
    pokaziSkrijVrsto () {
      this.pokaziVrsto = !this.pokaziVrsto
    },
    odpriYoutube() {
      window.open('https://youtu.be/' + this.trenutna.ytId   + '?t=' + Math.round(this.$refs.plyr.player.currentTime), "_blank");
    },
    pridobiUrlSlike(video_id) {
      this.urlSlike = "https://i.ytimg.com/vi/" + video_id + "/hqdefault.jpg";
    },
    dodajZaznamekModal() {
      this.zaznamekCas = Math.round(this.$refs.plyr.player.currentTime)
      this.zaznamekIme = ''
      this.zaznamekOpis = ''
      this.zaznamekZasebno = true
      window.jQuery('#modal-ustvari-zaznamek').modal('toggle')
    },
    dodajZaznamek() {
      let postdata = {
        idPodcasta: this.trenutna.idPodcasta,
        idEpizode: this.trenutna.id,
        cas: this.zaznamekCas,
        ime: this.zaznamekIme,
        zasebno: this.zaznamekZasebno,
        opis: this.zaznamekOpis
      }
      Rest.request("POST", `/podcasti/zaznamki/dodaj`, postdata)
        .then(data => {
          window.jQuery('#modal-ustvari-zaznamek').modal('toggle')
        })
        .catch(err => {
          console.log(err);
        });
    },
    spremeniCas(sprememba) {
      this.$refs.plyr.player.forward(sprememba);
    },
    posljiCasPoslusanja() {
      if (Vue.prototype.$brezPovezave) {
        return
      }
      let zaokrozeneSekunde = Math.round(this.stSekundPredvajanja);
      let podcast = false
      if (this.trenutna.podcast) {
        podcast = true
      }
      let postdata = {
        sekunde: zaokrozeneSekunde,
        podcast: podcast
      };
      this.stSekundPredvajanja -= zaokrozeneSekunde;
      Rest.request("POST", `/statistika/dodajCas`, postdata)
        .then(data => {})
        .catch(err => {
          console.log(err);
        });
    },
    posljiCasPodcasta() {
      let zaokrozeneSekunde = Math.round(this.$refs.plyr.player.currentTime);
      let postdata = {
        idEpizode: this.trenutna.id,
        cas: zaokrozeneSekunde
      };
      if (this.trenutna.podcast) {
        postdata.trajanje = Math.round(this.$refs.plyr.player.duration)
      }
      nalaganje.posljiCasPodcasta(postdata)
        .then(data => {})
        .catch(err => {
          console.log(err);
        });
    },
    koncajPodcast() {
      if (nalaganje.jeCordova()) {
        MusicControls.destroy(function() {}, function() {});
      }
      if (Vue.prototype.$brezPovezave) {
        return
      }
      let postdata = {
        idEpizode: this.trenutna.id,
        koncano: true
      };
      Rest.request("POST", `/podcasti/nastaviKoncano`, postdata)
        .then(data => {})
        .catch(err => {
          console.log(err);
        });
    },
    loadedmetadata() {
      if (this.trenutna.podcast) {
        console.log('nastavljanje mesta poslusanja: ', this.trenutna.mestoPoslusanjaSekunde)
        this.$refs.plyr.player.currentTime = this.trenutna.mestoPoslusanjaSekunde;
        if (this.trenutna.ytEpizoda) {
          this.pokaziYtLink = true
        }
        if (this.glejVideoPodcasta) {
          let th = this
          setTimeout(function () {
            th.$refs.plyr.player.play();
          }, 2000)
        }
      }
      if (this.trenutna.idSkupnegaPoslusanja) {
        console.log('nastavljanje mesta', this.trenutna.mestoPoslusanjaSekunde)
        let zamik = this.trenutna.mestoPoslusanjatrenutek ? Math.round((new Date().getTime() - this.trenutna.mestoPoslusanjatrenutek) / 1000) : 0
        this.$refs.plyr.player.currentTime = this.trenutna.mestoPoslusanjaSekunde + zamik;
      }
    },
    dostopniZeton() {
      return localStorage.getItem("sessionAccessToken");
    },
    preberiNastavitve() {
      if (localStorage.getItem("tihi-gumbi")) {
        this.tihiGumbi = localStorage.getItem("tihi-gumbi") === "true";
      }
      if (localStorage.getItem("zgodovina")) {
        this.shranjujZgodovino = localStorage.getItem("zgodovina") === "true";
      }
      if (localStorage.getItem("dark-mode")) {
        this.darkMode = localStorage.getItem("dark-mode") === "true";
      }

      if (this.darkMode) {
        this.nastaviDarkMode()
      } else {
        this.nastaviLightMode()
      }
    },
    zeloTiho(glasnost) {
      this.$refs.plyr.player.volume = glasnost;
    },
    zamenjajNacin() {
      this.nacinPredvajanja = (this.nacinPredvajanja + 1) % 4;
      localStorage.setItem("nacin-predvajanja", this.nacinPredvajanja);
      if (this.nacinPredvajanja === 3) {
        this.nepredvajane = [];
        for (let i = 0; i < this.vrsta.length; i++) {
          if (i !== this.trenutnaPesem) {
            this.nepredvajane.push(i);
          }
        }
      }
    },
    preskociPesem() {
      this.konecPesmi();
    },
    playPavza() {
      this.$refs.plyr.player.togglePlay();
    },
    nastaviNaPesem (indexPesmi) {
      this.predvajaSeIzVrste = false
      this.trenutnaPesem = indexPesmi;
      this.predvajajPesem(this.vrsta[this.trenutnaPesem]);
      let ind = this.nepredvajane.indexOf(indexPesmi);
      if (ind > -1) {
        this.nepredvajane.splice(ind, 1);
      }
    },
    konecPesmi() {
      if (this.trenutna.podcast) {
        this.koncajPodcast()
      }
      if (this.trenutna.idSkupnegaPoslusanja) {
        setTimeout(this.posodobiSkupnoPredvajanje, 3000)
      } else {
        if (this.naslednjaVrsta.length > 0) {
          this.predvajajPesem(this.naslednjaVrsta.shift());
          this.predvajaSeIzVrste = true
          console.log('predvaja se iz vrste')
        } else {
          this.predvajaSeIzVrste = false
          if (this.nacinPredvajanja === 0) {
            // po vrsti
            if (this.vrsta.length > this.trenutnaPesem + 1) {
              this.trenutnaPesem++;
              this.predvajajPesem(this.vrsta[this.trenutnaPesem]);
            } else {
              this.ustaviPredavanje();
            }
          }
          if (this.nacinPredvajanja === 1) {
            // ponavljaj vse
            if (this.vrsta.length > 0) {
              this.trenutnaPesem++;
              this.trenutnaPesem = this.trenutnaPesem % this.vrsta.length;
              this.predvajajPesem(this.vrsta[this.trenutnaPesem]);
            } else {
              this.ustaviPredavanje();
            }
          }
          if (this.nacinPredvajanja === 2) {
            // ponavljaj eno
            if (this.vrsta.length > this.trenutnaPesem) {
              this.predvajajPesem(this.vrsta[this.trenutnaPesem]);
            } else {
              this.ustaviPredavanje();
            }
          }
          if (this.nacinPredvajanja === 3) {
            // nakljucno
            if (this.nepredvajane.length > 0) {
              let izbrana = Math.floor(Math.random() * this.nepredvajane.length);
              this.trenutnaPesem = this.nepredvajane[izbrana];
              this.predvajajPesem(this.vrsta[this.trenutnaPesem]);
              this.nepredvajane.splice(izbrana, 1);
            } else {
              this.ustaviPredavanje();
            }
          }
        }
        if (this.idPredvajanja) {
          this.posodobiSkupnoPredvajanje()
        }
      }
    },
    predvajajNaslednjo(pesem) {
      if (Array.isArray(pesem)) {
        this.naslednjaVrsta = pesem.concat(this.naslednjaVrsta)
      } else {
        this.naslednjaVrsta.unshift(pesem)
      }
    },
    predvajaj(evt) {
      this.vrsta = evt[0];
      this.trenutnaPesem = evt[1];
      this.nepredvajane = [];
      for (let i = 0; i < this.vrsta.length; i++) {
        if (i !== this.trenutnaPesem) {
          this.nepredvajane.push(i);
        }
      }
      this.predvajajPesem(this.vrsta[this.trenutnaPesem]);
    },
    predvajajPodcast(podcast, nePrestaviVidea) {
      if (!nePrestaviVidea) {
        this.glejVideoPodcasta = false
      }
      this.vrsta = []
      this.trenutnaPesem = 0
      this.predvajajPesem(podcast);
    },
    predvajajPesem(pesem) {
      if (this.predvajalnik) {
        this.$refs.plyr.player.destroy();
      }
      this.predvajalnik = false;
      this.pokaziYtLink = false;
      this.trenutna = pesem;
      if (this.trenutna.podcast) {
        document.getElementById("naslov").innerText = this.trenutna["ime"] + " - Glasba"
      } else {
        document.getElementById("naslov").innerText = this.trenutna["ime-pesmi"] + " - Glasba"
      }
      let th = this;
      this.$nextTick(function() {
        th.predvajalnik = true;
        th.$nextTick(function() {
          // nastavi hitrost
          th.$refs.plyr.player.speed = this.pridobiHitrost();
          th.$refs.plyr.player.play();
          th.pripraviNotification(pesem)
        });
      });
      if (!pesem.podcast) {
        this.shraniPredvajanoPesemVZgodovino(pesem);
      }
    },
    shraniPredvajanoPesemVZgodovino(pesem) {
      if (this.shranjujZgodovino && !Vue.prototype.$brezPovezave) {
        let postdata = {
          idPesmi: pesem.id
        };
        Rest.request("POST", `/zgodovina/shrani`, postdata)
          .then(data => {
            this.pesmi = data;
          })
          .catch(err => {
            console.log(err);
          });
      }
    },
    ustaviPredavanje() {
      document.getElementById("naslov").innerText = "Glasba"
      if (this.predvajalnik) {
        this.$refs.plyr.player.destroy();
      }
      this.predvajalnik = false;
      if (nalaganje.jeCordova()) {
        MusicControls.destroy(function() {}, function() {});
      }
    },
    pripraviNotification(pesem) {
      let th = this
      if (nalaganje.jeCordova()) {
        if (this.predvajalnik) {
          MusicControls.create({
            track       : pesem.podcast ? pesem.ime : pesem["ime-pesmi"],		// optional, default : ''
            artist      : pesem.avtor,						// optional, default : ''
            album       : pesem.album,     // optional, default: ''
            cover       : pesem.ytId ? this.pridobiUrlSlike(pesem.ytId) : null,		// optional, default : nothing
            hasPrev   : false,		// show previous button, optional, default: true
            hasNext   : !pesem.podcast,		// show next button, optional, default: true
            hasClose  : true,		// show close button, optional, default: false
            isPlaying: th.$refs.plyr.player.playing
          }, function () {
            // Register callback
            MusicControls.subscribe(th.notificationEvent);
            // Start listening for events
            // The plugin will run the events function each time an event is fired
            MusicControls.listen();
          }, function () {

          });
        } else {
          MusicControls.destroy(function() {}, function(err) {console.log(err)});
        }
        
      }
    },
    notificationEvent(action) {
      console.log(action)
      const message = JSON.parse(action).message;
      switch(message) {
        case 'music-controls-next':
          // Do something
          this.preskociPesem()
          break;
        case 'music-controls-previous':
          // Do something
          break;
        case 'music-controls-pause':
          // Do something
          this.$refs.plyr.player.pause();
          break;
        case 'music-controls-play':
          // Do something
          this.$refs.plyr.player.play();
          break;
        case 'music-controls-destroy':
          // Do something
          this.ustaviPredavanje();
          break;
        case 'music-controls-toggle-play-pause' :
          // Do something
          if (th.$refs.plyr.player.playing) {
            this.$refs.plyr.player.pause();
          } else {
            this.$refs.plyr.player.play();
          }
          break;

        // Headset events (Android only)
        // All media button events are listed below
        case 'music-controls-media-button' :
          // Do something
          if (this.$refs.plyr.player.playing) {
            this.$refs.plyr.player.pause();
          } else {
            this.$refs.plyr.player.play();
          }
          break;
        case 'music-controls-headset-unplugged':
          // Do something
          this.$refs.plyr.player.pause();
          break;
        case 'music-controls-headset-plugged':
          // Do something
          break;
        default:
          break;
      }
      this.pripraviNotification(this.trenutna)
    }
  },
  props: {}
};
</script>

<style scoped>
.footer {
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
}
.pikice {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
.rojstni-dan {
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-size: cover;
  background-image: url("https://wallpapertag.com/wallpaper/full/d/c/1/605834-birthday-card-backgrounds-1920x1200-desktop.jpg");
}
.ikona-pesmi {
  height: 150px;
  width: 150px;
  text-align: center;
  font-size: 75px;
  background: #3c8dbc;
  color: white;
  vertical-align: middle;
  padding: 20px;
  margin-left: auto;
  margin-right: auto;
}
.slika-pesmi {
  height: 150px;
  /* width: 150px; */
  text-align: center;
  font-size: 75px;
  /* background: #3c8dbc; */
  color: white;
  vertical-align: middle;
  margin-left: auto;
  margin-right: auto;
}
.vbottom {
  position: relative;
  top: 100%;
  transform: translateY(-100%);
}
</style>
