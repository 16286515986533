<template>
  <div id="app">
    <body class="hold-transition skin-blue layout-top-nav">
      <navigacija></navigacija>
      <domacaStran></domacaStran>
    </body>
  </div>
</template>

<script>
import navigacija from './components/navigacija/navigacija.vue'
import domacaStran from './components/DomacaStran.vue'
export default {
  name: 'app',
  components: {
    navigacija,
    domacaStran
  }
}

</script>

<style>
  @import './../bower_components/bootstrap/dist/css/bootstrap.min.css';
  @import './../bower_components/font-awesome/css/font-awesome.min.css';
  @import './../bower_components/Ionicons/css/ionicons.min.css';
  @import './../staticno/css/AdminLTE.min.css';
  @import './../staticno/css/skins/_all-skins.min.css';
  @import './../bower_components/pace/pace.min.css';
  @import './../node_modules/sweetalert2/dist/sweetalert2.min.css';
  @import 'https://fonts.googleapis.com/css?family=Source+Sans+Pro:300,400,600,700,300italic,400italic,600italic';

  @media (max-width: 768px) {
    .container {
      padding-right: 0px;
      padding-left: 0px;
    }
  }
  @media (max-width: 768px) {
    .container > .navbar-header,
    .container-fluid > .navbar-header,
    .container > .navbar-collapse,
    .container-fluid > .navbar-collapse {
      margin-right: 0;
      margin-left: 0;
    }
  }
</style>
