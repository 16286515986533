<template>
  <div>
    <div class="container">
      <section class="content-header">
        <h1>
          Skupine
          <small></small>
        </h1>
        <ol class="breadcrumb">
          <li><a @click="$router.push('/')">Domov</a></li>
          <li><a @click="$router.push('/skupine')">Skupine</a></li>
        </ol>
      </section>
      <br/>
      <div class="col-md-12">
        <div class="box">
          <div class="box-header">
            <h3 class="box-title">Ustvari skupino</h3>
          </div>
          <!-- /.box-header -->
          <div class="box-body ">
            <div class="form-group">
              <label>Ime skupine</label>
              <input v-model="imeNoveSkupine" type="text" class="form-control" />
            </div>
          </div>
          <div class="box-footer">
            <button @click.prevent="ustvari()" class="btn btn-primary">Ustvari</button>
          </div>
        </div>
      <!-- /.box -->
      </div>

      <div class="col-md-6" v-for="(uporabnik, ind) in uporabniki" :key="uporabnik.ime">
        <div class="box">
          <div class="box-header">
            <h3 class="box-title">{{uporabnik.ime}}</h3>
          </div>
          <!-- /.box-header -->
          <div class="box-body ">
            <div class="form-group">
              <span v-for="skupina in vseSkupine" :key="skupina.id + uporabnik.ime">
                <input type="checkbox" :value="skupina.id" v-model="uporabnik.skupine">
                <label>&nbsp;{{skupina.ime}}&nbsp;&nbsp;</label>
              </span>
            </div>
          </div>
          <div class="box-footer">
            <button @click.prevent="posodobi(ind)" class="btn btn-primary">Posodobi</button>
          </div>
        </div>
      <!-- /.box -->
      </div>
    </div>
  </div>
</template>

<script>
import Rest from './../../storitve/rest'
import Vue from 'vue'
import { EventBus } from './../../storitve/event-bus'
import Pesem from './../skupno/Pesem.vue'

export default {
  components: {
  },
  name: 'skupine',
  props: {
  },
  data () {
    return {
      imeNoveSkupine: "",
      vseSkupine: [],
      uporabniki: []
    }
  },
  created: function () {
    this.pridobiVseSkupine()
    this.pridobiVseUporabnike()
  },
  methods: {
    ustvari () {
      let postdata = {
        ime: this.imeNoveSkupine
      }
      Rest.request('POST', `/skupine/ustvari`, postdata).then((data) => {
        this.$swal.fire(
            'Uspeh',
            'Skupina dodana',
            'success'
          )
        this.pridobiVseSkupine()
      }).catch((err) => {
        this.$swal.fire(
            'Napaka',
            'Prislo je do napake',
            'error'
          )
        console.log(err)
      })
    },
    posodobi (ind) {
      let postdata = this.uporabniki[ind]
      Rest.request('POST', `/skupine/nastaviUporabnika`, postdata).then((data) => {
        this.$swal.fire(
            'Uspeh',
            'Skupina dodana',
            'success'
          )
        this.pridobiVseUporabnike()
      }).catch((err) => {
        this.$swal.fire(
            'Napaka',
            'Prislo je do napake',
            'error'
          )
        console.log(err)
      })
    },
    pridobiVseSkupine: function() {
      Rest.request('GET', `/skupine/vse`).then((data) => {
        this.vseSkupine = data
      }).catch((err) => {
        console.log(err)
      })
    },
    pridobiVseUporabnike: function() {
      Rest.request('GET', `/skupine/uporabniki`).then((data) => {
        this.uporabniki = data.uporabniki
      }).catch((err) => {
        console.log(err)
      })
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
