<template>
  <div>
    <div class="container">
    <section class="content-header">
      <h1>
        Uredi zbirko
        <small></small>
      </h1>
      <ol class="breadcrumb">
        <li><a @click="$router.push('/')">Domov</a></li>
        <li><a @click="$router.push('/priljubljeniSeznami')">Priljubljeni seznami predvajanja</a></li>
        <li><a @click="$router.push('/priljubljeniSeznami/' + idMape)">{{imeSeznama}}</a></li>
        <li><a @click="$router.push('/seznam/' + idMape + '/uredi')">Uredi zbirko</a></li>
      </ol>
    </section>
      <br />
      <div class="col-xs-12">
        <div class="box box-primary">
          <div class="box-header with-border">
            <h3 class="box-title">Uredi zbirko</h3>
            <button class="btn btn-danger pull-right" @click="izbrisiSeznam()"><i class="fa fa-trash"></i></button>
          </div>
          <!-- /.box-header -->
          <!-- form start -->
          <form role="form">
            <div class="box-body">
              <div class="row">
              <div class="col-md-6">
                <div class="form-group">
                  <label>Ime zbirke</label>
                  <input v-model="imeSeznama" type="text" class="form-control" />
                </div>
              </div>
              <div class="col-md-6">
                <uredi-sliko  :imeSeznama="imeSeznama" :idSeznama="idMape"></uredi-sliko>
              </div>
              </div>
              <label>Seznami</label>
              <ul class="todo-list" v-if="seznami">
                <draggable :list="seznami" class="todo-list" ghost-class="ghost" handle=".handle">
                  <li v-for="(pesem, index) in seznami"
                    :key="pesem['id']">
                    <!-- drag handle -->
                    <span class="handle" style="width:3%">
                      <i class="fa fa-ellipsis-v"></i>
                      <i class="fa fa-ellipsis-v"></i>
                    </span>
                    <!-- todo text -->
                    <span class="text pikice" style="width:80%">{{ pesem['ime'] }}</span>
                    <span style="color:#dd4b39; width:10%">
                      <i class="pull-right fa fa-trash-o" @click.prevent.stop="odstraniPesem(index)"></i>
                    </span>
                  </li>
                </draggable>
                <li @click="odpriPesmi()" style="margin-top: 3px">
                  <!-- todo text -->
                  <span class="text pikice" style="width:80%"><i class="fa fa-plus"></i> Dodaj seznam</span>
                </li>
              </ul>
              <!-- <button type="button" class="btn btn-default" >
                <i class="fa fa-plus"></i> pesmi
              </button> -->
            </div>
            <!-- /.box-body -->

            <div class="box-footer">
              <button @click.prevent="posodobiSeznam()" class="btn btn-primary">Posodobi</button>
            </div>
          </form>
        </div>
      </div>

      <div class="modal fade" id="modal-dodaj">
        <div class="modal-dialog">
          <div class="modal-content">
            <div class="modal-header">
              <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span></button>
              <h4 class="modal-title">Dodaj seznam predvajanja</h4>
            </div>
            <div class="modal-body">
              <div class="input-group">
                <input type="text" autocomplete="off" name="q" class="form-control" placeholder="Iskanje..." v-model="iskalnoGeslo">
                <span class="input-group-btn">
                  <button type="submit" name="search" id="search-btn" class="btn btn-flat" @click.prevent=""><i class="fa fa-search"></i>
                  </button>
                </span>
              </div>

              <ul class="nav nav-pills nav-stacked">
                <li v-for="pesem in filtriraniSeznami" :key="pesem.id">
                  <a class="col-xs-8 pikice"><span class="">{{pesem['ime']}}</span>
                  </a>
                  <a class=" col-xs-4" @click="dodajSeznam(pesem.id)"><span class="pull-right text-blue"><i class="fa fa-plus"></i> Dodaj</span></a>
                </li>
              </ul>
            </div>
            <div class="modal-footer">
              <button type="button" class="btn btn-default pull-left" data-dismiss="modal">Zapri</button>
            </div>
          </div>
          <!-- /.modal-content -->
        </div>
        <!-- /.modal-dialog -->
      </div>
    </div>
  </div>
</template>

<script>
import draggable from "vuedraggable";
import Rest from "./../../storitve/rest";
import Vue from "vue";
import { EventBus } from "./../../storitve/event-bus";
import Pesem from "./../skupno/Pesem.vue";
import VueSweetalert2 from 'vue-sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';
import UrediSliko from "./../skupno/UrediSliko.vue";

Vue.use(VueSweetalert2);

export default {
  components: {
    draggable,
    UrediSliko
  },
  name: "uredi-priljubljeno-mapo",
  props: ["idMape"],
  data() {
    return {
      imeSeznama: "",
      seznami: [],
      vsiSeznami: [],
      mape: [],
      iskalnoGeslo: ""
    };
  },
  created: function() {
    this.naloziSeznam();
  },
  computed: {
    filtriraniSeznami () {
      return this.vsiSeznami.filter(el => el.ime.toLowerCase().indexOf(this.iskalnoGeslo.toLowerCase()) >= 0)
    }
  },
  methods: {
    dodajSeznam(idPesmi) {
      for (let i = 0; i < this.vsiSeznami.length; i++) {
        if (this.vsiSeznami[i].id === idPesmi) {
          let pesem = this.vsiSeznami.splice(i, 1)
          let dodaj = true
          for (let j = 0; j < this.seznami.length; j++) {
            if (this.seznami[j].id == idPesmi) {
              dodaj = false
            }
          }
          if (dodaj) {
            console.log(pesem[0])
            this.seznami.push(pesem[0])
          }
          break
        }
      }
    },
    pridobiPesmi: function() {
      let podatki = {
      }
      Rest.request('POST', `/vsi-seznami`, podatki).then((data) => {
        this.vsiSeznami = []
        for (let i = 0; i < data.length; i++) {
          let odstrani = false
          for (let j = 0; j < this.seznami.length; j++) {
            if (this.seznami[j].id === data[i].id) {
              odstrani = true
              break
            }
          }
          if (!odstrani) {
            this.vsiSeznami.push(data[i])
          }
        }
      }).catch((err) => {
        console.log(err)
      })
    },
    izbrisiSeznam: function() {
      this.$swal.fire({
        title: 'Želiš izbrisati zbirko?',
        text: "Brisanja zbirke se ne da razveljaviti.",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Da',
        cancelButtonText: 'Ne'
      }).then((result) => {
        if (result.value) {
          console.log('ok')
          let podatki = {
            idMape: this.idMape
          }

          Rest.request("POST", `/mape/moji/izbrisi`, podatki)
            .then(data => {
              this.$router.push('/priljubljeniSeznami')
            })
            .catch(err => {
              console.log(err);
              // th.opozorila.push(err.status + " " + err.statusText);
            });
        }
      })
    },
    odstraniPesem: function(index) {
      this.seznami.splice(index,1);
    },
    odpriPesmi () {
      window.jQuery("#modal-dodaj").modal("toggle")
      this.pridobiPesmi()
    },
    naloziSeznam: function() {
      Rest.request("POST", `/mape/moji/podrobnosti`, { idMape: this.idMape })
        .then(data => {
          this.seznami = data.seznami;
          this.imeSeznama = data.ime;
        })
        .catch(err => {
          console.log(err);
        });

      Rest.request("GET", `/vse-mape`)
        .then(data => {
          this.mape = data
        })
        .catch(err => {
          console.log(err);
        });
    },
    posodobiSeznam: function() {
      let podatki = {
        id: this.idMape,
        seznami: [],
        ime: this.imeSeznama
      };
      for (let i = 0; i < this.seznami.length; i++) {
        podatki.seznami.push(this.seznami[i].id)
      }
      Rest.request("POST", `/mape/moji/posodobi`, podatki)
        .then(data => {
          this.$router.push('/priljubljeniSeznami')
        })
        .catch(err => {
          console.log(err);
        });
    }
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.pikice{
  text-overflow: ellipsis !important;
  overflow: hidden;
  white-space: nowrap;
}
.ghost {
  background: #f8f8f8;
}
</style>
