<template>
  <div style="">
    
    <div style="margin-top: 1ex;">
      <Podcast :slika="'/api/v1/slikeSeznamov/' + idSeznama + '/posodobitev/' + posodobitev" style="margin-bottom: 1ex; width: 50%; margin-left: auto; margin-right: auto"></Podcast>
      <button type="button" class="btn btn-block btn-primary" data-toggle="modal" :data-target="'#modal-nalozi-sliko'"><i class="fa fa-plus"></i> Spremeni sliko</button>
    </div>


    <div class="modal fade" :id="'modal-nalozi-sliko'">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
            <h4 class="modal-title">Naloži sliko</h4>
          </div>
          <form class="" enctype="multipart/form-data" novalidate>
            <div class="modal-body">
              <div class="col-xs-6">
                <div class="checkbox">
                  <label>
                    <input type="checkbox" v-model="dodajIme">
                    Dodaj ime seznama
                  </label>
                </div>
              </div>
              <div class="col-xs-6">
                <div class="checkbox">
                  <label>
                    <input type="checkbox" v-model="zameglitevSlike">
                    Zabriši sliko
                  </label>
                </div>
              </div>
              <br/>
              <br/>
              <div class="col-xs-12">
                <button v-if="pridobivanjeSlikOmogoceno" class="btn btn-default form-control" @click.prevent="pridobiSlike()">Pridobi predlagane slike</button>
              </div>
              <br/>
              <br/>
              <div class="col-xs-12" v-if="predlaganeSlike.length === 0">
                <div class="dropbox">
                  <input
                    type="file"
                    multiple
                    :name="'NalaganjeIgre'"
                    :disabled="isSaving"
                    @change="filesChange($event.target.files); fileCount = $event.target.files.length"
                    class="input-file"
                    id="nalaganjeVerzije"
                  >
                  <p v-if="isSaving">Nalaganje datoteke...</p>
                  <p v-else>Sem potegni sliko.</p>
                </div>
                <br/>
              </div>

              <div class="col-xs-12">
                <div class="">
                  <label>URl slike</label>
                  <input v-model="urlSlike" type="text" class="form-control" />
                </div>
              </div>

              <div class="col-xs-12">
                <div v-for="slika in predlaganeSlike" class="col-xs-6" :key="slika.url">
                  <div class="thumbnail" @click="urlSlike = slika.url">
                    <img :src="nalaganje.pretvoriLokalniUrl(slika.url)">
                  </div>
                </div>
              </div>
              <br/>
              <br/>
              <br/>
              <br/>
              <br/>
              <br/>
              <br/>
              <br/>
              <br/>
              <br/>
              <br/>
              <br/>
              <br/>
              <br/>
              <br/>

            </div>
            <div class="modal-footer">
              <button v-if="urlSlike.length > 0" type="button" class="btn btn-primary pull-right" data-dismiss="modal" @click="dodajUrlSliko()">Dodaj sliko</button>
              <button type="button" class="btn btn-default pull-left" data-dismiss="modal">Prekliči</button>
            </div>
          </form>
        </div>
      </div>
    </div>

  </div>
</template>

<script>

import Rest from "./../../storitve/rest";

import Podcast from "./../skupno/Podcast.vue";
import nalaganje from './../../storitve/nalaganje-pesmi'

export default {

  components: {
    Podcast
  },
  name: 'uredi sliko',
  props: ['idSeznama', 'imeSeznama'],
  data () {
    return {
      posodobitev: Math.round(Math.random() * 1000000),
      dodajIme: false,
      zameglitevSlike: false,
      predlaganeSlike: [],
      pridobivanjeSlikOmogoceno: true,
      isSaving: false,
      urlSlike: "",
      nalaganje
    }
  },
  methods: {
    dodajUrlSliko() {
      let postdata = {
        idSeznama: this.idSeznama,
        url: this.urlSlike,
        zabrisi: this.zameglitevSlike,
        dodajIme: this.dodajIme
      }
      let th = this
      Rest.request('POST', `/urediSlikeSeznamov/dodajUrlSliko`, postdata).then((data) => {
            th.posodobitev = Math.round(Math.random() * 1000000)
      });
    },
    pridobiSlike() {
      this.pridobivanjeSlikOmogoceno = false
      Rest.request('GET', `/apiKljuci/iskanjeSlik`).then((data) => {
        let apiKljuc = data.kljuc
        let iskanje = this.imeSeznama
        Rest.request('GET', `https://app.zenserp.com/api/v2/search?apikey=`+apiKljuc+`&q=`+iskanje+`&tbm=isch&device=desktop&location=Slovenia`).then((data) => {
          let rezultati = data.image_results
          this.predlaganeSlike = []
          for (let i = 0; i < Math.min(rezultati.length, 10); i++) {
            if (rezultati[i].sourceUrl) {
              this.predlaganeSlike.push(
                {url: rezultati[i].sourceUrl}
              )
            }
          }
        })
      }).catch((err) => {
        console.log(err)
      })
    },
    filesChange(files) {
      let th = this
      this.isSaving = true
      let fileReader = new FileReader()
      fileReader.onload = function(fileLoadedEvent) {
        let arrayBuffer = fileLoadedEvent.target.result
        let array = new Uint8Array(arrayBuffer)

          fetch(`/api/v1/surovo/naloziSliko/` + th.idSeznama + `/nalozi?zameglitevSlike=` + th.zameglitevSlike + `&dodajIme=` + th.dodajIme, {
            method: 'POST',
            body: array, // string or object
            headers: {
              // 'Content-Type': 'application/json'
              'Authorization': 'Bearer ' + localStorage.getItem('sessionAccessToken')
            }
          }).then(() => {
            window.jQuery('#modal-nalozi-sliko').modal("toggle")
            th.isSaving = false
            th.posodobitev = Math.round(Math.random() * 1000000)
          }).catch(() => {
            this.isSaving = false
          })
      }
      fileReader.readAsArrayBuffer(files[0])
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.dropbox {
  outline: 2px dashed grey; /* the dash box */
  outline-offset: -10px;
  background: lightcyan;
  color: dimgray;
  padding: 10px 10px;
  min-height: 200px; /* minimum height */
  position: relative;
  cursor: pointer;
}

.input-file {
  opacity: 0; /* invisible but it's there! */
  width: 100%;
  height: 200px;
  position: absolute;
  cursor: pointer;
}

.dropbox:hover {
  background: lightblue; /* when mouse over to the drop zone, change color */
}

.dropbox p {
  font-size: 1.2em;
  text-align: center;
  padding: 50px 0;
}
</style>
