<template>
  <header class="main-header">
    <nav class="navbar navbar-static-top">
      <div class="">
        <div class="navbar-header">
          <a href="#" @click.prevent="$router.push({ path:'/' })" class="navbar-brand"><b>Glasba</b>
            <div class="btn-group" v-if="cordova" style="margin-left: 1em; margin-top: -4px">
              <button type="button" :class="['btn', 'btn-sm', $brezPovezave ? 'btn-default' : 'btn-warning']" @click.stop.prevent="omogociPovezavo(true)"><i class="fa fa-wifi"></i></button>
              <button type="button" :class="['btn', 'btn-sm', $brezPovezave ? 'btn-warning' : 'btn-default']" @click.stop.prevent="omogociPovezavo(false)"><i class="fa fa-plug"></i></button>
            </div>
          </a>
          <button type="button" class="navbar-toggle collapsed" data-toggle="collapse" data-target="#navbar-collapse">
            <i class="fa fa-bars"></i>
          </button>
        </div>

        <!-- Collect the nav links, forms, and other content for toggling -->
        <div class="collapse navbar-collapse pull-left" id="navbar-collapse">
          <ul class="nav navbar-nav">
            <li v-if="uporabnik && uporabnik.zacasen"><a @click.prevent="$router.push({ path:'/prijava' }); zapriMeni ()">Prijava</a></li>
            <li v-if="uporabnik && !uporabnik.zacasen"><a @click.prevent="$router.push({ path:'/iskanje' }); zapriMeni ()">Iskanje</a></li>
            <!-- <li><a @click.prevent="$router.push({ path:'/vse-pesmi' }); zapriMeni ()">Vse pesmi</a></li> -->
            <li v-if="uporabnik && !uporabnik.zacasen"><a @click.prevent="$router.push({ path:'/seznami' }); zapriMeni ()">Seznami</a></li>
            <li v-if="uporabnik && !uporabnik.zacasen"><a @click.prevent="$router.push({ path:'/priljubljeniSeznami' }); zapriMeni ()">Priljubljeni seznami</a></li>
            <li v-if="uporabnik && !uporabnik.zacasen"><a @click.prevent="$router.push({ path:'/poddaje' }); zapriMeni ()">Poddaje</a></li>
            <li v-if="uporabnik && !uporabnik.zacasen"><a @click.prevent="$router.push({ path:'/nalozi' }); zapriMeni ()">Naloži</a></li>
            <li class="dropdown" v-if="uporabnik && !uporabnik.zacasen">
              <a href="#" class="dropdown-toggle" data-toggle="dropdown">Več <span class="caret"></span></a>
              <ul class="dropdown-menu" role="menu">
                <li v-if="uporabnik && !uporabnik.zacasen"><a @click.prevent="$router.push({ path:'/miksi' }); zapriMeni ()">Miksi</a></li>
                <li v-if="uporabnik && !uporabnik.zacasen"><a @click.prevent="$router.push({ path:'/zgodovina' }); zapriMeni ()">Zgodovina</a></li>
                <li v-if="uporabnik && !uporabnik.zacasen"><a @click.prevent="$router.push({ path:'/posnetki' }); zapriMeni ()">Posnetki</a></li>
                <li v-if="uporabnik && !uporabnik.zacasen"><a @click.prevent="$router.push({ path:'/nastavitve' }); zapriMeni ()">Nastavitve</a></li>
              </ul>
            </li>
            <li class="dropdown" v-if="uporabnik && uporabnik.admin">
              <a href="#" class="dropdown-toggle" data-toggle="dropdown">Admin <span class="caret"></span></a>
              <ul class="dropdown-menu" role="menu">
                <li><a @click.prevent="$router.push({ path:'/skupine' }); zapriMeni ()">Skupine</a></li>
                <li><a @click.prevent="$router.push({ path:'/registracija' }); zapriMeni ()">Registracija</a></li>
                <li><a @click.prevent="$router.push({ path:'/dodajObvestilo' }); zapriMeni ()">Dodaj obvestilo</a></li>
                <li><a @click.prevent="$router.push({ path:'/statistika' }); zapriMeni ()">Statistika</a></li>
                <li><a @click.prevent="$router.push({ path:'/nadzornaPlosca' }); zapriMeni ()">Nadzorna plošča</a></li>
                <li><a @click.prevent="$router.push({ path:'/naloziLokalno' }); zapriMeni ()">Dodaj lokalne pesmi</a></li>
              </ul>
            </li>
          </ul>
          <!-- <form class="navbar-form navbar-left" role="search"> -->
            <!-- <div class="form-group"> -->
              <!-- <input type="text" class="form-control" id="navbar-search-input" placeholder="Search"> -->
            <!-- </div> -->
          <!-- </form> -->
        </div>
        <!-- /.navbar-collapse -->
        <!-- Navbar Right Menu -->
        <div class="navbar-custom-menu">
          <ul class="nav navbar-nav">
            <li class="dropdown notifications-menu">
              <a href="#" class="dropdown-toggle" data-toggle="dropdown" @click="pridobiObvestila()">
                <i class="fa fa-bell-o"></i>
                <span class="label label-warning" v-if="obvestila.length > 0">{{obvestila.length}}</span>
                <span class="label label-success" v-else-if="update"><i class="fa fa-star"></i></span>
              </a>
              <ul class="dropdown-menu">
                <li class="header clickable text-yellow" style="text-align: center" v-if="update" @click.prevent="posodobiAplikacijo()"><i class="fa fa-star"></i><span class="text-green">Naloži posodobitev</span><i class="fa fa-star"></i></li>
                <li class="header clickable" @click.prevent="$router.push({path:'/naloge'})">Preglej aktivne naloge.</li>
                <li class="header" @click.prevent="$router.push({path:'/statistika'})">Imaš {{obvestila.length}} obvestil.</li>
                <li>
                <li>
                  <!-- inner menu: contains the actual data -->
                  <ul class="menu">
                    <li v-for="obvestilo in obvestila" :key="obvestilo.besedilo">
                      <a>
                        <i class="fa fa-info text-aqua"></i> {{obvestilo.besedilo}}
                      </a>
                    </li>
                  </ul>
                </li>
              </ul>
            </li>
          </ul>
        </div>
        <!-- /.navbar-custom-menu -->
      </div>
      <!-- /.container-fluid -->
    </nav>
  </header>
</template>

<script>
import Rest from './../../storitve/rest'
import nalaganje from './../../storitve/nalaganje-pesmi'
import verzija from './../../storitve/verzija'
import JQuery from 'jquery'
import Vue from 'vue'
import { EventBus } from '../../storitve/event-bus.js';

export default {
  components: {
  },
  name: 'navigacija',
  props: {
  },
  data () {
    return {
      uporabnik: {},
      obvestila: [],
      stPreverjanjObvestil: 1,
      intervalid1: null,
      datoteke: "asd",
      cordova: false,
      update: false
    }
  },
  created: function () {
    console.log("---------------")
    console.log(window.location.host)
    Vue.prototype.$brezPovezave = (window.location.host === 'localhost:7777')
    let th = this
    if (!localStorage.getItem("sessionAccessToken") || localStorage.getItem("sessionAccessToken").split(".").length !== 3) {
      setTimeout(th.pridobiUporabnika, 1000)
    } else {
      th.pridobiUporabnika()
    }
    this.$nextTick(function () {
      th.intervalid1 = setInterval(() => {
        if (th.stPreverjanjObvestil > 0) {
          th.stPreverjanjObvestil--
          th.pridobiObvestila()
        }
      },3000);

      EventBus.$on('sproziObvestila', th.sproziObvestila);
    })
    EventBus.$on('posodobiBrezPovezave', this.posodobi);
    nalaganje.nalozenePesmi().then((pesmi) => {
      th.datoteke = pesmi
    })
    setTimeout(function () {
      th.cordova = nalaganje.jeCordova()
      if (th.cordova) {
        th.preglejPosodobitve()
      }
    }, 1000)
  },
  beforeDestroy () {
    clearInterval(this.intervalid1)
    EventBus.$off('sproziObvestila', this.sproziObvestila);
    EventBus.$off('posodobiBrezPovezave', this.posodobi);
  },
  computed: {
    brezPovezave() {
      return Vue.prototype.$brezPovezave;
    },
  },
  methods: {
    jeCordova() {
      return nalaganje.jeCordova()
    },
    posodobi() {
      this.$forceUpdate();
      console.log('posodobi-navigacija')
    },
    omogociPovezavo(omogoci) {
      Vue.prototype.$brezPovezave = !omogoci
      EventBus.$emit('posodobiBrezPovezave');
    },
    sproziObvestila(stOsvezevanj) {
      this.stPreverjanjObvestil = stOsvezevanj
    },
    zapriMeni () {
      let $ = window.jQuery
      $('#navbar-collapse').collapse('hide')
    },
    pridobiUporabnika () {
      Rest.request('POST', `/uporabniki/jaz`, {verzija: verzija.verzija}).then((data) => {
        this.uporabnik = data
      }).catch((err) => {
        console.log(err)
      })
    },
    pridobiObvestila () {
      Rest.request('GET', `/obvestila`).then((data) => {
        this.obvestila = data
      }).catch((err) => {
        console.log(err)
      })
    },
    preglejPosodobitve () {
      let th = this
      Rest.request('GET', `https://glasba.knez.cloud/downloads/verzija.json`).then((data) => {
        let zadnjaVerzija = data.verzija
        let trenutnaVerzija = verzija.verzija
        if (trenutnaVerzija < zadnjaVerzija) {
          th.update = true
        }
      }).catch((err) => {
        console.log(err)
      })
    },
    async posodobiAplikacijo() {
      if (window.ApkUpdater) {
        this.$swal.fire({
          position: 'top',
          title: 'Nalaganje začeto',
          icon: 'success',
          showConfirmButton: false,
          timerProgressBar: true,
          backdrop: null,
          timer: 1000
        })
      }
      await window.ApkUpdater.download(
          'https://glasba.knez.cloud/downloads/glasba.apk',
          {
              onDownloadProgress: console.log
          }
      );
      await window.ApkUpdater.install();
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.clickable{
  cursor: pointer; 
}
</style>
