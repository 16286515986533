<template>
  <div>
    <div class="container">
      <section class="content-header">
        <h1>
          Aktivne naloge
          <small></small>
        </h1>
        <ol class="breadcrumb">
          <li><a @click="$router.push('/')">Domov</a></li>
          <li><a @click="$router.push('/naloge')">Aktivne naloge</a></li>
        </ol>
      </section>
      <br/>
      <div class="col-md-12">
        <div class="box">
          <div class="box-header">
            <h3 class="box-title">Aktivne naloge</h3>
          </div>
          <!-- /.box-header -->
          <div class="box-body ">
            <div v-for="(naloga, i) in naloge" :key="i">
                <div v-if="naloga.imeStarsa" class="progress-group">
                    <span class="progress-text">{{naloga.imeStarsa}}</span>
                    <span class="progress-number">{{naloga.uporabnik}}</span>
                    <div class="progress sm">
                    <div class="progress-bar progress-bar-warning" :style="'width: ' + (naloga.napredekStarsa*100) + '%'"></div>
                    </div>
                </div>
                <div class="progress-group">
                    <span class="progress-text">{{naloga.ime}}</span>
                    <span class="progress-number">{{naloga.status}} | {{naloga.uporabnik}}</span>
                    <div class="progress sm">
                    <div class="progress-bar progress-bar-aqua" :style="'width: ' + (naloga.napredek*100) + '%'"></div>
                    </div>
                </div>
            </div>
          </div>
          <!-- /.box-body -->
        </div>
      <!-- /.box -->
      </div>
    </div>
  </div>
</template>

<script>
import Rest from './../../storitve/rest'
import Vue from 'vue'
import { EventBus } from './../../storitve/event-bus'
import Pesem from './../skupno/Pesem.vue'

export default {
  components: {
  },
  name: 'koren',
  props: {
  },
  data () {
    return {
      naloge: [],
      intervalid1: null
    }
  },
  created: function () {
    this.pridobiNaloge()
    let th = this
    th.intervalid1 = setInterval(() => {
        th.pridobiNaloge()
    },3000);
  },
  beforeDestroy () {
    clearInterval(this.intervalid1)
  },
  methods: {
    pridobiNaloge: function() {
      Rest.request('GET', `/statistika/naloge`).then((data) => {
        this.naloge = data
      }).catch((err) => {
        console.log(err)
      })
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.pikice{
  text-overflow: ellipsis !important;
  overflow: hidden !important;
  white-space: nowrap !important;
}
</style>
