<template>
  <div style="text-align:center;">
    <div class="thumbnail" style="margin-bottom: 0px" @contextmenu.stop.prevent="meni = !meni" @mouseover="hover = true"
    @mouseleave="hover = false">
      <div class="animated-background" :style="'height: 130px;'" ref="slika">
      </div>
      <div class="caption pikice">
        <div class="animated-background" style="width: 70px; height: 18px; margin: auto;"></div>
      </div>
    </div>
  </div>
</template>

<script>
import nalaganje from './../../storitve/nalaganje-pesmi'

export default {
  components: {
  },
  name: 'podcast-loading',
  props: [],
  data () {
    return {
      visinaSlike: '',
      meni: false,
      sirinaSlike: 0,
      hover: false,
      nalaganje: nalaganje,
    }
  },
  computed: {
  },
  mounted() {
    this.nastaviVisino()
    window.addEventListener("resize", this.resizeHandeler);
    let th = this
    setTimeout(function () {
      th.nastaviVisino();
    }, 2000)
  },
  destroyed() {
    window.removeEventListener("resize", this.resizeHandeler);
  },
  methods: {
    resizeHandeler (e) {
      this.nastaviVisino()
    },
    nastaviVisino () {
      if (!this.niKvadrat) {
        // this.visinaSlike = 'height: ' + this.$refs.slika.clientWidth + 'px';
        // this.sirinaSlike = this.$refs.slika.clientWidth;
      }
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
  @keyframes placeHolderShimmer {
    0% {
      background-position: -800px 0
    }
    100% {
      background-position: 800px 0
    }
  }
  .skin-dark .animated-background {
    animation-duration: 2s;
    animation-fill-mode: forwards;
    animation-iteration-count: infinite;
    animation-name: placeHolderShimmer;
    animation-timing-function: linear;
    background-color: #5e5e5e;
    background: linear-gradient(to right, #5e5e5e 8%, #6b6b6b 18%, #5e5e5e 33%);
    background-size: 800px 104px;
    height: 70px;
    position: relative;
  }
  .animated-background {
    animation-duration: 2s;
    animation-fill-mode: forwards;
    animation-iteration-count: infinite;
    animation-name: placeHolderShimmer;
    animation-timing-function: linear;
    background-color: #dbd9d9;
    background: linear-gradient(to right, #dbd9d9 8%, #ececec 18%, #dbd9d9 33%);
    background-size: 800px 104px;
    height: 70px;
    position: relative;
  }
  .pikice{
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }
  .info-box {
    cursor: pointer;
  }
  .flip-card {
    background-color: transparent;
    perspective: 1000px;
  }

  .flip-card-inner {
    position: relative;
    width: 100%;
    height: 100%;
    text-align: center;
    transition: transform 0.6s;
    transform-style: preserve-3d;
    box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
  }

  .flip-card:hover .flip-card-inner {
    transform: rotateY(180deg);
  }

  .flip-card-front, .flip-card-back {
    position: absolute;
    width: 100%;
    height: 100%;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
  }

  .flip-card-front {
    background-color: #bbb;
    /* color: black; */
  }

  .flip-card-back {
    background-color: #2980b9;
    color: white;
    transform: rotateY(180deg);
  }
  .btn-circle {
    border-radius: 50%;
  }
</style>
