<template>
  <div>
    <div class="container">
    <section class="content-header">
      <h1>
        Miksi
        <small>Novi miksi pridejo vsak dan.</small>
      </h1>
      <ol class="breadcrumb" style="margin-top: 1em">
        <li><a @click="$router.push('/')">Domov</a></li>
        <li><a @click="$router.push('/miksi')">Miksi</a></li>
      </ol>
    </section>
      <br />
      <div v-for="(miks) in miksi" :key="miks.tip">
        <div class="col-xs-12" @click="predvajajMiks(miks.tip)">
          <Pesem :naslov="miks.ime" :avtor="miks.opis" barva="aqua" :ikona="miks.ikona" :album="'Miks'">
          </Pesem>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import Rest from './../../storitve/rest'
import Vue from 'vue'
import { EventBus } from './../../storitve/event-bus'
import Pesem from './../skupno/Pesem.vue'

export default {
  components: {
    Pesem
  },
  name: 'miksi',
  props: [],
  data () {
    return {
      miksi: []
    }
  },
  created: function () {
    this.pridobiMikse()
  },
  beforeDestroy () {
    clearInterval(this.intervalid1)
  },
  methods: {
    dostopniZeton() {
      return localStorage.getItem("sessionAccessToken");
    },
    pridobiMikse: function() {
      Rest.request('GET', `/miksi/danasnji`).then((data) => {
        this.miksi = data
      }).catch((err) => {
        console.log(err)
      })
    },
    predvajajMiks(tip) {
      Rest.request('POST', `/miksi/pesmi`, {tipMiksa: tip}).then((data) => {
        this.$emit('predvajaj', [data, 0]);
      }).catch((err) => {
        console.log(err)
      })
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
