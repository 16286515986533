<template>
  <div>
    <div :class="['info-box', 'bg-' + barva]">
      <span class="info-box-icon" v-if="slika">
        <div class="flip-card">
          <div class="flip-card-inner">
            <div class="flip-card-front">
              <i :class="['fa', ikona]"></i>
            </div>
            <div class="flip-card-back">
              <img class = "img-thumbnail" :src="nalaganje.pretvoriLokalniUrl(slika)">
            </div>
          </div>
        </div>
      </span>
      <span v-else>
        <span class="" style="position: absolute; left: 23px; top:2px; font-size: 16px;" v-if="vSkupini">
          <i class="fa fa-group"></i>
        </span>
        <span class="" style="position: absolute; left: 23px; top:2px; font-size: 20px;" v-else-if="zasebno">
          <i class="fa fa-lock"></i>
        </span>
        <span class="" style="position: absolute; left: 23px; top:2px; font-size: 20px;" v-else-if="nalozena">
          <i class="fa fa-download"></i>
        </span>
        <span class="info-box-icon">
          <i :class="['fa', ikona]"></i>
        </span>
      </span>

      <div class="info-box-content">
        <slot></slot>
        <span class="info-box-text">{{album}}&nbsp;<span v-if="opomba" class="label bg-blue" style="font-size: 10px">{{opomba}}</span></span>
        <span class="info-box-number">{{naslov}}</span>
        <div class="progress" v-if="odstotek && odstotek > 0 && odstotek <= 100">
          <div class="progress-bar" :style="'width: ' + odstotek + '%'"></div>
        </div>
        <div class="progress" v-else>
          <div class="progress-bar" style="width: 0%"></div>
        </div>
        <span class="progress-description">
          {{avtor}}&nbsp;
        </span>
      </div>
      <!-- /.info-box-content -->
    </div>
  </div>
</template>

<script>
import nalaganje from './../../storitve/nalaganje-pesmi'

export default {
  components: {
  },
  name: 'pesem',
  props: ['naslov', 'avtor', 'barva', 'ikona', 'album', 'slika', 'zasebno', 'vSkupini', 'odstotek', 'nalozena', 'opomba'],
  data () {
    return {
      nalaganje
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
  .info-box-number{
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }
  .info-box {
    cursor: pointer;
  }
  .flip-card {
    background-color: transparent;
    perspective: 1000px;
  }

  .flip-card-inner {
    position: relative;
    width: 100%;
    height: 100%;
    text-align: center;
    transition: transform 0.6s;
    transform-style: preserve-3d;
    box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
  }

  .flip-card:hover .flip-card-inner {
    transform: rotateY(180deg);
  }

  .flip-card-front, .flip-card-back {
    position: absolute;
    width: 100%;
    height: 100%;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
  }

  .flip-card-front {
    background-color: #bbb;
    /* color: black; */
  }

  .flip-card-back {
    background-color: #2980b9;
    color: white;
    transform: rotateY(180deg);
  }
</style>
