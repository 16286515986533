<template>
  <div>
    <div class="container">
    <section class="content-header">
      <h1>
        Registracija
        <small></small>
      </h1>
      <ol class="breadcrumb">
        <li><a @click="$router.push('/')">Domov</a></li>
        <li><a @click="$router.push('/registracija')">Registracija</a></li>
      </ol>
    </section>
      <br />
      <div class="col-xs-12">
        <div class="box box-primary">
          <div class="box-header with-border">
            <h3 class="box-title">Registracija</h3>
          </div>
          <!-- /.box-header -->
          <!-- form start -->
          <form>
            <div class="box-body">
              <div class="form-group">
                <label>Uporabniško ime</label>
                <input v-model="uporabniskoIme" type="text" class="form-control" />
              </div>
              <div class="form-group">
                <label>Geslo</label>
                <input v-model="geslo" type="password" class="form-control" />
              </div>
            </div>
            <!-- /.box-body -->

            <div class="box-footer">
              <button @click.prevent="Registracija()" class="btn btn-primary">Registracija</button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import draggable from "vuedraggable";
import Rest from "./../../storitve/rest";
import Vue from "vue";
import { EventBus } from "./../../storitve/event-bus";
import Pesem from "./../skupno/Pesem.vue";
import VueSweetalert2 from 'vue-sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';

Vue.use(VueSweetalert2);

export default {
  components: {
  },
  name: "registracija",
  props: [],
  data() {
    return {
      uporabniskoIme:"",
      geslo:""
    };
  },
  created: function() {
  },
  methods: {
    Registracija() {
      let podatki = {
        uporabniskoIme: this.uporabniskoIme,
        geslo: this.geslo
      }

      Rest.request("POST", `/registriraj`, podatki)
        .then(data => {
          this.$swal.fire(
            'Uspeh',
            'Uporabnik registriran',
            'success'
          )
          this.$router.push("/")
        })
        .catch(err => {
          this.$swal.fire(
            'Napaka',
            'Prislo je do napake',
            'error'
          )
          console.log(err);
        });
    }
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.pikice{
  text-overflow: ellipsis !important;
  overflow: hidden;
  white-space: nowrap;
}
</style>
