<template>
  <div>
    <div class="container">
    <section class="content-header">
      <h1>
        Glasba
        <div class="prikazi-vse" style="display: inline-block;" @click="$router.push('/nastavitve')">
          <a><i class="fa fa-cog"></i></a>
        </div>
        <small v-if="$brezPovezave">Brez povezave</small>
      </h1>
      <ol class="breadcrumb hidden-xs">
        <li><a @click="$router.push('/')">Domov</a></li>
      </ol>
    </section>
      <br />

      <div>
        <div v-if="!$brezPovezave">
          <div class="col-xs-12 naslovna-vrstica">
            <div class="pull-left naslov">
              <i class="fa fa-music"></i>
              <span>Pesmi</span>
              <div class="prikazi-vse" style="display: inline-block;" @click="$router.push('/zgodovina')">
                <a><i class="fa fa-history"></i></a>
              </div>
            </div>
            <div class="pull-right prikazi-vse" @click="$router.push('/iskanje')">
              <a><i class="fa fa-search"></i> Iskanje</a>
            </div>
          </div>
          <div class="primeri">
            <p v-if="!shranjujZgodovino" style="text-align: center;">Za boljšo prvo stran si vklopi shranjevanje zgodovine.</p>
            <div v-if="prvaStran">
              <div v-for="pesem in prvaStran.pesmi.slice(0, stevilaElementov[0])" :key="pesem.id" class="col-xs-4 col-sm-3 col-md-2 manj-presledka" style="margin-bottom: 1em;" @click.prevent.stop="predvajajPesemSSeznamom(pesem, pesem.seznam.id)">
                <Podcast  :niKvadrat="true" :naslov="pesem['ime-pesmi']" :slika="urlSlike(pesem)"></Podcast>
              </div>
            </div>
            <div v-else>
              <div v-for="i in stevilaElementov[0]" :key="'pesmi-' + i" class="col-xs-4 col-sm-3 col-md-2 manj-presledka" style="margin-bottom: 1em;" @click.prevent.stop="predvajajPesemSSeznamom(pesem, pesem.seznam.id)">
                <PodcastLoading></PodcastLoading>
              </div>
            </div>
          </div>
        </div>
        
        <div v-if="!$brezPovezave">
          <div class="col-xs-12 naslovna-vrstica">
            <div class="pull-left naslov">
              <i class="fa fa-magic"></i>
              <span>Miksi</span>
            </div>
            <div class="pull-right prikazi-vse" @click="$router.push('/miksi')">
              <a>Prikaži vse</a>
            </div>
          </div>
          <div class="primeri">
            <div v-if="prvaStran">
              <div v-for="(miks, ind) in prvaStran.miksi.slice(0, stevilaElementov[1])" :key="'miks-' + ind" class="clickable col-xs-6 col-sm-3 col-md-3 manj-presledka" style="margin-bottom: 1em;" @click.prevent.stop="predvajajMiks(miks.tip)">
                <div class="thumbnail" style="margin-bottom: 0px; color: #d3d3d3;">
                  <div style="background-color: #4fa0b4;">
                    <div style="height: 130px; font-size: 100px; text-align: center;"><i :class="'fa ' + miks.ikona"></i></div>
                  </div>
                  <div class="caption pikice" style="text-align: center;">
                    {{miks.ime}}
                  </div>
                </div>
              </div>
            </div>
            <div v-else>
              <div v-for="i in stevilaElementov[1]" :key="'pesmi-' + i" class="col-xs-4 col-sm-3 col-md-3 manj-presledka" style="margin-bottom: 1em;" @click.prevent.stop="predvajajPesemSSeznamom(pesem, pesem.seznam.id)">
                <PodcastLoading></PodcastLoading>
              </div>
            </div>
          </div>
        </div>
        
        <div v-if="!$brezPovezave">
          <div class="col-xs-12 naslovna-vrstica">
            <div class="pull-left naslov">
              <i class="fa fa-reorder"></i>
              <span>Seznami</span>
            </div>
            <div class="pull-right prikazi-vse" @click="$router.push('/seznami')">
              <a>Prikaži vse</a>
            </div>
          </div>
          <div class="primeri">
            <p v-if="!shranjujZgodovino" style="text-align: center;">Za boljšo prvo stran si vklopi shranjevanje zgodovine.</p>
            <div v-if="prvaStran">
              <div v-for="seznam in prvaStran.seznami.slice(0, stevilaElementov[2])" :key="'seznam-' + seznam.id" class="col-xs-4 col-sm-3 col-md-2 manj-presledka" style="margin-bottom: 1em;" @click.prevent.stop="$router.push('/seznam/' + seznam.id)">
                <Podcast :naslov="seznam['ime']" :slika="urlSeznama(seznam)"
                :eventi="['play', 'shuffle']" @play="predvajajSeznam(seznam.id, false)" @shuffle="predvajajSeznam(seznam.id, true)">
                </Podcast>
              </div>
            </div>
            <div v-else>
              <div v-for="i in stevilaElementov[2]" :key="'pesmi-' + i" class="col-xs-4 col-sm-3 col-md-2 manj-presledka" style="margin-bottom: 1em;" @click.prevent.stop="predvajajPesemSSeznamom(pesem, pesem.seznam.id)">
                <PodcastLoading></PodcastLoading>
              </div>
            </div>
          </div>
        </div>
        
        <div v-if="!$brezPovezave">
          <div class="col-xs-12 naslovna-vrstica">
            <div class="pull-left naslov">
              <i class="fa fa-heart-o"></i>
              <span>Priljubljeni</span>
            </div>
            <div class="pull-right prikazi-vse" @click="$router.push('/priljubljeniSeznami')">
              <a>Prikaži vse</a>
            </div>
          </div>
          <div class="primeri">
            <p v-if="!shranjujZgodovino" style="text-align: center;">Za boljšo prvo stran si vklopi shranjevanje zgodovine.</p>
            <div v-if="prvaStran">
              <div v-for="seznam in prvaStran.priljubljeni.slice(0, stevilaElementov[3])" :key="'priljubljen-' + seznam.id" class="col-xs-4 col-sm-3 col-md-2 manj-presledka" style="margin-bottom: 1em;" @click.prevent.stop="$router.push('/seznam/' + seznam.id)">
                <Podcast :naslov="seznam['ime']" :slika="urlSeznama(seznam)"
                :eventi="['play', 'shuffle']" @play="predvajajSeznam(seznam.id, false)" @shuffle="predvajajSeznam(seznam.id, true)">
                </Podcast>
              </div>
            </div>
            <div v-else>
              <div v-for="i in stevilaElementov[3]" :key="'pesmi-' + i" class="col-xs-4 col-sm-3 col-md-2 manj-presledka" style="margin-bottom: 1em;" @click.prevent.stop="predvajajPesemSSeznamom(pesem, pesem.seznam.id)">
                <PodcastLoading></PodcastLoading>
              </div>
            </div>
          </div>
        </div>
      </div>
      
      <!-- <div class="col-xs-12" @click="$router.push('/miksi')" v-if="!$brezPovezave">
        <Pesem :naslov="'Miksi'" :avtor="'Personalizirani miksi ustvarjeni prav zate'" barva="green" ikona="fa-magic" album="Miksi"></Pesem>
      </div>
      <div class="col-xs-12" @click="$router.push('/iskanje')" v-if="!$brezPovezave">
        <Pesem :naslov="'Iskanje'" :avtor="'Iskanje'" barva="green" ikona="fa-search" album="Iskanje"></Pesem>
      </div>
      <div class="col-xs-12" @click="$router.push('/seznami')" v-if="!$brezPovezave">
        <Pesem :naslov="'Seznami predvajanja'" avtor="Seznami predvajanja" barva="green" ikona="fa-reorder" album="Skupina"></Pesem>
      </div>
      <div class="col-xs-12" @click="$router.push('/priljubljeniSeznami')" v-if="!$brezPovezave">
        <Pesem :naslov="'Priljubljeni seznami'" avtor="Priljubljeni seznami predvajanja" barva="green" ikona="fa-heart-o" album="Skupina"></Pesem>
      </div>
      <div class="col-xs-12" @click="$router.push('/nalozeniSeznami')" v-if="nalaganje.jeCordova()">
        <Pesem :naslov="'Naloženi seznami'" avtor="Seznami pripravljeni za poslušanje brez povezave" barva="green" ikona="fa-download" album="Skupina"></Pesem>
      </div>
      <div class="col-xs-12" @click="$router.push('/nalozi')" v-if="!$brezPovezave">
        <Pesem :naslov="'Naloži'" :avtor="'Naloži novo pesem'" barva="green" ikona="fa-cloud-upload" album="Skupina"></Pesem>
      </div>
      
      <div class="col-xs-12" @click="$router.push('/poddaje')">
        <Pesem :naslov="'Poddaje'" :avtor="'Moje poddaje'" barva="green" ikona="fa-headphones" album="Skupina"></Pesem>
      </div>
      
      <div class="col-xs-12" @click="$router.push('/zgodovina')" v-if="!$brezPovezave && shranjujZgodovino">
        <Pesem :naslov="'Zgodovina'" :avtor="'Zgodovina poslušanja'" barva="green" ikona="fa-history" album="Skupina"></Pesem>
      </div>
      
      <div class="col-xs-12" @click="$router.push('/posnetki')" v-if="!$brezPovezave && shranjujZgodovino">
        <Pesem :naslov="'Posnetki'" :avtor="'Nalaganje posnetkov (v mp4 formatu)'" barva="green" ikona="fa-youtube-play" album="Skupina"></Pesem>
      </div>

      <div class="col-xs-12" @click="$router.push('/nastavitve')">
        <Pesem :naslov="'Nastavitve'" :avtor="'Spremeni nastavitve'" barva="green" ikona="fa-cog" album="Skupina"></Pesem>
      </div> -->
    </div>
  </div>
</template>

<script>
import Rest from './../../storitve/rest'
import Vue from 'vue'
import { EventBus } from './../../storitve/event-bus'
import Pesem from './../skupno/Pesem.vue'
import Podcast from "./../skupno/Podcast.vue";
import PodcastLoading from "./../skupno/PodcastLoading.vue";
import nalaganje from './../../storitve/nalaganje-pesmi'

export default {
  components: {
    // Pesem,
    Podcast,
    PodcastLoading
  },
  name: 'koren',
  props: {
  },
  data () {
    return {
      shranjujZgodovino: true,
      nalaganje,
      prvaStran: null,
      stevilaElementov: [6,4,12,6]
    }
  },
  created: function () {
    let th = this
    this.preberiNastavitve()
    EventBus.$on('posodobiBrezPovezave', this.posodobi);
    Rest.request('GET', `/uporabniki/prvaStran`).then((data) => {
      th.prvaStran = data
    }).catch((err) => {
      console.log(err)
    })
    this.posodobiSirino()
    window.addEventListener("resize", this.resizeHandeler);
  },
  destroyed() {
    window.removeEventListener("resize", this.resizeHandeler);
  },
  beforeDestroy () {
    EventBus.$off('posodobiBrezPovezave', this.posodobi);
  },
  computed: {
    brezPovezave() {
      return Vue.prototype.$brezPovezave;
    }
  },
  methods: {
    resizeHandeler (e) {
      this.posodobiSirino()
    },
    posodobiSirino () {
      let sirina = window.innerWidth;
      console.log(sirina)
      if (sirina <= 768) {
        this.stevilaElementov = [6,2,6,6]
      } else if (sirina <= 992) {
        this.stevilaElementov = [4,4,8,4]
      } else {
        this.stevilaElementov = [6,4,12,6]
      }
    },
    predvajajMiks(tip) {
      Rest.request('POST', `/miksi/pesmi`, {tipMiksa: tip}).then((data) => {
        this.$emit('predvajaj', [data, 0]);
      }).catch((err) => {
        console.log(err)
      })
    },
    predvajajSeznam(id, premesaj) {
      nalaganje.pridobiSeznamPaginirano(id, {'limit': 50, 'random': premesaj}).then((data) => {
        let izbrana = 0
        if (premesaj) {
          izbrana = Math.floor(Math.random() * data.pesmi.length)
        }
        this.$emit('predvajaj', [data.pesmi, izbrana]);
      }).catch((err) => {
      })
    },
    predvajajPesemSSeznamom(pesem, idSeznama) {
      nalaganje.pridobiSeznamPaginirano(idSeznama, {'limit': 50, 'random': true}).then((data) => {
        let izbrana = 0
        let pesmi = data.pesmi
        pesmi.unshift(pesem)
        this.$emit('predvajaj', [pesmi, izbrana]);
      }).catch((err) => {
      })
    },
    urlSlike(pesem) {
      // return "/api/v1/slikeSeznamov/pesem/" + pesem.id;
      if (pesem.ytId) {
        return "https://i.ytimg.com/vi/" + pesem.ytId + "/mqdefault.jpg";
      } else {
        return this.nalaganje.pretvoriLokalniUrl('/api/v1/slikeSeznamov/' + this.seznam.id);
      }
    },
    urlSeznama(pesem) {
      return "/api/v1/slikeSeznamov/" + pesem.id;
    },
    posodobi(data) {
      this.$forceUpdate();
      console.log('posodobi-koren')
    },
    nacinBrezPovezave() {
      Vue.prototype.$brezPovezave = true
      EventBus.$emit('posodobiBrezPovezave');
      // Vue.forceUpdate();
    },
    preberiNastavitve() {
      if (localStorage.getItem('zgodovina')) {
        this.shranjujZgodovino = (localStorage.getItem('zgodovina') === 'true')
      }
    },
    click: function () {
      console.log('klik dela');
      EventBus.$emit('klik', "abc");
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.naslovna-vrstica {
  margin-bottom: 1em;
}
.naslovna-vrstica span {
  margin-left: 1ex;
}
.naslovna-vrstica i {
  /* font-size: 25pt; */
}
.naslovna-vrstica .naslov {
  font-size: 20pt;
}
.prikazi-vse {
  cursor:pointer;
  padding: 10px;
  border-radius: 40px;
  color: #065fd4;
}
.prikazi-vse:hover {
  background-color: #3c8dbc50;
}
.pikice{
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
.clickable{
  cursor: pointer; 
}
.manj-presledka {
  padding: 7px;
}
</style>
