<template>
  <div>
    <div class="container">
    <section class="content-header">
      <h1>
        Najljubši seznami predvajanja
        <button type="button" class="btn btn-default" @click.prevent.stop="$router.push('/urediPriljubeljnoMapo/'+mapa)" v-if="mapa">
            <i class="fa fa-cogs"></i></button>
        <button type="button" class="btn btn-default" @click.prevent.stop="spremeniSlikeSeznamov()" style="margin-left: 1ex">
          <i v-if="slikeSeznamov" class="fa fa-list"></i>
          <i v-else class="fa fa-th"></i>
        </button>
        <button type="button" class="btn btn-default" data-toggle="modal" data-target="#modal-sortiraj" style="margin-left: 1ex">
          <i class="fa fa-sort"></i>
        </button>
        <small></small>
      </h1>
      <ol class="breadcrumb">
        <li><a @click="$router.push('/')">Domov</a></li>
        <li><a @click="$router.push('/priljubljeniSeznami')">Priljubljeni seznami</a></li>
      </ol>
    </section>
      <br />

      <div v-for="(m) in mape" :key="m.id">
        <div v-if="slikeSeznamov" class="col-xs-6 col-sm-3 col-md-2" @click="$router.push('/priljubljeniSeznami/' + m.id)" style="padding: 7px">
          <Podcast :naslov="m.ime" :slika="'/api/v1/slikeSeznamov/' + m.id" :zasebno="m.zasebno" :vSkupini="m.vSkupini" :mapa="true">
            <button type="button" class="btn btn-default form-control" @click.prevent.stop="$router.push('/urediPriljubeljnoMapo/'+m.id)">
                <i class="fa fa-cogs"></i></button>
          </Podcast>
        </div>
        <div v-else class="col-xs-12" @click="$router.push('/priljubljeniSeznami/' + m.id)">
          <Pesem :naslov="m.ime" avtor=" " barva="orange" ikona="fa-folder-o" album="Zbirka">
            <div class="pull-right">
              <button type="button" class="btn btn-default" @click.prevent.stop="$router.push('/urediPriljubeljnoMapo/'+m.id)">
                <i class="fa fa-cogs"></i></button>
            </div>
          </Pesem>
        </div>
      </div>

      <div v-for="(seznam) in seznami" :key="seznam.id">
        <div v-if="slikeSeznamov" class="col-xs-6 col-sm-3 col-md-2" @click="$router.push('/seznam/' + seznam.id)" style="padding: 7px">
          <Podcast :naslov="seznam.ime" :slika="'/api/v1/slikeSeznamov/' + seznam.id" :zasebno="seznam.zasebno" :vSkupini="seznam.vSkupini"
          :eventi="['play', 'shuffle']" @play="predvajajSeznam(seznam.id, false)" @shuffle="predvajajSeznam(seznam.id, true)">
            <button type="button" class="btn btn-default form-control" @click.prevent.stop="$router.push('/seznam/'+seznam.id+'/uredi')">
                <i class="fa fa-cogs"></i></button>
            <br/>
            <button type="button" class="btn btn-default form-control" @click.prevent.stop="seznamVVrsto(seznam.id)" style="margin-top:1ex">
                <i class="fa fa-mail-forward"></i></button>
          </Podcast>
        </div>
        <div v-else class="col-xs-12" @click="$router.push('/seznam/' + seznam.id)">
          <Pesem :naslov="seznam.ime" avtor=" " barva="orange" ikona="fa-reorder" album="Seznam predvajanja" :zasebno="seznam.zasebno" :vSkupini="seznam.vSkupini">
            <div class="pull-right">
              <button type="button" class="btn btn-default" @click.prevent.stop="$router.push('/seznam/'+seznam.id+'/uredi')">
                <i class="fa fa-cogs"></i></button>
            </div>
          </Pesem>
        </div>
      </div>
      <div v-if="slikeSeznamov" class="col-xs-6 col-sm-3 col-md-2" data-toggle="modal" data-target="#modal-dodaj-mapo" style="padding: 7px">
        <Podcast :naslov="'Ustvari zbirko'" :slika="darkMode ? '/plus-dark.png' : 'plus.png'"></Podcast>
      </div>
      <div v-else class="col-xs-12" data-toggle="modal" data-target="#modal-dodaj-mapo">
        <Pesem :naslov="'Ustvari zbirko'" avtor=" " barva="orange" ikona="fa-plus" album="Ustvari zbirko">
        </Pesem>
      </div>
    </div>

    <div class="modal fade" id="modal-dodaj-mapo">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span></button>
            <h4 class="modal-title">Dodaj zbirko</h4>
          </div>
          <div class="modal-body">
            <div class="form-group">
              <label for="exampleInputEmail1">Ime zbirke</label>
              <input v-model="imeSeznama" type="text" class="form-control">
            </div>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-default pull-left" data-dismiss="modal">Zapri</button>
            <button type="button" class="btn btn-primary" @click="dodajMapo()">Potrdi</button>
          </div>
        </div>
        <!-- /.modal-content -->
      </div>
      <!-- /.modal-dialog -->
    </div>
    
    
    <div class="modal fade" id="modal-sortiraj">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span></button>
            <h4 class="modal-title">Določi vrstni red</h4>
          </div>
          <div class="modal-body">
            Zbirke:
            <ul class="list-group">
              <draggable :list="mape" ghost-class="ghost" @change="posodobiVrstniRedZbirk">
                <li class="list-group-item" v-for="(m) in mape" :key="m.id">
                  {{m.ime}}
                </li>
              </draggable>
            </ul>

            Seznami:
            <ul class="list-group">
              <draggable :list="seznami" ghost-class="ghost" @change="posodobiVrstniRedSeznamov">
                <li class="list-group-item" v-for="(m) in seznami" :key="m.id">
                  {{m.ime}}
                </li>
              </draggable>
            </ul>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-default pull-left" data-dismiss="modal">Zapri</button>
          </div>
        </div>
        <!-- /.modal-content -->
      </div>
      <!-- /.modal-dialog -->
    </div>
  </div>
</template>

<script>
// import draggable from "vuedraggable";
import Rest from './../../storitve/rest'
import Vue from 'vue'
import { EventBus } from './../../storitve/event-bus'
import Pesem from './../skupno/Pesem.vue'
import Podcast from "./../skupno/Podcast.vue";
import draggable from "vuedraggable";
import nalaganje from './../../storitve/nalaganje-pesmi'

export default {
  components: {
    Pesem,
    Podcast,
    draggable
  },
  name: 'seznami',
  props: ['mapa'],
  data () {
    return {
      seznami: [],
      mape: [],
      imeSeznama: "",
      zasebno: false,
      slikeSeznamov: true,
      darkMode: false
    }
  },
  created: function () {
    if (localStorage.getItem('dark-mode')) {
      this.darkMode = (localStorage.getItem('dark-mode') === 'true')
    }
    this.pridobiSezname();
    if (localStorage.getItem("slike-seznamov") !== null) {
      this.slikeSeznamov = (typeof localStorage.getItem("slike-seznamov") === "boolean" ? localStorage.getItem("slike-seznamov") : localStorage.getItem("slike-seznamov") === 'true')
    }
    if (Vue.prototype.$brezPovezave) {
      this.slikeSeznamov = false
    }
  },
  watch: {
    mapa: function (staro, novo) {
      this.seznami = []
      this.mape = []
      this.pridobiSezname();
    }
  },
  methods: {
    posodobiVrstniRedZbirk() {
      let vrstniRed = []
      for (let i = 0; i < this.mape.length; i++) {
        vrstniRed.push(this.mape[i].id)
      }
      let postdata = {
        idStarsa: this.mapa,
        vrstniRed: vrstniRed
      }
      Rest.request('POST', `/mape/moji/vrstniRedZbirke`, postdata).then((data) => {
      }).catch((err) => {
      })
    },
    seznamVVrsto (idSeznama) {
      Rest.request('POST', `/seznami/pesmi`, {idSeznama: idSeznama}).then((data) => {
        this.$emit('predvajajNaslednjo', data.pesmi);
      }).catch((err) => {
      })
      this.$swal.fire({
        position: 'top',
        title: 'Seznam dodan v vrsto za predvajanje',
        icon: 'success',
        showConfirmButton: false,
        timerProgressBar: true,
        backdrop: null,
        timer: 1000
      })
    },
    posodobiVrstniRedSeznamov() {
      let vrstniRed = []
      for (let i = 0; i < this.seznami.length; i++) {
        vrstniRed.push(this.seznami[i].id)
      }
      let postdata = {
        idStarsa: this.mapa,
        vrstniRed: vrstniRed
      }
      Rest.request('POST', `/mape/moji/vrstniRedSeznami`, postdata).then((data) => {
      }).catch((err) => {
      })
    },
    predvajajSeznam(id, premesaj) {
      nalaganje.pridobiSeznamPaginirano(id, {'limit': 50, 'random': premesaj}).then((data) => {
        let izbrana = 0
        if (premesaj) {
          izbrana = Math.floor(Math.random() * data.pesmi.length)
        }
        this.$emit('predvajaj', [data.pesmi, izbrana]);
      }).catch((err) => {
      })
    },
    spremeniSlikeSeznamov() {
      this.slikeSeznamov = !this.slikeSeznamov
      localStorage.setItem("slike-seznamov", this.slikeSeznamov)
    },
    dodajMapo: function() {
      if (this.imeSeznama.length === 0) {
        window.jQuery("#modal-dodaj-mapo").modal("toggle")
        return
      }
      let podatki = {
        imeMape: this.imeSeznama,
        mapa: this.mapa
      }
      Rest.request('POST', `/mape/moji/ustvari`, podatki).then((data) => {
        this.pridobiSezname()
        this.imeSeznama = ""
        window.jQuery("#modal-dodaj-mapo").modal("toggle")
      }).catch((err) => {
        console.log(err)
      })
    },
    pridobiSezname: function() {
      let postdata={
        mapa:this.mapa
      }
      Rest.request('POST', `/seznami/moji`, postdata).then((data) => {
        this.seznami = data
      }).catch((err) => {
        console.log(err)
      })
      Rest.request('POST', `/mape/moji`, postdata).then((data) => {
        this.mape = data
      }).catch((err) => {
        console.log(err)
      })
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
