<template>
  <div>
    <div class="container">

    <section class="content-header">
      <h1>
        Naloži pesem
        <small></small>
      </h1>
      <ol class="breadcrumb">
        <li><a @click="$router.push('/')">Domov</a></li>
        <li><a @click="$router.push('/naloziLokalno')">Naloži pesem</a></li>
      </ol>
    </section>
      <br />
      <div class="col-xs-12">
        <div class="box box-primary" v-if="!koncano">
          <div class="box-header with-border">
            <h3 class="box-title">Dodaj lokalno pesem</h3>
          </div>
          <!-- /.box-header -->
          <!-- form start -->
          <form>
            <div class="box-body">
              <div class="form-group">
                <label>Album</label>
                <input v-model="album" type="text" class="form-control">
              </div>

              <div class="form-group">
                <label>Avtor</label>
                <input v-model="avtor" type="text" class="form-control">
              </div>

              <div class="form-group">
                <div class="checkbox">
                  <label>
                    <input type="checkbox" v-model="skrita">
                    <i class="fa fa-eye-slash" v-if="skrita"></i>
                    <i class="fa fa-eye" v-else></i>
                    Skrita
                  </label>
                </div>
              </div>

              <div class="form-group">
                <label>Seznami predvajanja</label>
                <div class="input-group">
                  <input type="text" autocomplete="off" name="q" class="form-control" placeholder="Iskanje..." v-model="iskalnoGeslo">
                  <span class="input-group-btn">
                    <button name="search" id="search-btn" class="btn btn-flat" @click.prevent.stop=""><i class="fa fa-search"></i>
                    </button>
                  </span>
                </div>
                <div class="checkbox" v-for="seznam in filtriraniSeznami" :key="seznam.id">
                  <label>
                    <input type="checkbox" v-model="izbraniSeznami" :value="seznam.id">
                    <span v-if="seznam.imeMape">{{seznam.imeMape}}/</span>
                    {{seznam.ime}}
                  </label>
                </div>
              </div>
            </div>
            <!-- /.box-body -->

            <div class="box-footer">
              <button @click.prevent="dodajPesem()" class="btn btn-primary">Potrdi</button>
            </div>
          </form>
        </div>
        <div class="box box-primary" v-else>
          <div class="box-header with-border">
            <h3 class="box-title">Dodaj pesem</h3>
          </div>
          <div class="box-body">
            Pesem bo kmalu dodana
          </div>
          <!-- /.box-body -->

          <div class="box-footer">
            <button @click.prevent="ponastavi()" class="btn btn-primary">Dodaj novo pesem</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Rest from './../../storitve/rest'
import Vue from 'vue'
import { EventBus } from './../../storitve/event-bus.js'
import Pesem from './../skupno/Pesem.vue'
import {parseTitleString} from './../../storitve/parseTitle'

export default {
  components: {
  },
  name: 'dodajLokalnoPesem',
  props: [],
  data () {
    return {
      album: "",
      avtor: "",
      koncano: false,
      seznami: [],
      izbraniSeznami: [],
      skrita: false,
      iskalnoGeslo: ""
    }
  },
  created: function () {
    this.pridobiSezname()
  },
  computed: {
    filtriraniSeznami () {
      return this.seznami.filter(el => el.ime.toLowerCase().indexOf(this.iskalnoGeslo.toLowerCase()) >= 0)
    }
  },
  methods: {
    pridobiSezname() {
      Rest.request('GET', `/vsi-seznami`).then((data) => {
        this.seznami = data
        console.log(data)
      }).catch((err) => {
        console.log(err)
      })
    },
    ponastavi: function() {
      this.album = ""
      this.avtor = ""
      this.koncano = false
    },
    dodajPesem: function() {
      let podatki = {
        album: this.album,
        avtor: this.avtor,
        izbraniSeznami: this.izbraniSeznami,
        skrita: this.skrita
      }
      Rest.request('POST', (`/pesmi/dodajLokalnePesmi`), podatki).then((data) => {
        EventBus.$emit('sproziObvestila', 10);
        this.koncano = true;
      }).catch((err) => {
        console.log(err)
      })
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
