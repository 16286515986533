<template>
  <div>
    <div class="container">
    <section class="content-header">
      <h1>
        {{podcast.ime}}
        <small></small>
        <button type="button" class="btn btn-primary" @click.prevent.stop="$router.push('/poddaja/' + podcast.id + '/zaznamki')" style="margin-right: 1ex"><i class="fa fa-bookmark"></i></button>
        <button type="button" class="btn btn-primary" data-toggle="modal" data-target="#modal-nastavi-urejenost" style="margin-right: 1ex">
          <i class="fa fa-sort-amount-desc "></i>
        </button>
        <button type="button" class="btn btn-primary" @click="dodatneMoznosti = !dodatneMoznosti" style="margin-right: 1ex">
          <i v-if="dodatneMoznosti" class="fa fa-eye"></i>
          <i v-else class="fa fa-eye-slash"></i>
        </button>
        <button type="button" class="btn btn-danger" @click.prevent.stop="odstraniNarocnino()" style="margin-right: 1ex"><i class="fa fa-bell-slash"></i></button>
        <button type="button" class="btn btn-danger" @click.prevent.stop="odstraniPodcast()">
            <i class="fa fa-trash"></i></button>
      </h1>
      <ol class="breadcrumb" style="">
        <li><a @click="$router.push('/')">Domov</a></li>
        <li><a @click="$router.push('/poddaje')">Poddaje</a></li>
        <li><a @click="$router.push('/poddaja/' + podcast.id)">{{podcast.ime}}</a></li>
      </ol>
    </section>
      <br />
      <div v-if="potekaNalaganje" style="text-align: center;">
        <h2>Poteka pripravljanje epizode. Prosimo počakajte.</h2>
        <div class="lds-ellipsis"><div></div><div></div><div></div><div></div></div>
        <p>To lahko traja nekaj minut.</p>
      </div>
      <div v-else>
        <div v-if="podcast.epizode">
          <div v-for="(epizoda) in podcast.epizode" :key="epizoda.id">
            <div class="col-xs-12" @click="posodobiInPredvajaj(epizoda)">
              <!-- {{epizoda}} -->
              <Pesem :naslov="epizoda.ime" :avtor="odstraniHtml(epizoda.opis)" :barva="(epizoda.ytEpizoda && !epizoda.nalozenaEpizodaId) ? 'gray' : 'purple'" :ikona="epizoda.koncano ? 'fa-check' : 'fa-headphones'" :album="epizoda.datumIzdaje" :odstotek="epizoda.koncano ? (100) : (epizoda.trajanjeSekunde > 0 ? (100 * epizoda.mestoPoslusanjaSekunde / epizoda.trajanjeSekunde) : 0)" :nalozena="epizoda.nalozena">
                <div class="btn-group pull-right">
                  <button type="button" class="btn btn-default" @click.prevent.stop="resetirajEpizodo(epizoda.id)" v-if="dodatneMoznosti">
                    <!-- <i class="fa fa-download"></i> -->
                    Resetiraj
                  </button>
                  <button type="button" class="btn btn-default" @click.prevent.stop="naloziEpizodo(epizoda.id)" v-if="nalaganje.jeCordova() && !epizoda.nalozena">
                    <i class="fa fa-download"></i>
                  </button>
                  <button type="button" class="btn btn-default" @click.prevent.stop="odstraniEpizodo(epizoda.id)" v-if="nalaganje.jeCordova() && epizoda.nalozena">
                    <i class="fa fa-eraser"></i>
                  </button>
                  <button type="button" class="btn btn-default" @click.prevent.stop="epizoda.koncano = true; koncajPodcast(epizoda.id, true)" v-if="!epizoda.koncano">
                    <i class="fa fa-check"></i>
                  </button>
                  <button type="button" class="btn btn-default" @click.prevent.stop="epizoda.koncano = false; koncajPodcast(epizoda.id, false)" v-else>
                    <i class="fa fa-remove"></i>
                  </button>
                </div>
              </Pesem>
            </div>
          </div>
        </div>
        <div v-if="!vse && podcast.epizode && podcast.epizode.length == 50" style="text-align: center">
            <button class="btn btn-default" @click="vse = true; pridobiEpizode(true)">Prikaži vse epizode</button>
        </div>
      </div>
    </div>

    <div class="modal fade" id="modal-nastavi-urejenost">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
            <h4 class="modal-title">Nastavi urejenost</h4>
          </div>
          <div class="modal-body">
            <div class="">
              <div class="form-group">
                <label>Vrstni red</label>
                <select class="form-control" v-model="urejenost">
                  <option value="0">Najprej najnovejše</option>
                  <option value="1">Najprej najstarejše</option>
                  <option value="2">Po imenu</option>
                  <option value="3">Po imenu padajoče</option>
                </select>
                <br/>
                <label>Filter</label>
                <select class="form-control" v-model="filter">
                  <option value="0">Vse</option>
                  <option value="1">Samo neposlušane</option>
                  <option value="2">Naslednje epizode</option>
                </select>
              </div>
              <p v-if="filter == 2">Način prikaza naslednjih epizod je najbolje uporabiti z načinom 'Najprej najnovejše'. V tem načinu se preskočijo še neposlušane epizode na začetku seznama.</p>
              <div class="form-group">
                <button data-dismiss="modal" @click="odstraniPodvojene()" class="btn btn-primary form-control">
                  Odstrani podvojene epizode
                </button>
              </div>
              <div class="form-group">
                <button data-dismiss="modal" @click="posodobiPodcast()" class="btn btn-primary form-control">
                  Posodobi epizode poddaje
                </button>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-primary pull-right" data-dismiss="modal" @click="nastaviUrejenost()">Potrdi</button>
            <button type="button" class="btn btn-default pull-left" data-dismiss="modal">Zapri</button>
          </div>
        </div>
        <!-- /.modal-content -->
      </div>
      <!-- /.modal-dialog -->
    </div>
  </div>
</template>

<script>
import Rest from './../../../storitve/rest'
import nalaganje from './../../../storitve/nalaganje-pesmi'
import Vue from 'vue'
import { EventBus } from './../../../storitve/event-bus'
import Pesem from './../../skupno/Pesem.vue'

export default {
  components: {
    Pesem
  },
  name: 'epizode',
  props: ['idPodcasta'],
  data () {
    return {
      podcast: {},
      vse: false,
      potekaNalaganje: false,
      urejenost: 0,
      filter: 0,
      nalaganje,
      dodatneMoznosti: false
    }
  },
  created: function () {
    this.pridobiEpizode(this.vse);
  },
  methods: {
    resetirajEpizodo(idEpizode) {
      this.koncajPodcast(idEpizode, false)
      let postdata = {
        idEpizode: idEpizode,
        cas: 0
      };
      nalaganje.posljiCasPodcasta(postdata)
        .then(data => {
          for (let i = 0; i < this.podcast.epizode.length; i++) {
            if (this.podcast.epizode[i].id == idEpizode) {
              this.podcast.epizode[i].koncano = false
              this.podcast.epizode[i].mestoPoslusanjaSekunde = 0
            }
          }
        })
        .catch(err => {
          console.log(err);
        });
    },
    naloziEpizodo(idEpizode) {
      this.potekaNalaganje = true
      nalaganje.naloziPodcast(this.idPodcasta, idEpizode).then(() => {
        this.potekaNalaganje = false
        this.pridobiEpizode(this.vse)
      }).cath(() => {
        this.potekaNalaganje = false
        this.pridobiEpizode(this.vse)
      })
    },
    odstraniEpizodo(idEpizode) {
      nalaganje.izbrisiPesemPoId(idEpizode)
      this.pridobiEpizode(this.vse)
    },
    posodobiPodcast() {
      this.$swal.fire({
        position: 'top',
        title: 'Poteka posodabljanje',
        icon: 'info',
        showConfirmButton: false,
        onBeforeOpen: () => {
          this.$swal.showLoading()
        }
      })
      Rest.request("POST", `/podcasti/posodobiPodcast`, {idPodcasta: this.podcast.id})
      .then(data => {
        this.$swal.close()
        this.pridobiEpizode(this.vse)
      })
      .catch(err => {
        this.$swal.close()
        console.log(err);
      });
    },
    odstraniPodvojene() {
      Rest.request("POST", `/podcasti/odstraniPodvojene`, {idPodcasta: this.podcast.id})
      .then(data => {
        this.pridobiEpizode(this.vse)
        this.$swal.fire()({
          position: 'top',
          title: 'Odstranjevanje končano',
          icon: 'success',
          showConfirmButton: false,
          timerProgressBar: true,
          backdrop: null,
          timer: 2000
        })
      })
      .catch(err => {
        console.log(err);
      });
    },
    nastaviUrejenost() {
      let postdata = {
        idPodcasta: this.podcast.id,
        urejenost: parseInt(this.urejenost),
        filter: parseInt(this.filter)
      };
      Rest.request("POST", `/podcasti/nastaviUrejenost`, postdata)
      .then(data => {
        this.pridobiEpizode(this.vse)
      })
      .catch(err => {
        console.log(err);
      });
    },
    odstraniNarocnino() {
      this.$swal.fire({
        title: 'Res želiš odstraniti to poddajo izmed svojih priljubljenih.',
        text: "Če je poddaja zasebna, je ne moreš dobiti nazaj",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Da',
        cancelButtonText: 'Ne'
      }).then((result) => {
        if (result.value) {
          Rest.request("POST", `/podcasti/odnaroci`, {idPodcasta: this.idPodcasta})
          .then(data => {
            this.$router.push({ path:'/' })
          }).catch(err => {
            this.pridobiEpizode(this.vse)
            console.log(err);
          });
        }
      })
    },
    odstraniPodcast() {
      this.$swal.fire({
        title: 'Res želiš izbrisati to poddajo',
        text: "Tega dejanja se ne da razveljaviti. Izbrisani bodo tudi vsi časi poslušanja epizod te poddaje.",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Da',
        cancelButtonText: 'Ne'
      }).then((result) => {
        this.$swal.fire({
          title: 'Si povsem prepričan?',
          text: "To dejanje vpliva tudi na druge uporabnike aplikacije",
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Da',
          cancelButtonText: 'Ne'
        }).then((result) => {
          if (result.value) {
            Rest.request("POST", `/podcasti/odstrani`, {idPodcasta: this.idPodcasta})
            .then(data => {
              this.$router.push({ path:'/' })
            }).catch(err => {
              this.pridobiEpizode(this.vse)
              console.log(err);
            });
          }
        })
      })
    },
    koncajPodcast(id, koncano) {
      let postdata = {
        idEpizode: id,
        koncano: koncano
      };
      Rest.request("POST", `/podcasti/nastaviKoncano`, postdata)
        .then(data => {
      })
      .catch(err => {
        this.pridobiEpizode(this.vse)
        console.log(err);
      });
    },
    odstraniHtml(besedilo) {
      if ((besedilo === undefined) || (besedilo === null) || (besedilo === '')) {
        return false;
      } else {
        besedilo = besedilo.toString();
        return besedilo.replace(/<[^>]*>/g, '');
      }
    },
    pridobiEpizode: function(vse) {
      nalaganje.pridobiPodcast(this.idPodcasta, vse).then((podcast) => {
        this.podcast = podcast
        this.urejenost = this.podcast.narocnina.urejenost
        this.filter = this.podcast.narocnina.filter
      }).catch((err) => {
        console.log(err)
      })
      // Rest.request('POST', `/podcasti/epizode`, {idPodcasta: this.idPodcasta, vse: vse}).then((data) => {
      //   this.podcast = data
      //   this.urejenost = this.podcast.narocnina.urejenost
      //   this.filter = this.podcast.narocnina.filter
      // }).catch((err) => {
      //   console.log(err)
      // })
    },
    posodobiInPredvajaj (podcast) {
      nalaganje.pridobiPodcastEpizodo(this.podcast.id, podcast.id).then((data) => {
        this.predvajajPodcast(data)
      }).catch((err) => {
        console.log(err)
      })
    },
    predvajajPodcast: function(podcast) {
      if (podcast.ytEpizoda && !podcast.nalozenaEpizodaId) {
        this.$swal.fire({
          title: 'Želiš pripraviti to poddajo?',
          text: "Priprava poddaje lahko traja nekaj časa",
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Da',
          cancelButtonText: 'Ne'
        }).then((result) => {
          if (result.value) {
            this.potekaNalaganje = true
            Rest.request('POST', `/podcasti/naloziEpizodo`, {idEpizode: podcast.id}).then((data) => {
              this.potekaNalaganje = false
              this.pridobiEpizode(this.vse)
            }).catch((err) => {
              this.potekaNalaganje = false
              console.log(err)
            })
          }
        })
      } else {
        if (podcast.ytEpizoda && !podcast.epizodaUrl) {
          podcast.epizodaUrl = '/podcast/' + podcast.nalozenaEpizodaId
        }
        podcast.podcast = true
        if (podcast.koncano) {
          podcast.mestoPoslusanjaSekunde = 0
        }
        this.$emit('predvajajPodcast', podcast);
      }
    },
    click: function () {
      console.log('klik dela');
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

.lds-ellipsis {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}
.lds-ellipsis div {
  position: absolute;
  top: 33px;
  width: 13px;
  height: 13px;
  border-radius: 50%;
  background: rgb(122, 120, 120);
  animation-timing-function: cubic-bezier(0, 1, 1, 0);
}
.lds-ellipsis div:nth-child(1) {
  left: 8px;
  animation: lds-ellipsis1 0.6s infinite;
}
.lds-ellipsis div:nth-child(2) {
  left: 8px;
  animation: lds-ellipsis2 0.6s infinite;
}
.lds-ellipsis div:nth-child(3) {
  left: 32px;
  animation: lds-ellipsis2 0.6s infinite;
}
.lds-ellipsis div:nth-child(4) {
  left: 56px;
  animation: lds-ellipsis3 0.6s infinite;
}
@keyframes lds-ellipsis1 {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}
@keyframes lds-ellipsis3 {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(0);
  }
}
@keyframes lds-ellipsis2 {
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(24px, 0);
  }
}

</style>
