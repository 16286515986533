<template>
  <div>
    <div class="container">
      <section class="content-header">
        <h1>
          Uredi pesem
          <small></small>
        </h1>
        <ol class="breadcrumb">
          <li><a @click="$router.push('/')">Domov</a></li>
          <li><a @click="$router.push('/pesem/'+idPesmi)">Uredi pesem</a></li>
        </ol>
      </section>
      <br />
      <div class="col-xs-12">
        <div class="box box-primary">
          <div class="box-header with-border">
            <h3 class="box-title">Uredi pesem</h3>
            <button class="btn btn-danger pull-right" @click="izbrisiPesem()"><i class="fa fa-trash"></i></button>
          </div>
          <!-- /.box-header -->
          <!-- form start -->
          <form>
            <div class="box-body">
              <div class="form-group">
                <label>Naslov pesmi</label>
                <input v-model="naslov" type="text" class="form-control">
              </div>

              <div class="form-group">
                <label>Album</label>
                <input v-model="album" type="text" class="form-control">
              </div>

              <div class="form-group">
                <label>Avtor</label>
                <input v-model="avtor" type="text" class="form-control">
              </div>

              <div class="form-group">
                <div class="checkbox">
                  <label>
                    <input type="checkbox" v-model="skrita">
                    <i class="fa fa-eye-slash" v-if="skrita"></i>
                    <i class="fa fa-eye" v-else></i>
                    Skrita
                  </label>
                </div>
              </div>

              <div class="form-group">
                <label>Besedilo</label>
                <textarea v-model="besedilo" class="form-control"></textarea>
              </div>

              <div class="form-group">
                <label>Seznami predvajanja</label>
                <div class="input-group">
                  <input type="text" autocomplete="off" name="q" class="form-control" placeholder="Iskanje..." v-model="iskalnoGeslo">
                  <span class="input-group-btn">
                    <button name="search" id="search-btn" class="btn btn-flat" @click.prevent.stop=""><i class="fa fa-search"></i>
                    </button>
                  </span>
                </div>
                <div class="checkbox" v-for="seznam in filtriraniSeznami" :key="seznam.id">
                  <label>
                    <input type="checkbox" v-model="seznam.jeNaSeznamu" @click="seznam.spremenjeno = true">
                    {{seznam.imeSeznama}}
                  </label>
                </div>
              </div>
            </div>
            <!-- /.box-body -->

            <div class="box-footer">
              <button @click.prevent="posodobiPesem()" v-if="nalozeno" class="btn btn-primary">Posodobi</button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Rest from './../../storitve/rest'

export default {
  components: {
  },
  name: 'koren',
  props: ['idPesmi'],
  data () {
    return {
      album: "",
      avtor: "",
      naslov: "",
      seznami: [],
      koncano: false,
      skrita: false,
      iskalnoGeslo: "",
      besedilo: "",
      nalozeno: false
    }
  },
  created: function () {
    this.naloziPesem()
  },
  computed: {
    filtriraniSeznami () {
      return this.seznami.filter(el => el.imeSeznama.toLowerCase().indexOf(this.iskalnoGeslo.toLowerCase()) >= 0)
    }
  },
  methods: {
    izbrisiPesem: function() {
      this.$swal.fire({
        title: 'Želiš izbrisati pesem?',
        text: "Brisanja pesmi se ne da preklicati.",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Da',
        cancelButtonText: 'Ne'
      }).then((result) => {
        if (result.value) {
          console.log('ok')
          let podatki = {
            idPesmi: this.idPesmi
          }

          Rest.request("POST", `/pesem/izbrisi`, podatki)
            .then(data => {
              this.$router.push('/')
            })
            .catch(err => {
              console.log(err);
              // th.opozorila.push(err.status + " " + err.statusText);
            });
        }
      })
    },
    naloziPesem: function() {
      let podatki = {
        idPesmi: this.idPesmi
      }
      Rest.request('POST', `/pesem/podrobnosti`, podatki).then((data) => {
        this.album = data.album
        this.avtor = data.avtor
        this.naslov = data['ime-pesmi']
        this.seznami = data.seznami
        this.skrita = data.skrita
        this.besedilo = data.besedilo
        this.nalozeno = true
      }).catch((err) => {
        console.log(err)
      })
    },
    posodobiPesem: function() {
      let spremenjeniSeznami = []
      for (let i = 0; i < this.seznami.length; i++) {
        if (this.seznami[i].spremenjeno) {
          spremenjeniSeznami.push(this.seznami[i])
        }
      }
      let podatki = {
        idPesmi: this.idPesmi,
        album: this.album,
        avtor: this.avtor,
        naslov: this.naslov,
        seznami: spremenjeniSeznami,
        skrita: this.skrita,
        besedilo: this.besedilo
      }
      Rest.request('POST', `/pesem/podrobnosti/posodobi`, podatki).then((data) => {
        this.album = data.album
        this.avtor = data.avtor
        this.naslov = data['ime-pesmi']
        this.seznami = data.seznami
        this.besedilo = data.besedilo
        this.$swal.fire(
            'Uspešno posodobljeno',
            'Pesem uspešno posodobljena',
            'success'
          )
      }).catch((err) => {
        console.log(err)
      })
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
