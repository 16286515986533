<template>
  <div>
    <div class="container">
    <section class="content-header">
      <h1>
        Uredi seznam
        <small></small>
      </h1>
      <ol class="breadcrumb">
        <li><a @click="$router.push('/')">Domov</a></li>
        <!-- <li><a @click="$router.push('/seznami')">Seznami predvajanja</a></li> -->
        <!-- <li><a @click="$router.push('/seznam/' + idSeznama)">{{imeSeznama}}</a></li> -->
        <li v-for="drobtina in breadcrumbs" :key="drobtina.id"><a @click="$router.push(drobtina.naslov)">{{drobtina.ime}}</a></li>
        <li><a @click="$router.push('/seznam/' + idSeznama + '/uredi')">Uredi seznam</a></li>
      </ol>
    </section>
      <br />
      <div class="col-xs-12">
        <div class="box box-primary">
          <div class="box-header with-border">
            <h3 class="box-title">{{imeSeznama}}</h3>
            <button class="btn btn-danger pull-right" @click="izbrisiSeznam()"><i class="fa fa-trash"></i></button>
            <div v-if="avtor">
              <small>Avtor: {{avtor.uporabniskoIme}}</small>
            </div>
          </div>
          <!-- /.box-header -->
          <!-- form start -->
          <form>
            <div class="box-body">
              <div class="row">
                <div class="col-md-6">
                  <div class="form-group">
                    <label>Ime seznama</label>
                    <input v-model="imeSeznama" type="text" class="form-control" />
                  </div>

                  <div class="form-group">
                    <label>Mapa</label>
                    <select v-model="stars" class="form-control">
                      <option :value="''">Koren</option>
                      <option v-for="mapa in mape" :value="mapa.id" :key="mapa.id">{{mapa.ime}}</option>
                    </select>
                  </div>
                  
                  <div class="form-group">
                    <div class="checkbox">
                      <label>
                        <input type="checkbox" v-model="arhivirano">
                        <i class="fa fa-archive"></i>
                        Arhivirano
                      </label>
                    </div>
                  </div>
                  
                  <div class="form-group">
                    <div class="checkbox">
                      <label>
                        <input type="checkbox" v-model="zasebno">
                        <i class="fa fa-lock" v-if="zasebno"></i>
                        <i class="fa fa-unlock" v-else></i>
                        Zasebno
                      </label>
                    </div>
                  </div>

                  <div class="form-group" v-if="zasebno && skupine.length > 0">
                    Skupna raba s skupinami:
                    <div class="checkbox" v-for="skupina in skupine" :key="skupina.id">
                      <label>
                        <input type="checkbox" :value="skupina.id" v-model="izbraneSkupine">
                        {{skupina.ime}}
                      </label>
                    </div>
                  </div>
                </div>

                <div class="col-md-6">
                  <uredi-sliko :imeSeznama="imeSeznama" :idSeznama="idSeznama"></uredi-sliko>
                </div>
              </div>

              <label>Pesmi ({{pesmi.length}})</label>
              <!-- <span v-if="pesmi.length > 100">&nbsp;<small class="text-red">Na seznamu je {{pesmi.length}} pesmi! Nekatere funkcionalnosti morda ne bodo delovale pravilno.</small></span> -->
              <ul class="todo-list" v-if="pesmi">
                <draggable :list="pesmi" class="todo-list" ghost-class="ghost" handle=".handle">
                  <li v-for="(pesem, index) in pesmi"
                    :key="pesem['id']">
                    <!-- drag handle -->
                    <span class="handle" style="width:3%">
                      <i class="fa fa-ellipsis-v"></i>
                      <i class="fa fa-ellipsis-v"></i>
                    </span>
                    <!-- todo text -->
                    <span class="text pikice" style="width:80%">{{ pesem['ime-pesmi'] }}</span>
                    <span style="color:#dd4b39; width:10%">
                      <i class="pull-right fa fa-trash-o" @click.prevent.stop="odstraniPesem(index)"></i>
                      <i class="pull-right fa fa-mail-forward" @click.prevent.stop="dajNaKonec(index)"></i>
                    </span>
                  </li>
                </draggable>
                <li @click="odpriPesmi()" style="margin-top: 3px">
                  <!-- todo text -->
                  <span class="text pikice" style="width:80%"><i class="fa fa-plus"></i> Dodaj pesmi</span>
                </li>
                
                <li @click="odpriSezname()" style="margin-top: 3px">
                  <!-- todo text -->
                  <span class="text pikice" style="width:80%"><i class="fa fa-plus"></i> Dodaj sezname</span>
                </li>
              </ul>
              <!-- <button type="button" class="btn btn-default" >
                <i class="fa fa-plus"></i> pesmi
              </button> -->

            </div>
            <!-- /.box-body -->

            <div class="box-footer">
              <button @click.prevent="posodobiSeznam()" v-if="nalozeno" class="btn btn-primary">Posodobi</button>
              <button @click.prevent="dodajPriljubljenim()" class="btn btn-primary pull-right">
                <i class="fa fa-heart" v-if="priljubljen"></i>
                <i class="fa fa-heart-o" v-else></i>
              </button>
              <button @click.prevent="naloziSeznamKotZip()" v-if="nalozeno" class="btn btn-primary pull-right" style="margin-right: 1ex"><i class="fa fa-download"></i></button>
            </div>
          </form>
        </div>
      </div>

      <div class="modal fade" id="modal-dodaj">
        <div class="modal-dialog">
          <div class="modal-content">
            <div class="modal-header">
              <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span></button>
              <h4 class="modal-title">Dodaj pesmi na seznam predvajanja</h4>
            </div>
            <div class="modal-body">
              <div class="input-group">
                <input type="text" autocomplete="off" name="q" class="form-control" placeholder="Iskanje..." v-model="iskalnoGeslo" @change="pridobiPesmi()">
                <span class="input-group-btn">
                  <button type="submit" name="search" id="search-btn" class="btn btn-flat" @click.prevent="pridobiPesmi()"><i class="fa fa-search"></i>
                  </button>
                </span>
              </div>
              <small v-if="opozorilo">Iskalno geslo mora vsebovati vsaj tri znake.</small>

              <ul class="nav nav-pills nav-stacked">
                <li v-for="pesem in vsePesmi" :key="pesem.id">
                  <a class="col-xs-8 pikice"><span class="">{{pesem['ime-pesmi']}}</span>
                  </a>
                  <a class=" col-xs-4" @click="dodajPesem(pesem.id)"><span class="pull-right text-blue"><i class="fa fa-plus"></i> Dodaj</span></a>
                </li>
              </ul>
            </div>
            <div class="modal-footer">
              <button type="button" class="btn btn-default pull-left" data-dismiss="modal">Zapri</button>
            </div>
          </div>
          <!-- /.modal-content -->
        </div>
        <!-- /.modal-dialog -->
      </div>
      
      <div class="modal fade" id="modal-dodaj-seznam">
        <div class="modal-dialog">
          <div class="modal-content">
            <div class="modal-header">
              <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span></button>
              <h4 class="modal-title">Dodaj cel seznam</h4>
            </div>
            <div class="modal-body">
              <div class="input-group">
                <input type="text" autocomplete="off" name="q" class="form-control" placeholder="Iskanje..." v-model="iskalnoGeslo">
                <span class="input-group-btn">
                  <button type="submit" name="search" id="search-btn" class="btn btn-flat" @click.prevent="pridobiPesmi()"><i class="fa fa-search"></i>
                  </button>
                </span>
              </div>
              <small v-if="opozorilo">Iskalno geslo mora vsebovati vsaj tri znake.</small>

              <ul class="nav nav-pills nav-stacked">
                <li v-for="seznam in filtriraniSeznami" :key="seznam.id">
                  <a class="col-xs-8 pikice"><span class="">{{seznam.ime}}</span>
                  &nbsp;<span class="label label-warning">Seznam</span>
                  </a>
                  <a class=" col-xs-4" @click="dodajSeznam(seznam.id)"><span class="pull-right text-blue"><i class="fa fa-plus"></i> Dodaj</span></a>
                </li>
              </ul>
            </div>
            <div class="modal-footer">
              <button type="button" class="btn btn-default pull-left" data-dismiss="modal">Zapri</button>
            </div>
          </div>
          <!-- /.modal-content -->
        </div>
        <!-- /.modal-dialog -->
      </div>
    </div>

  </div>
</template>

<script>
import draggable from "vuedraggable";
import Rest from "./../../storitve/rest";
import Vue from "vue";
import VueSweetalert2 from 'vue-sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';
import UrediSliko from "./../skupno/UrediSliko.vue";

Vue.use(VueSweetalert2);

export default {
  components: {
    draggable,
    UrediSliko
  },
  name: "uredi-seznam",
  props: ["idSeznama"],
  data() {
    return {
      imeSeznama: "",
      iskalnoGeslo: "",
      pesmi: [],
      vsePesmi: [],
      zasebno: false,
      arhivirano: false,
      priljubljen: false,
      mape:[],
      stars: "",
      opozorilo: false,
      skupine: [],
      izbraneSkupine: [],
      breadcrumbs: [],
      nalozeno: false,
      seznami: [],
      avtor: null
    };
  },
  created: function() {
    this.naloziSeznam();
    this.pridobiSkupine();
    this.pridobiIzbraneSkupine();
  },
  computed: {
    filtriraniSeznami () {
      return this.seznami.filter(seznam => {
        return seznam.ime.toLowerCase().includes(this.iskalnoGeslo.toLowerCase())
     })
    }
  },
  methods: {
    naloziSeznamKotZip() {
      this.$swal.fire({
          title: 'Generiranje mape',
          html: 'Pripravljamo zip mapo',// add html attribute if you want or remove
          allowOutsideClick: false,
          showConfirmButton: false,
          onBeforeOpen: () => {
              this.$swal.showLoading()
          },
      });
      Rest.request("POST", `/seznami/nalozi`, { idSeznama: this.idSeznama })
        .then(data => {
          this.$swal.close()
          console.log(data.link)
          window.location.replace(data.link);
        })
        .catch(err => {
          this.$swal.close()
          console.log(err);
        });
    },
    dajNaKonec(index) {
      let p = this.pesmi[index]
      this.pesmi.splice(index, 1)
      this.pesmi.push(p)
    },
    dodajPesem(idPesmi) {
      for (let i = 0; i < this.vsePesmi.length; i++) {
        if (this.vsePesmi[i].id === idPesmi) {
          let pesem = this.vsePesmi.splice(i, 1)
          let dodaj = true
          for (let j = 0; j < this.pesmi.length; j++) {
            if (this.pesmi[j].id == idPesmi) {
              dodaj = false
            }
          }
          if (dodaj) {
            console.log(pesem[0])
            this.pesmi.push(pesem[0])
          }
          break
        }
      }
    },
    dodajSeznam(idSeznama) {
      Rest.request("POST", `/seznami/pesmi`, { idSeznama: idSeznama })
        .then(data => {
          let pesmi = data.pesmi;
          for (let i = 0; i < pesmi.length; i++) {
            let idPesmi = pesmi[i].id
            let dodaj = true
            for (let j = 0; j < this.pesmi.length; j++) {
              if (this.pesmi[j].id == idPesmi) {
                dodaj = false
              }
            }
            if (dodaj) {
              this.pesmi.push(pesmi[i])
            }
          }

        })
        .catch(err => {
          console.log(err);
        });
    },
    pridobiSezname () {
      Rest.request('POST', `/vsi-seznami`, {tudiArhivirano: true}).then((data) => {
        this.seznami = data
      }).catch((err) => {
        console.log(err)
      })
    },
    pridobiPesmi: function() {
      if (this.iskalnoGeslo.length <= 2) {
        this.opozorilo = true
        return
      }
      this.opozorilo = false
      let podatki = {
        geslo: this.iskalnoGeslo
      }
      Rest.request('POST', `/iskanje`, podatki).then((data) => {
        this.vsePesmi = []
        for (let i = 0; i < data.length; i++) {
          let odstrani = false
          for (let j = 0; j < this.pesmi.length; j++) {
            if (this.pesmi[j].id === data[i].id) {
              odstrani = true
              break
            }
          }
          if (!odstrani) {
            this.vsePesmi.push(data[i])
          }
        }
      }).catch((err) => {
        console.log(err)
      })
    },
    izbrisiSeznam: function() {
      this.$swal.fire({
        title: 'Želiš izbrisati seznam?',
        text: "Brisanja seznama se ne da preklicati.",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Da',
        cancelButtonText: 'Ne'
      }).then((result) => {
        if (result.value) {
          console.log('ok')
          let podatki = {
            idSeznama: this.idSeznama
          }

          Rest.request("POST", `/seznami/izbrisi`, podatki)
            .then(data => {
              this.$router.push('/seznami')
            })
            .catch(err => {
              console.log(err);
              // th.opozorila.push(err.status + " " + err.statusText);
            });
        }
      })
    },
    odstraniPesem: function(index) {
      this.pesmi.splice(index,1);
    },
    odpriPesmi () {
      window.jQuery("#modal-dodaj").modal("toggle")
    },
    odpriSezname () {
      this.pridobiSezname()
      window.jQuery("#modal-dodaj-seznam").modal("toggle")
    },
    naloziSeznam: function() {
      Rest.request("POST", `/seznami/pesmi`, { idSeznama: this.idSeznama, zAvtorjem: true })
        .then(data => {
          this.pesmi = data.pesmi;
          this.imeSeznama = data.imeSeznama;
          this.zasebno = data.zasebno;
          this.arhivirano = data.seznam.arhivirano;
          this.priljubljen = data.priljubljen;
          this.stars = data.idStarsa;
          this.breadcrumbs = data.breadcrumbs
          this.nalozeno = true
          this.avtor = data.avtorSeznama
        })
        .catch(err => {
          console.log(err);
        });

      Rest.request("GET", `/vse-mape`)
        .then(data => {
          this.mape = data
        })
        .catch(err => {
          console.log(err);
        });
    },
    pridobiSkupine: function() {
      let podatki = {
        idSeznama: this.idSeznama
      };
      Rest.request("POST", `/skupine/pridobiSkupine`, podatki)
        .then(data => {
          this.skupine = data
        })
        .catch(err => {
          console.log(err);
        });
    },
    pridobiIzbraneSkupine: function() {
      let podatki = {
        idSeznama: this.idSeznama
      };
      Rest.request("POST", `/skupine/izbraneSkupineSeznama`, podatki)
        .then(data => {
          this.izbraneSkupine = data
        })
        .catch(err => {
          console.log(err);
        });
    },
    posodobiSeznam: function() {
      let podatki = {
        idSeznama: this.idSeznama,
        ime: this.imeSeznama,
        pesmi: [],
        zasebno: this.zasebno,
        arhivirano: this.arhivirano,
        stars: this.stars,
        izbraneSkupine: this.izbraneSkupine
      };
      for (let i = 0; i < this.pesmi.length; i++) {
        podatki.pesmi.push(this.pesmi[i].id)
      }
      Rest.request("POST", `/seznami/posodobi`, podatki)
        .then(data => {
          // this.$router.push('/seznami')
          this.$swal.fire(
            'Uspešno posodobljeno',
            'Pesem uspešno posodobljena',
            'success'
          )
        })
        .catch(err => {
          console.log(err);
        });
    },
    dodajPriljubljenim: function() {
      console.log('priljubljeni')
      let podatki = {
        idSeznama: this.idSeznama,
        priljubljen: !this.priljubljen
      };
      Rest.request("POST", `/seznami/priljubljen`, podatki)
        .then(data => {
          this.priljubljen = !this.priljubljen
        })
        .catch(err => {
          console.log(err);
        });
    }
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.pikice{
  text-overflow: ellipsis !important;
  overflow: hidden;
  white-space: nowrap;
}
.ghost {
  background: #f8f8f8;
}
</style>
