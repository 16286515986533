import Vue from 'vue'
import Router from 'vue-router'

import koren from '@/components/podstrani/Koren.vue'
import naloge from '@/components/podstrani/Naloge.vue'
import prijava from '@/components/podstrani/Prijava.vue'
import nastavitve from '@/components/podstrani/Nastavitve.vue'
import registracija from '@/components/podstrani/Registracija.vue'
import zgodovina from '@/components/podstrani/Zgodovina.vue'
import nalozi from '@/components/podstrani/NaloziPesem.vue'
import naloziLokalno from '@/components/podstrani/DodajLokalnoPesem.vue'
import seznami from '@/components/podstrani/Seznami.vue'
import nalozeniSeznami from '@/components/podstrani/NalozeniSeznami.vue'
import mojiSeznami from '@/components/podstrani/PriljubljeniSeznami.vue'
import pesem from '@/components/podstrani/UrediPesem.vue'
import PesmiNaSeznamu from '@/components/podstrani/PesmiNaSeznamu.vue'
import NalozenePesmiNaSeznamu from '@/components/podstrani/NalozenePesmiNaSeznamu.vue'
import UrediSeznam from '@/components/podstrani/UrediSeznam.vue'
import BulkUrejanjeSeznama from '@/components/podstrani/BulkUrejanjeSeznama.vue'
import UrediMapo from '@/components/podstrani/UrediMapo.vue'
import UrediPriljubljenoMapo from '@/components/podstrani/UrediPriljubljenoMapo.vue'
import iskanje from '@/components/podstrani/Iskanje.vue'
import Statistika from '@/components/podstrani/Statistika.vue'
import StatistikaSlika from '@/components/podstrani/StatistikaSlika.vue'
import NadzornaPlosca from '@/components/podstrani/NadzornaPlosca.vue'
import dodajObvestilo from '@/components/podstrani/DodajObvestilo.vue'
import skupine from '@/components/podstrani/Skupine.vue'
import podcasti from '@/components/podstrani/podcasti/MojiPodcasti.vue'
import dodajPodcast from '@/components/podstrani/podcasti/UstvariPodcast.vue'
import epizode from '@/components/podstrani/podcasti/Epizode.vue'
import zaznamki from '@/components/podstrani/podcasti/Zaznamki.vue'
import Video from '@/components/podstrani/Video.vue'
import Miksi from '@/components/podstrani/Miksi.vue'
import PoglejPesem from '@/components/podstrani/Pesem.vue'
import SkupnoPoslusanje from '@/components/podstrani/SkupnoPoslusanje.vue'

/* eslint-disable */
Vue.use(Router)
export default new Router({
routes: [
    {
        path:'/',
        name: 'koren',
        component: koren
    },{
        path:'/prijava',
        name: 'prijava',
        component: prijava
    },{
        path:'/dodajObvestilo',
        name: 'dodajObvestilo',
        component: dodajObvestilo
    },{
        path:'/nastavitve',
        name: 'nastavitve',
        component: nastavitve
    },{
        path:'/registracija',
        name: 'registracija',
        component: registracija
    },{
        path:'/iskanje',
        name: 'iskanje',
        component: iskanje
    },{
        path:'/nalozi',
        name: 'nalozi',
        component: nalozi
    },{
        path:'/naloziLokalno',
        name: 'naloziLokalno',
        component: naloziLokalno
    },{
        path:'/nalozi/:ytid',
        name: 'nalozi',
        props: true,
        component: nalozi
    },{
        path:'/seznami',
        name: 'seznami',
        component: seznami
    },{
        path:'/zgodovina',
        name: 'zgodovina',
        component: zgodovina
    },{
        path:'/seznami/:mapa',
        name: 'seznami',
        props: true,
        component: seznami
    },{
        path:'/priljubljeniSeznami',
        name: 'moji-seznami',
        component: mojiSeznami
    },{
        path:'/priljubljeniSeznami/:mapa',
        name: 'moji-seznami',
        props: true,
        component: mojiSeznami
    },{
        path:'/urediPriljubeljnoMapo/:idMape',
        name: 'UrediPriljubljenoMapo',
        props: true,
        component: UrediPriljubljenoMapo
    },{
        path:'/pesem/:idPesmi',
        name: 'pesem',
        props: true,
        component: pesem
    },{
        path:'/poglej-pesem/:idPesmi',
        name: 'poglej-pesem',
        props: true,
        component: PoglejPesem
    },{
        path:'/seznam/:idSeznama',
        name: 'seznam',
        props: true,
        component: PesmiNaSeznamu
    },{
        path:'/seznam/:idSeznama/:zac/:kon',
        name: 'seznam',
        props: true,
        component: PesmiNaSeznamu
    },{
        path:'/poddaja/:idPodcasta',
        name: 'podcast',
        props: true,
        component: epizode
    },{
        path:'/poddaja/:idPodcasta/zaznamki',
        name: 'zaznamki',
        props: true,
        component: zaznamki
    },{
        path:'/seznam/:idSeznama/uredi',
        name: 'uredi-seznam',
        props: true,
        component: UrediSeznam
    },{
        path:'/seznam/:idSeznama/uredipesmi',
        name: 'uredi-seznam',
        props: true,
        component: BulkUrejanjeSeznama
    },{
        path:'/mape/:idMape/uredi',
        name: 'uredi-mapo',
        props: true,
        component: UrediMapo
    },{
        path:'/statistika',
        name: 'statistika',
        props: true,
        component: Statistika
    },{
        path:'/statistikaSlik',
        name: 'statistikaSlik',
        props: true,
        component: StatistikaSlika
    },{
        path:'/nadzornaPlosca',
        name: 'NadzornaPlosca',
        props: false,
        component: NadzornaPlosca
    },{
        path:'/skupine',
        name: 'Skupine',
        props: false,
        component: skupine
    },{
        path:'/naloge',
        name: 'Naloge',
        props: false,
        component: naloge
    },{
        path:'/poddaje',
        name: 'Podcasti',
        props: false,
        component: podcasti
    },{
        path:'/dodajPodcast',
        name: 'DodajPodcast',
        props: false,
        component: dodajPodcast
    },{
        path:'/nalozeniSeznami',
        name: 'NalozeniSeznami',
        props: false,
        component: nalozeniSeznami
    },{
        path:'/nalozeniSeznami/:idSeznama',
        name: 'NalozeniSeznam',
        props: true,
        component: NalozenePesmiNaSeznamu
    },{
        path:'/posnetki',
        name: 'Video',
        props: true,
        component: Video
    },{
        path:'/miksi',
        name: 'Miksi',
        props: true,
        component: Miksi
    },{
        path:'/poslusajSkupaj/:idPredvajanja',
        name: 'SkupnoPoslusanje',
        props: true,
        component: SkupnoPoslusanje
    }
],
mode: 'hash',
scrollBehavior (to, from, savedPosition) {
    if (savedPosition) {
        return savedPosition
    } else {
        return { x: 0, y: 0 }
    }
    }
});