<template>
  <div>
    <div class="container">

    <section class="content-header">
      <h1>
        Dodaj poddajo
        <small></small>
      </h1>
      <ol class="breadcrumb">
        <li><a @click="$router.push('/')">Domov</a></li>
        <li><a @click="$router.push('/dodajPodcast')">Dodaj poddajo</a></li>
      </ol>
    </section>
      <br />
      <div class="col-xs-12">
        <div class="box box-primary" v-if="!koncano">
          <div class="box-header with-border">
            <h3 class="box-title">Dodaj poddajo</h3>

            <div class="box-tools pull-right">
              <div class="btn-group" data-toggle="btn-toggle">
                <button type="button" class="btn btn-default btn-sm" :class="[ytPodcast ? '' : 'active']" @click="ytPodcast = false">Dodaj RSS
                </button>
                <button type="button" class="btn btn-default btn-sm" :class="[ytPodcast ? 'active' : '']" @click="ytPodcast = true">Dodaj YT Playlist</button>
              </div>
            </div>
          </div>
          <!-- /.box-header -->
          <!-- form start -->
          <form>
            <div class="box-body">
              <div class="form-group" v-if="!ytPodcast">
                <label>Povezava do RSS dokumenta</label>
                <input v-model="rssLink" type="text" class="form-control" placeholder="https://feeds.simplecast.com/bbt_sv9A">
              </div>
              
              <div class="form-group" v-if="ytPodcast">
                <label>Link do youtube playlista</label>
                <input v-model="playlistLink" type="text" class="form-control" placeholder="https://www.youtube.com/playlist?list=PLR57QuuWlo2CmHZ62hJoSbirKohXEghQ5">
              </div>

              <div class="form-group" v-if="ytPodcast">
                <label>Ime podcasta</label>
                <input v-model="ime" type="text" class="form-control">
              </div>

              <div class="form-group" v-if="ytPodcast">
                <label>Opis podcasta</label>
                <input v-model="opis" type="text" class="form-control">
              </div>

              <div class="form-group" v-if="ytPodcast">
                <label>Povezava do slike podcasta (Najboljše so kvadratne slike)</label>
                <input v-model="slikaUrl" type="text" class="form-control">
              </div>
              <img v-if="ytPodcast" :src="slikaUrl" class="img-thumbnail" style="height: 150px" onerror="this.src='https://external-content.duckduckgo.com/iu/?u=https%3A%2F%2Fimages.techhive.com%2Fimages%2Farticle%2F2016%2F04%2Ferror-thinkstock-100655502-primary.idge.jpg&f=1&nofb=1'" />

              <div class="form-group">
                <div class="checkbox">
                  <label>
                    <input type="checkbox" v-model="zasebno">
                    <i class="fa fa-eye-slash" v-if="zasebno"></i>
                    <i class="fa fa-eye" v-else></i>
                    Zasebno
                  </label>
                </div>
              </div>

            </div>
            <!-- /.box-body -->

            <div class="box-footer">
              <button @click.prevent="dodajPesem()" class="btn btn-primary">Potrdi</button>
            </div>
          </form>
        </div>
        <div class="box box-primary" v-else>
          <div class="box-header with-border">
            <h3 class="box-title">Dodaj podcast</h3>
          </div>
          <div class="box-body">
            <h2>Poteka dodajanje poddaje. Prosimo počakajte.</h2>
            <div class="lds-ellipsis"><div></div><div></div><div></div><div></div></div>
            <p>To lahko traja nekaj minut.</p>
          </div>
          <!-- /.box-body -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Rest from './../../../storitve/rest'

export default {
  components: {
  },
  name: 'koren',
  props: ['ytid'],
  data () {
    return {
      ytPodcast: false,
      rssLink: "",
      zasebno: false,
      playlistLink: "",
      ime: "",
      slikaUrl: "",
      opis: "",
      koncano: false
    }
  },
  created: function () {
  },
  computed: {
  },
  methods: {
    ponastavi: function() {
      this.ytPodcast = false
      this.rssLink = ""
      this.zasebno = false
      this.playlistLink = ""
      this.ime = ""
      this.slikaUrl = ""
      this.opis = ""
      this.koncano = false
    },
    dodajPesem: function() {
      if (this.rssLink) {
        let podatki = {
          rssLink: this.rssLink,
          zasebno: this.zasebno
        }
        this.koncano = true;
        Rest.request('POST', (`/podcasti/dodajRss`), podatki).then((data) => {
          this.koncano = false;
          this.ponastavi()
        }).catch((err) => {
          console.log(err)
        })
      } else {
        let podatki = {
          playlistLink: this.playlistLink,
          zasebno: this.zasebno,
          ime: this.ime,
          slikaUrl: this.slikaUrl,
          opis: this.opis
        }
        this.koncano = true;
        Rest.request('POST', (`/podcasti/dodajYt`), podatki).then((data) => {
          this.koncano = false;
          this.ponastavi()
        }).catch((err) => {
          console.log(err)
        })
      }
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.lds-ellipsis {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}
.lds-ellipsis div {
  position: absolute;
  top: 33px;
  width: 13px;
  height: 13px;
  border-radius: 50%;
  background: rgb(122, 120, 120);
  animation-timing-function: cubic-bezier(0, 1, 1, 0);
}
.lds-ellipsis div:nth-child(1) {
  left: 8px;
  animation: lds-ellipsis1 0.6s infinite;
}
.lds-ellipsis div:nth-child(2) {
  left: 8px;
  animation: lds-ellipsis2 0.6s infinite;
}
.lds-ellipsis div:nth-child(3) {
  left: 32px;
  animation: lds-ellipsis2 0.6s infinite;
}
.lds-ellipsis div:nth-child(4) {
  left: 56px;
  animation: lds-ellipsis3 0.6s infinite;
}
@keyframes lds-ellipsis1 {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}
@keyframes lds-ellipsis3 {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(0);
  }
}
@keyframes lds-ellipsis2 {
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(24px, 0);
  }
}
</style>
