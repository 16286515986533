<template>
  <div>
    <div class="container">
    <section class="content-header">
      <h1>
        Nastavitve
        <small></small>
      </h1>
      <ol class="breadcrumb">
        <li><a @click="$router.push('/')">Domov</a></li>
        <li><a @click="$router.push('/nastavitve')">Nastavitve</a></li>
      </ol>
    </section>
      <br />
      <div class="col-xs-12">
        <div class="box box-primary">
          <div class="box-header with-border">
            <h3 class="box-title">Nastavitve</h3>
          </div>
          <!-- /.box-header -->
          <div class="box-body">
            <form>
              <!-- checkbox -->
              <div class="form-group">
                <div class="checkbox">
                  <label>
                    <input type="checkbox" v-model="tihiGumbi">
                    Gumbi za super tiho predvajanje
                  </label>
                </div>
                
                <div class="checkbox">
                  <label>
                    <input type="checkbox" v-model="zgodovina">
                    Shranjuj zgodovino poslušanih pesmi
                  </label>
                </div>
                
                <div class="checkbox">
                  <label>
                    <input type="checkbox" v-model="darkMode">
                    Uporabi temno temo
                  </label>
                </div>
              </div>
            </form>

            <button class="btn btn-default form-control" @click="nacinBrezPovezave()">Prižgi način brez povezave</button>
            <button v-if="!jeCordova()" class="btn btn-default form-control" @click="naloziAplikacijo()" style="margin-top: 1ex">Naloži aplikacijo</button>
            <p v-if="nalozenePesmi.length > 0">
              {{nalozenePesmi.length}} naloženih pesmi
            </p>
            <p>Verzija: {{verzija}}</p>
          </div>
          <!-- /.box-body -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Rest from './../../storitve/rest'
import Vue from 'vue'
import { EventBus } from './../../storitve/event-bus'
import Pesem from './../skupno/Pesem.vue'
import nalaganje from './../../storitve/nalaganje-pesmi'
import verzija from './../../storitve/verzija'
// import ApkUpdater from 'cordova-plugin-apkupdater'

export default {
  components: {
  },
  name: 'nastavitve',
  props: [],
  data () {
    return {
      tihiGumbi: false,
      zgodovina: true,
      darkMode: false,
      nalozenePesmi: [],
      verzija: ""
    }
  },
  watch: {
    tihiGumbi: function(star, nov) {
      localStorage.setItem('tihi-gumbi', this.tihiGumbi)
      this.$emit('nastavitve')
    },
    zgodovina: function(star, nov) {
      localStorage.setItem('zgodovina', this.zgodovina)
      this.$emit('nastavitve')
    },
    darkMode: function(star, nov) {
      localStorage.setItem('dark-mode', this.darkMode)
      this.$emit('nastavitve')
    }
  },
  created: function () {
    console.log('------------------------------')
    this.verzija = verzija.verzija
    console.log(localStorage.getItem('tihi-gumbi'))
    if (localStorage.getItem('tihi-gumbi')) {
      this.tihiGumbi = (localStorage.getItem('tihi-gumbi') === 'true')
    }
    if (localStorage.getItem('zgodovina')) {
      this.zgodovina = (localStorage.getItem('zgodovina') === 'true')
    }
    if (localStorage.getItem('dark-mode')) {
      this.darkMode = (localStorage.getItem('dark-mode') === 'true')
    }
    let th = this
    nalaganje.nalozenePesmi().then((pesmi) => {
      th.nalozenePesmi = pesmi
    })
  },
  methods: {
    naloziAplikacijo() {
      window.open('/downloads/glasba.apk', "_blank");
    },
    nacinBrezPovezave() {
      Vue.prototype.$brezPovezave = true
      EventBus.$emit('posodobiBrezPovezave');
    },
    jeCordova () {
      return nalaganje.jeCordova()
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
