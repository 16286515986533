<template>
  <div :style="'background-image: linear-gradient(' + ozadje + ', transparent 500px);'">
    <div :class="'container ' + tema">
    <section class="content-header">
      <div class="row" style="vertical-align: bottom" v-if="seznam && seznam.id">
        <div class="col-md-3 col-xs-12" style="text-align: center">
          <div class="thumbnail" style="margin: auto; max-width: 300px">
            <div>
              <img ref="slika" class="" :style="'width:100%;'" :src ="nalaganje.pretvoriLokalniUrl('/api/v1/slikeSeznamov/' + seznam.id)" />
            </div>
          </div>
        </div>
        <div class="col-md-9 col-xs-12 hidden-xs hidden-sm">
          <div style="height: 98px"></div>
        </div>
        <div class="col-md-9 col-xs-12">
          <h1>
            {{imeSeznama}}
              
            <small></small>
          </h1>
        </div>
        <div class="col-md-9 col-xs-12">
          <div class="">
            <button type="button" class="btn btn-default" @click.prevent.stop="$router.push('/seznam/'+idSeznama+'/uredi')" style="margin-right: 1ex">
              <i class="fa fa-cog"></i> Uredi seznam</button>
            <button type="button" class="btn btn-default" @click.prevent.stop="$router.push('/seznam/'+idSeznama+'/uredipesmi')" style="margin-right: 1ex">
              <i class="fa fa-cogs"></i> Uredi pesmi na seznamu</button>
            <button v-if="nalaganje.jeCordova()" type="button" class="btn btn-default" data-toggle="modal" data-target="#modal-nalaganje" style="">
              <i class="fa fa-download"></i> Naloži seznam
            </button>
            <button @click.prevent="dodajPriljubljenim()" class="btn btn-default">
              <i class="fa fa-heart" v-if="priljubljen"></i>
              <i class="fa fa-heart-o" v-else></i>
            </button>
          </div>
        </div>
        
        <div class="col-md-9 col-xs-12" v-if="kazalo.length > 0" style="margin-top: 1ex">
          <div class="" style="margin-left: -15px;">
            <div class="col-xs-4">
              <button type="button" class="btn btn-default form-control" @click.prevent.stop="predvajajNakljucno()" style="margin-right: 1ex;">
                <i class="fa fa-random"></i> Predvajaj</button>
            </div>
            <div class="input-group col-xs-8">
              <input type="text" autocomplete="off" name="q" class="form-control" placeholder="Iskanje..." v-model="iskalnoGeslo" @change="poisci()">
              <span class="input-group-btn">
                <button type="submit" name="search" id="search-btn" class="btn btn-flat" @click.prevent="pridobiPesmi()"><i class="fa fa-search"></i>
                </button>
              </span>
            </div>
          </div>
        </div>
        
        <div class="col-md-9 col-xs-12" v-if="zacetek >= 0 && konec >= 0" style="margin-top: 1ex">
          <div class="row">
            <div class="col-xs-4">
              <button type="button" class="btn btn-default form-control" @click.prevent.stop="nazajNaKazalo()" style="margin-right: 1ex;">
                <i class="fa fa-chevron-left"></i> Nazaj</button>
            </div>
          </div>
        </div>
      </div>
      <ol class="breadcrumb" style="margin-top: 1em">
        <li><a @click="$router.push('/')">Domov</a></li>
        <li v-for="drobtina in breadcrumbs" :key="drobtina.id"><a @click="$router.push(drobtina.naslov)">{{drobtina.ime}}</a></li>
      </ol>
    </section>
      <br />
      <div v-if="prikaziPesmi">
        <div v-if="zacetek > 0" class="col-xs-12" style="margin-bottom: 1em">
          <button class="btn btn-default form-control" @click.prevent="infiniteHandler(false)" :disabled="vProcesu">Naloži več (še {{zacetek}})</button>
        </div>


        <!-- <div class="col-xs-12" @click="predvajajPesem(index)" v-for="(pesem, index) in pesmi" :key="index">
          <Pesem :naslov="pesem['ime-pesmi']" :avtor="pesem.avtor" :barva="vsebujeIskanoPesem(index + zacetek, index + zacetek + 1) ? 'red' : 'yellow'" ikona="fa-music" :album="pesem.album" :nalozena="pesem.nalozena">
            <div class="pull-right" @click.stop="">
              <div class="btn-group">
                <button type="button" class="btn btn-default" @click.prevent.stop="$router.push('/pesem/'+pesem.id)">
                  <i class="fa fa-cogs"></i>
                </button>
                <button type="button" class="btn btn-default" @click.prevent.stop="predvajajNaslednjo(index)">
                  <i class="fa fa-mail-forward"></i>
                </button>
              </div>
            </div>
          </Pesem>
        </div> -->

        <div class="col-xs-12">
          <div class="box">
            <div class="box-body table-responsive no-padding" ref="tabelaPesmi">
              <table class="col-xs-12 table table-hover">
                <tbody>
                  <tr>
                  <th>#</th>
                  <th v-if="prikaziDodatneStolpce"></th>
                  <th>Naslov</th>
                  <th v-if="prikaziDodatneStolpce">Album</th>
                  <th></th>
                  </tr>
                  <tr @click="predvajajPesem(index)" v-for="(pesem, index) in pesmi" :key="index" :class="vsebujeIskanoPesem(index + zacetek, index + zacetek + 1) ? 'bg-orange' : ''">
                    <td style="font-size: 11pt; padding-top: 23px">{{index + 1 + Math.max(0, zacetek)}}</td>
                    <td v-if="prikaziDodatneStolpce"><img :src="urlSlike(pesem)" alt="" style="margin-top: 2px" width="50" height="50"></td>
                    <td :style="'min-width: '+(sirinaTabelePopravljeno * 0.7)+'px; max-width: '+(sirinaTabelePopravljeno * 0.7)+'px;'">
                      <p style="margin: 0px">
                      <span style="max-width: 100%; display: inline-block; text-overflow: ellipsis; overflow: hidden; white-space: nowrap; font-weight: bold; font-size: 13pt">{{pesem['ime-pesmi']}}</span>
                      <br/>
                      <span style="max-width: 100%; display: inline-block; text-overflow: ellipsis; overflow: hidden; white-space: nowrap;">{{pesem.avtor}}</span>
                      </p>
                    </td>
                    <td v-if="prikaziDodatneStolpce" :style="'padding-top: 23px; min-width: '+(sirinaTabelePopravljeno * 0.3)+'px; max-width: '+(sirinaTabelePopravljeno * 0.3)+'px;text-overflow: ellipsis; overflow: hidden; white-space: nowrap;'">{{pesem.album}}</td>
                    <td :style="'padding-top: 18px; min-width: 100px; max-width: 100px;text-overflow: ellipsis; overflow: hidden; white-space: nowrap;'">
                      <div class="pull-right" @click.stop="">
                        <div class="btn-group">
                          <button type="button" class="btn btn-default" @click.prevent.stop="$router.push('/pesem/'+pesem.id)">
                            <i class="fa fa-cogs"></i>
                          </button>
                          <button type="button" class="btn btn-default" @click.prevent.stop="predvajajNaslednjo(index)">
                            <i class="fa fa-mail-forward"></i>
                          </button>
                          <!-- <button type="button" class="btn btn-default" @click.prevent.stop="naloziPesem(index)">
                            <i class="fa fa-download"></i>
                          </button> -->
                        </div>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
        
        <div v-for="blok in kazalo" :key="blok.zacetek">
          <div class="col-xs-12" @click="opis(blok.zacetek, blok.konec)">
            <Pesem :naslov="(blok.zacetek + 1) + '-' + blok.konec" 
            :avtor="blok.opis" :barva="vsebujeIskanoPesem(blok.zacetek, blok.konec) ? 'red' : 'orange'" ikona="fa-music" :album="'Pesmi'"></Pesem>
          </div>
        </div>

        <div v-if="konec > 0 && konec < dolzina" class="col-xs-12" style="z-inex: -1">
          <button class="btn btn-default form-control" @click.prevent="infiniteHandler(true)" :disabled="vProcesu">Naloži več (še {{dolzina - konec}})</button>
        </div>

      </div>
    </div>

    <div class="modal fade" id="modal-nalaganje">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
            <h4 class="modal-title">Upravljaj nalaganja</h4>
          </div>
          <div class="modal-body">
            <div class="form-group">
              <div v-if="nalaganjeAktivno">
                Nalaganje se je začelo!
              </div>
              <div v-else>
                <button type="button" class="btn btn-default form-control" @click.prevent.stop="naloziSeznam()" style="">
                  <i class="fa fa-download"></i>
                  Naloži seznam
                </button>
                <button type="button" class="btn btn-default form-control" @click="izbrisiNalozeniSeznam()" data-dismiss="modal" style="margin-top: 1ex;">
                  <i class="fa fa-trash"></i>
                  Izbriši naložene pesmi s seznama
                </button>
              </div>
              
              <!-- <button type="button" class="btn btn-default form-control" @click="vsePesmiNaNalozenihSeznamih()" data-dismiss="modal" style="margin-top: 1ex;">
                <i class="fa fa-trash"></i>
                PReveri
              </button> -->
            </div>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-default pull-left" data-dismiss="modal">Zapri</button>
          </div>
        </div>
        <!-- /.modal-content -->
      </div>
      <!-- /.modal-dialog -->
    </div>

  </div>
</template>

<script>
import Rest from './../../storitve/rest'
import Vue from 'vue'
import { EventBus } from './../../storitve/event-bus'
import Pesem from './../skupno/Pesem.vue'
import nalaganje from './../../storitve/nalaganje-pesmi'
// import InfiniteLoading from 'vue-infinite-loading';

export default {
  components: {
    Pesem
  },
  name: 'seznam-predvajanja',
  props: ['idSeznama', 'zac', 'kon'],
  data () {
    return {
      pesmi: [],
      kazalo: [],
      imeSeznama: "",
      seznam: {},
      odprtaAplikacija: false,
      intervalid1: null,
      stPreverjenj: 0,
      breadcrumbs: [],
      nalaganje,
      posodobi: false,
      prikaziPesmi: true,
      nalaganjeAktivno: false,
      zacetek: -1,
      konec: -1,
      iskalnoGeslo: "",
      najdeno: [],
      dolzina: 0,
      vProcesu: false,
      sirinaTabele: 0,
      sirinaTabelePopravljeno: 0,
      prikaziDodatneStolpce: 0,
      priljubljen: false,
      ozadje: '#ffffff',
      tema: 'light'
    }
  },
  created: function () {
    if (this.zac) {
      this.zacetek = parseInt(this.zac)
    }
    
    if (this.kon) {
      this.konec = parseInt(this.kon)
    }
    this.pridobiPesmi();
    let th = this
    this.$nextTick(function () {
      th.intervalid1 = setInterval(() => {
        if (th.stPreverjenj > 0) {
          th.stPreverjenj--
          th.pridobiNalozenePesmi()
        }
      },5000);
    })
  },
  mounted () {
    // document.addEventListener("backbutton", this.backButtonFunction, false);
    this.nastaviVisino()
    window.addEventListener("resize", this.resizeHandeler);
  },
  beforeDestroy () {
    clearInterval(this.intervalid1)
    // document.removeEventListener("backbutton", this.backButtonFunction);
    window.removeEventListener("resize", this.resizeHandeler);
  },
  watch: {
    zac: function (staro, novo) {
      if (this.zac) {
        this.zacetek = parseInt(this.zac)
      } else {
        this.zacetek = -1
      }
      
      if (this.kon) {
        this.konec = parseInt(this.kon)
      } else {
        this.konec = -1
      }
      this.pridobiPesmi()
    }
  },
  methods: {
    urlSlike(pesem) {
      return "/api/v1/slikeSeznamov/pesem/" + pesem.id;
      if (pesem.ytId) {
        return "https://i.ytimg.com/vi/" + pesem.ytId + "/hqdefault.jpg";
      } else {
        return this.nalaganje.pretvoriLokalniUrl('/api/v1/slikeSeznamov/' + this.seznam.id);
      }
    },
    resizeHandeler (e) {
      this.nastaviVisino()
    },
    nastaviVisino () {
      this.sirinaTabele = this.$refs.tabelaPesmi.clientWidth;
      if (this.sirinaTabele > 768 - 30) {
        this.prikaziDodatneStolpce = true
        this.sirinaTabelePopravljeno = this.sirinaTabele - 110 - 100;
      } else {
        this.prikaziDodatneStolpce = false
        this.sirinaTabelePopravljeno = this.sirinaTabele - 50 - 100;
      }
    },
    infiniteHandler(konec) {
      if ((!konec && this.zacetek > 0) || (konec && this.konec < this.dolzina)) {
        let visinaPrej = document.body.scrollHeight
        let th = this
        if (konec) {
          let konec = Math.min(this.konec + 50, this.dolzina)
          this.vProcesu = true
          nalaganje.pridobiSeznamPaginirano(this.idSeznama, {zacetek: this.konec, konec: konec, kazaloPodprto: true})
          .then((data) => {
            if (data.velikost) {
              this.dolzina = data.velikost
            }
            th.konec = konec
            th.pesmi.push(...data.pesmi)
            this.vProcesu = false
          }).catch((err) => {
            this.vProcesu = false
          })
        } else {
          let zac = Math.max(0, this.zacetek - 50)
          this.vProcesu = true
          nalaganje.pridobiSeznamPaginirano(this.idSeznama, {zacetek: zac, konec: this.zacetek, kazaloPodprto: true})
          .then((data) => {
            if (data.velikost) {
              this.dolzina = data.velikost
            }
            th.zacetek = zac
            th.pesmi.unshift(...data.pesmi)
            this.vProcesu = false
            this.$nextTick(function () {
              window.scrollTo(0, window.scrollY + (document.body.scrollHeight - visinaPrej));
            })
            // document.body.scrollTop = document.body.scrollTop + (document.body.scrollHeight - visinaPrej)
          }).catch((err) => {
            this.vProcesu = false
          })
        }
      } else {
      }
    },
    nazajNaKazalo () {
      this.zacetek = -1
      this.konec = -1
      this.pridobiPesmi()
    },
    backButtonFunction () {
      if (this.zacetek >= 0 && this.konec >= 0) {
        this.zacetek = -1
        this.konec = -1
        this.pridobiPesmi()
      }
    },
    vsebujeIskanoPesem (zac, kon) {
      for (let i = 0; i < this.najdeno.length; i++) {
        if (zac <= this.najdeno[i] && kon > this.najdeno[i]) {
          return true
        }
      }
      return false
    },
    poisci () {
      Rest.request('POST', `/seznami/iskanjePoSeznamu`, {idSeznama: this.idSeznama, geslo: this.iskalnoGeslo}).then((data) => {
        this.najdeno = data.rezultati
      }).catch((err) => {
        console.log(err)
      })
    },
    predvajajNakljucno () {
      nalaganje.pridobiSeznamPaginirano(this.idSeznama, {'limit': 100, 'random': true}).then((data) => {
        let izbrana = 0
        this.$emit('predvajaj', [data.pesmi, izbrana]);
      }).catch((err) => {
      })
    },
    opis(zacetek, konec) {
      this.$router.push('/seznam/' + this.idSeznama + '/' + zacetek + '/' + konec)
      // this.zacetek = zacetek
      // this.konec = konec
      // this.pridobiPesmi()
    },
    posodobiPesmi() {
      this.prikaziPesmi = false
      this.$nextTick(function () {
        this.prikaziPesmi = true
      })
    },
    pridobiNalozenePesmi() {
      if (nalaganje.jeCordova()) {
        let th = this
        nalaganje.obdelajSeznam({pesmi: th.pesmi}).then(seznam => {
          th.pesmi = seznam.pesmi
          th.posodobiPesmi()
        })
      }
    },
    naloziPesem(index) {
      console.log(this.dostopniZeton())
      let postdata = this.pesmi[index];
      postdata.token = this.dostopniZeton();
      Rest.request('POST', `http://localhost:7777/api/v1/naloziPesmi/nalozi`, postdata).then((data) => {
        this.$swal.fire({
          position: 'top',
          title: 'Pesmi bodo kmalu naložene',
          icon: 'success',
          showConfirmButton: false,
          timerProgressBar: true,
          backdrop: null,
          timer: 1000
        })
      }).catch((err) => {
        console.log(err)
      })
      // window.open('/prenosPesmi/'+this.pesmi[index].id+'?token='+this.dostopniZeton(), "_blank");
      // var cacheName = 'sw-precache-v3-sw-precache-' + (self.registration ? self.registration.scope : '');
      // console.log(cacheName)
      // let th = this
      // caches.open('sw-precache-v3-sw-precache-http://localhost:8080/').then(function(cache) {
      //   cache.add('/glasba/'+th.pesmi[index].id+'?token='+th.dostopniZeton());
      // });
    },
    dostopniZeton() {
      return localStorage.getItem("sessionAccessToken");
    },
    izbrisiNalozeniSeznam () {
      this.$swal.fire({
        title: 'Sigurno?',
        text: "",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Da',
        cancelButtonText: 'Ne'
      }).then((result) => {
        nalaganje.odstraniSeznam(this.idSeznama)
        this.stPreverjenj = 5
      })
    },
    vsePesmiNaNalozenihSeznamih () {
      nalaganje.vsePesmiNaNalozenihSeznamih()
    },
    naloziSeznam () {
      this.stPreverjenj = 100
      this.nalaganjeAktivno = true
      nalaganje.naloziSeznam(this.idSeznama)
    },
    predvajajNaslednjo (index) {
      this.$emit('predvajajNaslednjo', this.pesmi[index]);
      this.$swal.fire({
        position: 'top',
        title: 'Pesem dodana v vrsto za predvajanje',
        icon: 'success',
        showConfirmButton: false,
        timerProgressBar: true,
        backdrop: null,
        timer: 1000
      })
    },
    pridobiPesmi: function() {
      return nalaganje.pridobiSeznamPaginirano(this.idSeznama, {zacetek: this.zacetek, konec: this.konec, kazaloPodprto: true}).then((data) => {
        console.log(data)
        if (data.pesmi) {
          this.pesmi = data.pesmi
        } else {
          this.pesmi = []
        }
        if (data.kazalo) {
          this.kazalo = data.kazalo
        } else {
          this.kazalo = []
        }
        if (data.velikost) {
          this.dolzina = data.velikost
        }
        if (data.barvaOzadja) {
          this.ozadje = data.barvaOzadja
          this.tema = this.lightOrDark(this.ozadje)
        }
        this.imeSeznama = data.imeSeznama
        this.seznam = data.seznam
        this.breadcrumbs = data.breadcrumbs
        this.priljubljen = data.priljubljen
      }).catch((err) => {
        console.log(err)
      })
    },
    predvajajPesem: function(index) {
      this.$emit('predvajaj', [this.pesmi, index]);
    },
    click: function () {
      console.log('klik dela');
    },
    dodajPriljubljenim: function() {
      let podatki = {
        idSeznama: this.idSeznama,
        priljubljen: !this.priljubljen
      };
      Rest.request("POST", `/seznami/priljubljen`, podatki)
        .then(data => {
          this.priljubljen = !this.priljubljen
        })
        .catch(err => {
          console.log(err);
        });
    },
    lightOrDark(color) {
      // Check the format of the color, HEX or RGB?
      console.log(color)
      let r = 0;
      let g = 0;
      let b = 0;
      if (color.match(/^rgb/)) {

        // If HEX --> store the red, green, blue values in separate variables
        color = color.match(/^rgba?\((\d+),\s*(\d+),\s*(\d+)(?:,\s*(\d+(?:\.\d+)?))?\)$/);

        r = color[1];
        g = color[2];
        b = color[3];
      } 
      else {

        // If RGB --> Convert it to HEX: http://gist.github.com/983661
        color = +("0x" + color.slice(1).replace( 
          color.length < 5 && /./g, '$&$&'
        )
                );

        r = color >> 16;
        g = color >> 8 & 255;
        b = color & 255;
      }

      // HSP equation from http://alienryderflex.com/hsp.html
      let hsp = Math.sqrt(
        0.299 * (r * r) +
        0.587 * (g * g) +
        0.114 * (b * b)
      );

      // Using the HSP value, determine whether the color is light or dark
      if (hsp>127.5) {
        return 'light';
      } 
      else {
        return 'dark';
      }
      }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.bottomdiv { 
    position:absolute; 
    left:0; 
    bottom:0; 
} 
@media (min-width: 991px) {
  .light h1 {
    color: #333 !important;
  }
  .light a {
    color: #333 !important;
  }
  .light breadcrumb {
    background-color: #d3d3d3 !important;
  }
  .light .breadcrumb>li+li:before {
    color: #333;
  }
  .dark h1 {
    color: #d3d3d3 !important;
  }
  .dark a {
    color: #d3d3d3 !important;
  }
  .light breadcrumb {
    background-color: #333 !important;
  }
  .dark .breadcrumb>li+li:before {
    color: #ccc;
  }
}
</style>
