<template>
  <div>
    <div class="container">
      <section class="content-header">
        <h1>
          Statistika
          <small></small>
        </h1>
        <ol class="breadcrumb">
          <li><a @click="$router.push('/')">Domov</a></li>
          <li><a @click="$router.push('/statistika')">Statistika</a></li>
        </ol>
      </section>
      <br/>
      <div class="col-md-12">
        <div class="box">
          <div class="box-header">
            <h3 class="box-title">Zgodovina</h3>
          </div>
          <!-- /.box-header -->
          <div class="box-body ">
            <table class="table table-bordered">
              <tr>
                <th>Dan</th>
                <th>Število pesmi</th>
              </tr>
              <tr v-for="(z, ind) in zgodovina" :key="ind">
                <td>{{z.dan}}</td>
                <td>{{z.stPoslusanihPesmi}}</td>
              </tr>
            </table>
          </div>
          <!-- /.box-body -->
        </div>
      <!-- /.box -->
      </div>
    </div>
  </div>
</template>

<script>
import Rest from './../../storitve/rest'

export default {
  components: {
  },
  name: 'koren',
  props: {
  },
  data () {
    return {
      zgodovina: []
    }
  },
  created: function () {
    this.pridobiZgodovino()
  },
  methods: {
    pridobiZgodovino: function() {
      Rest.request('GET', `/statistika`).then((data) => {
        this.zgodovina = data.zgodovina
      }).catch((err) => {
        console.log(err)
      })
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
