<template>
  <div>
    <div class="container">
      <section class="content-header">
        <h1>
          Zgodovina
          <small data-toggle="modal" data-target="#modal-casi" @click="casiPoslusanja()">Čas poslušanja</small>
          <small @click="$router.push('/statistikaSlik')">Statistika</small>
        </h1>
        <ol class="breadcrumb">
          <li><a @click="$router.push('/')">Domov</a></li>
          <li><a @click="$router.push('/zgodovina')">Zgodovina</a></li>
        </ol>
      </section>
      <br />
      <div v-for="(pesem, index) in pesmi" :key="pesem.id">
        <div class="col-xs-12" @click="predvajajPesem(index)">
          <Pesem :naslov="pesem['ime-pesmi']" :avtor="pesem.avtor" barva="yellow" ikona="fa-music" :album="pesem.album">
            <div class="pull-right" @click.stop="">
              <div class="btn-group">
                <button type="button" class="btn btn-default" @click.prevent.stop="$router.push('/pesem/'+pesem.id)">
                  <i class="fa fa-cogs"></i>
                </button>
                <button type="button" class="btn btn-default" @click.prevent.stop="predvajajNaslednjo(index)">
                  <i class="fa fa-mail-forward"></i>
                </button>
              </div>
            </div>
          </Pesem>
        </div>
      </div>
      <div v-if="pesmi.length === 0" class="col-xs-12">
        V zgodovini nimaš še nobene pesmi.
      </div>
    </div>

    <div class="modal fade" id="modal-casi">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span></button>
            <h4 class="modal-title">Zgodovina časa poslušanja</h4>
          </div>
          <div class="modal-body">
            <div class="table-responsive" style="margin-bottom: 0px">
              <table class="table table-striped" style="margin-bottom: 0px">
                <tbody>
                <tr>
                  <th>Datum</th>
                  <th>Pesmi</th>
                  <th>Poddaje</th>
                </tr>
                <tr v-for="(dan, ind) in zgodovinaCasov" :key="ind">
                  <td>{{dan.datum}}</td>
                  <td><span>{{Math.floor(dan.casSekunde / 3600)}}:{{pad(Math.floor(dan.casSekunde / 60) % 60, 2)}}:{{pad(dan.casSekunde % 60, 2)}}</span></td>
                  <td><span>{{Math.floor(dan.casSekundePodcasti / 3600)}}:{{pad(Math.floor(dan.casSekundePodcasti / 60) % 60, 2)}}:{{pad(dan.casSekundePodcasti % 60, 2)}}</span></td>
                </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-default pull-right" data-dismiss="modal">Zapri</button>
          </div>
        </div>
        <!-- /.modal-content -->
      </div>
      <!-- /.modal-dialog -->
    </div>
  </div>
</template>

<script>
import Rest from './../../storitve/rest'
import Vue from 'vue'
import { EventBus } from './../../storitve/event-bus'
import Pesem from './../skupno/Pesem.vue'

export default {
  components: {
    Pesem
  },
  name: 'zgodovina',
  props: {
  },
  data () {
    return {
      pesmi: [],
      seznami: [],
      idOdprtePesmi: "",
      zgodovinaCasov: []
    }
  },
  created: function () {
    this.pridobiPesmi();
  },
  methods: {
    predvajajNaslednjo (index) {
      this.$emit('predvajajNaslednjo', this.pesmi[index]);
      this.$swal.fire({
        position: 'top',
        title: 'Pesem dodana v vrsto za predvajanje',
        icon: 'success',
        showConfirmButton: false,
        timerProgressBar: true,
        backdrop: null,
        timer: 1000
      })
    },
    pad(num, size) {
      var s = num+"";
      while (s.length < size) s = "0" + s;
      return s;
    },
    casiPoslusanja () {
      Rest.request('GET', `/statistika/casi`).then((data) => {
        this.zgodovinaCasov = data.casi
      }).catch((err) => {
        console.log(err)
      })
    },
    pridobiPesmi: function() {
      Rest.request('GET', `/zgodovina/pesmi`).then((data) => {
        this.pesmi = data
      }).catch((err) => {
        console.log(err)
      })
    },
    predvajajPesem: function(index) {
      this.$emit('predvajaj', [this.pesmi, index]);
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>
