<template>
  <div>
    <div class="container">
      <section class="content-header">
        <h1>
          Statistika
          <small></small>
        </h1>
        <ol class="breadcrumb">
          <li><a @click="$router.push('/')">Domov</a></li>
          <li><a @click="$router.push('/statistikaSlika')">Statistika</a></li>
        </ol>
      </section>
      <br/>
      <div class="col-md-12">
        <div class="box">
          <div class="box-header">
            <h3 class="box-title">Statistika</h3>
          </div>
          <!-- /.box-header -->
          <div class="box-body ">
            <div class="form-group">
              <label for="exampleInputEmail1">Leto</label>
              <select class="form-control" v-model="leto">
                <option>2020</option>
                <option>2021</option>
                <option>2022</option>
                <option>2023</option>
                <option>2024</option>
              </select>
            </div>
            <div style="text-align: center">
              <img v-if="uporabnik" :src="nalaganje.pretvoriLokalniUrl('/api/v1/statistika/' + uporabnik + '/leto/' + leto)" style="width: 100%; max-width: 400px">
            </div>
          </div>
          <!-- /.box-body -->
        </div>
      <!-- /.box -->
      </div>
    </div>
  </div>
</template>

<script>
import Rest from './../../storitve/rest'
import nalaganje from './../../storitve/nalaganje-pesmi'

export default {
  components: {
  },
  name: 'koren',
  props: {
  },
  data () {
    return {
      zgodovina: [],
      leto: 2024,
      uporabnik: "",
      nalaganje: nalaganje
    }
  },
  created: function () {
    this.pridobiUporabnika()
  },
  methods: {
    pridobiUporabnika () {
      Rest.request('GET', `/uporabniki/jaz`).then((data) => {
        this.uporabnik = data.id
      }).catch((err) => {
        console.log(err)
      })
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
