<template>
  <div>
    <div class="container">
    <section class="content-header">
      <h1>
        Naloženi seznami predvajanja
        <!-- <button type="button" class="btn btn-default" @click.prevent.stop="$router.push('/mape/'+mapa+'/uredi')" v-if="mapa">
            <i class="fa fa-cogs"></i></button> -->
        <small></small>
      </h1>
      <ol class="breadcrumb">
        <li><a @click="$router.push('/')">Domov</a></li>
        <li><a @click="$router.push('/nalozeniSeznami')">Seznami predvajanja</a></li>
      </ol>
    </section>
      <br />
      
      <div v-for="(seznam, index) in seznami" :key="index">
        <div class="col-xs-12" @click="$router.push('/seznam/' + seznam.id)">
          <Pesem :naslov="seznam.ime" avtor=" " barva="orange" ikona="fa-reorder" album="Seznam predvajanja" :zasebno="seznam.zasebno" :vSkupini="seznam.vSkupini">
            <div class="pull-right">
            </div>
          </Pesem>
        </div>
      </div>
    </div>
    
  </div>
</template>

<script>
import Rest from './../../storitve/rest'
import Vue from 'vue'
import { EventBus } from './../../storitve/event-bus'
import Pesem from './../skupno/Pesem.vue'
import nalaganje from './../../storitve/nalaganje-pesmi'


export default {
  components: {
    Pesem
  },
  name: 'seznami',
  props: ['mapa'],
  data () {
    return {
      seznami: [],
      mape: [],
      imeSeznama: "",
      zasebno: false
    }
  },
  watch: {
    mapa: function (staro, novo) {
      this.pridobiSezname();
    }
  },
  created: function () {
    this.pridobiSezname();
  },
  methods: {
    
    pridobiSezname: function() {
      let podatki = {
        mapa: this.mapa
      }
      this.seznami = nalaganje.nalozeniSeznami()
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
