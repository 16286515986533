<template>
  <div>
    <div class="container">
    <section class="content-header">
      <h1>
        Zaznamki: {{podcast.ime}}
        <small></small>
      </h1>
      <ol class="breadcrumb" style="">
        <li><a @click="$router.push('/')">Domov</a></li>
        <li><a @click="$router.push('/poddaje')">Poddaje</a></li>
        <li><a @click="$router.push('/poddaja/' + podcast.id)">{{podcast.ime}}</a></li>
        <li><a @click="$router.push('/poddaja/' + podcast.id + '/zaznamki')">zaznamki</a></li>
      </ol>
    </section>
      <br />
      <div v-if="nalaganje" style="text-align: center;">
        <h2>Poteka pripravljanje epizode. Prosimo počakajte.</h2>
        <div class="lds-ellipsis"><div></div><div></div><div></div><div></div></div>
        <p>To lahko traja nekaj minut.</p>
      </div>
      <div v-else>
        <div v-if="podcast.zaznamki && podcast.zaznamki.length > 0">
          <div v-for="(zaznamek) in podcast.zaznamki" :key="zaznamek.id">
            <div class="col-xs-12" @click="predvajajZaznamek(zaznamek)">
              <!-- {{epizoda}} -->
              <Pesem :naslov="zaznamek.imeZaznamka" :avtor="zaznamek.opisZaznamka" :barva="'purple'" :ikona="'fa-bookmark'" :album="zaznamek.imeEpizode" :odstotek="0">
              </Pesem>
            </div>
          </div>
        </div>
        <div v-else style="text-align: center">
          Za to poddajo ni še nobenih zaznamkov.
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Rest from './../../../storitve/rest'
import Vue from 'vue'
import { EventBus } from './../../../storitve/event-bus'
import Pesem from './../../skupno/Pesem.vue'

export default {
  components: {
    Pesem
  },
  name: 'zaznamki',
  props: ['idPodcasta'],
  data () {
    return {
      podcast: {},
      vse: false,
      nalaganje: false
    }
  },
  created: function () {
    this.pridobiZaznamke(this.vse);
  },
  methods: {
    odstraniNarocnino() {
      this.$swal.fire({
        title: 'Res želiš odstraniti to poddajo izmed svojih priljubljenih.',
        text: "Če je poddaja zasebna, je ne moreš dobiti nazaj",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Da',
        cancelButtonText: 'Ne'
      }).then((result) => {
        if (result.value) {
          Rest.request("POST", `/podcasti/odnaroci`, {idPodcasta: this.idPodcasta})
          .then(data => {
            this.$router.push({ path:'/' })
          }).catch(err => {
            this.pridobiEpizode(this.vse)
            console.log(err);
          });
        }
      })
    },
    odstraniPodcast() {
      this.$swal.fire({
        title: 'Res želiš izbrisati to poddajo',
        text: "Tega dejanja se ne da razveljaviti. Izbrisani bodo tudi vsi časi poslušanja epizod te poddaje.",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Da',
        cancelButtonText: 'Ne'
      }).then((result) => {
        if (result.value) {
          Rest.request("POST", `/podcasti/odstrani`, {idPodcasta: this.idPodcasta})
          .then(data => {
            this.$router.push({ path:'/' })
          }).catch(err => {
            this.pridobiEpizode(this.vse)
            console.log(err);
          });
        }
      })
    },
    koncajPodcast(id, koncano) {
      let postdata = {
        idEpizode: id,
        koncano: koncano
      };
      Rest.request("POST", `/podcasti/nastaviKoncano`, postdata)
        .then(data => {
      })
      .catch(err => {
        this.pridobiEpizode(this.vse)
        console.log(err);
      });
    },
    odstraniHtml(besedilo) {
      if ((besedilo === null) || (besedilo === '')) {
        return false;
      } else {
        besedilo = besedilo.toString();
        return besedilo.replace(/<[^>]*>/g, '');
      }
    },
    pridobiZaznamke: function(vse) {
      Rest.request('POST', `/podcasti/zaznamki/vse`, {idPodcasta: this.idPodcasta}).then((data) => {
        this.podcast = data
      }).catch((err) => {
        console.log(err)
      })
    },
    predvajajZaznamek (zaznamek) {
      Rest.request('POST', `/podcasti/epizoda`, {idEpizode: zaznamek.idEpizode}).then((data) => {
        data.mestoPoslusanjaSekunde = zaznamek.cas
        this.predvajajPodcast(data)
      }).catch((err) => {
        console.log(err)
      })
    },
    predvajajPodcast: function(podcast) {
      if (podcast.ytEpizoda && !podcast.nalozenaEpizodaId) {
        this.$swal.fire({
          title: 'Želiš pripraviti to poddajo?',
          text: "Priprava poddaje lahko traja nekaj časa",
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Da',
          cancelButtonText: 'Ne'
        }).then((result) => {
          if (result.value) {
            this.nalaganje = true
            Rest.request('POST', `/podcasti/naloziEpizodo`, {idEpizode: podcast.id}).then((data) => {
              this.nalaganje = false
              this.pridobiZaznamke(this.vse)
            }).catch((err) => {
              this.nalaganje = false
              console.log(err)
            })
          }
        })
      } else {
        if (podcast.ytEpizoda && !podcast.epizodaUrl) {
          podcast.epizodaUrl = '/podcast/' + podcast.nalozenaEpizodaId
        }
        podcast.podcast = true
        this.$emit('predvajajPodcast', podcast);
      }
    },
    click: function () {
      console.log('klik dela');
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
