<template>
  <div>
    <form role="form">
      <div class="form-group" v-for="kljuc in kljuci" :key="kljuc.ime">
        <div v-if="kljuc.tip === 'checkbox'">
          <input type="checkbox" v-model="objekt[kljuc.key]"> {{kljuc.ime}}
        </div>
        <div v-else>
          <label>{{kljuc.ime}}</label>
          <input :type="kljuc.tip" class="form-control" v-model="objekt[kljuc.key]">
        </div>
      </div>
      <button type="submit" class="btn btn-primary btn-block" @click.prevent="posodibi">Potrdi</button>
    </form>
  </div>
</template>

<script>
import Rest from "./../../storitve/rest";

export default {
  components: {
  },
  name: 'uredi',
  props: ['objekt', 'kljuci', 'submitUrl'],
  data () {
    return {
    }
  },
  methods: {
    posodibi() {
      Rest.request('POST', this.submitUrl, this.objekt).then((data) => {
        this.$swal.fire(
            'Uspešno posodobljeno',
            'Pesem uspešno posodobljena',
            'success'
          )
        this.$emit('koncano');
      }).catch((err) => {
        console.log(err)
      })
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>
