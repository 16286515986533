<template>
  <div>
    <div class="container">
    <section class="content-header">
      <h1>
        {{imeMape}}
        <button type="button" class="btn btn-default" @click.prevent.stop="$router.push('/mape/'+mapa+'/uredi')" v-if="mapa">
            <i class="fa fa-cogs"></i></button>
        <button type="button" class="btn btn-default" @click.prevent.stop="spremeniSlikeSeznamov()" style="margin-left: 1ex">
          <i v-if="slikeSeznamov" class="fa fa-list"></i>
          <i v-else class="fa fa-th"></i>
        </button>
        <small></small>
      </h1>
      <ol class="breadcrumb">
        <li><a @click="$router.push('/')">Domov</a></li>
        <li v-for="drobtina in breadcrumbs" :key="drobtina.id"><a @click="$router.push(drobtina.naslov)">{{drobtina.ime}}</a></li>
      </ol>
    </section>
      <br />
    <div :style="slikeSeznamov ? 'padding-left: 7px; padding-right: 8px': ''">
      <div v-for="(mapa) in mape" :key="mapa.id">
        <div v-if="slikeSeznamov" class="col-xs-4 col-sm-3 col-md-2" @click="$router.push('/seznami/' + mapa.id)" style="padding: 7px">
          <Podcast :naslov="mapa.ime" :slika="'/api/v1/slikeSeznamov/' + mapa.id" :zasebno="mapa.zasebno" :vSkupini="mapa.vSkupini" :mapa="true">
            <button type="button" class="btn btn-default form-control" @click.prevent.stop="$router.push('/mape/'+mapa.id+'/uredi')">
                <i class="fa fa-cogs"></i></button>
          </Podcast>
        </div>
        <div v-else class="col-xs-12" @click="$router.push('/seznami/' + mapa.id)">
          <Pesem :naslov="mapa.ime" avtor=" " barva="orange" ikona="fa-folder-o" album="Mapa" :zasebno="mapa.zasebno" :vSkupini="mapa.vSkupini">
            <div class="pull-right">
              <button type="button" class="btn btn-default" @click.prevent.stop="$router.push('/mape/'+mapa.id+'/uredi')">
                <i class="fa fa-cogs"></i></button>
            </div>
          </Pesem>
        </div>
      </div>
      
      <div v-for="(seznam) in seznami" :key="seznam.id">
        <div v-if="slikeSeznamov" class="col-xs-6 col-sm-3 col-md-2" @click="$router.push('/seznam/' + seznam.id)" style="padding: 7px">
          <Podcast :naslov="seznam.ime" :slika="'/api/v1/slikeSeznamov/' + seznam.id" :zasebno="seznam.zasebno" :vSkupini="seznam.vSkupini"
          :eventi="['play', 'shuffle']" @play="predvajajSeznam(seznam.id, false)" @shuffle="predvajajSeznam(seznam.id, true)">
            <button type="button" class="btn btn-default form-control" @click.prevent.stop="$router.push('/seznam/'+seznam.id+'/uredi')">
                <i class="fa fa-cogs"></i></button>
            <br/>
            <button type="button" class="btn btn-default form-control" @click.prevent.stop="seznamVVrsto(seznam.id)" style="margin-top:1ex">
                <i class="fa fa-mail-forward"></i></button>
          </Podcast>
        </div>
        <div v-else class="col-xs-12" @click="$router.push('/seznam/' + seznam.id)">
          <Pesem :naslov="seznam.ime" avtor=" " barva="orange" ikona="fa-reorder" album="Seznam predvajanja" :zasebno="seznam.zasebno" :vSkupini="seznam.vSkupini">
            <div class="pull-right">
              <button type="button" class="btn btn-default" @click.prevent.stop="$router.push('/seznam/'+seznam.id+'/uredi')">
                <i class="fa fa-cogs"></i></button>
            </div>
          </Pesem>
        </div>
      </div>
      <div v-if="slikeSeznamov" class="col-xs-6 col-sm-3 col-md-2" data-toggle="modal" data-target="#modal-dodaj" style="padding: 7px">
        <Podcast :naslov="'Ustvari seznam'" :slika="darkMode ? '/plus-dark.png' : 'plus.png'"></Podcast>
      </div>
      <div v-else class="col-sm-6 col-xs-12" data-toggle="modal" data-target="#modal-dodaj">
        <Pesem naslov="Ustvari seznam" avtor=" " barva="orange" ikona="fa-plus" album="Ustvari seznam"></Pesem>
      </div>
      <div v-if="slikeSeznamov" class="col-xs-6 col-sm-3 col-md-2" data-toggle="modal" data-target="#modal-dodaj-mapo" style="padding: 7px">
        <Podcast :naslov="'Ustvari mapo'" :slika="darkMode ? '/plus-dark.png' : 'plus.png'"></Podcast>
      </div>
      <div v-else class="col-sm-6 col-xs-12" data-toggle="modal" data-target="#modal-dodaj-mapo">
        <Pesem naslov="Ustvari mapo" avtor=" " barva="orange" ikona="fa-plus" album="Ustvari mapo"></Pesem>
      </div>
    </div>
    <div class="modal fade" id="modal-dodaj">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span></button>
            <h4 class="modal-title">Dodaj seznam predvajanja</h4>
          </div>
          <div class="modal-body">
            <form @submit.prevent="dodajSeznam()">
                <div class="form-group">
                  <label for="exampleInputEmail1">Ime seznama</label>
                  <input v-model="imeSeznama" type="text" class="form-control">
                </div>

                <div class="form-group">
                  <div class="checkbox">
                    <label>
                      <input type="checkbox" v-model="zasebno">
                      <i class="fa fa-lock" v-if="zasebno"></i>
                      <i class="fa fa-unlock" v-else></i>
                      Zasebno
                    </label>
                  </div>
                </div>
            </form>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-default pull-left" data-dismiss="modal">Zapri</button>
            <button type="button" class="btn btn-primary" @click="dodajSeznam()">Potrdi</button>
          </div>
        </div>
        <!-- /.modal-content -->
      </div>
      <!-- /.modal-dialog -->
    </div>
    </div>

    <div class="modal fade" id="modal-dodaj-mapo">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span></button>
            <h4 class="modal-title">Dodaj mapo</h4>
          </div>
          <div class="modal-body">
            <form @submit.prevent="dodajMapo()">
                <div class="form-group">
                  <label for="exampleInputEmail1">Ime mape</label>
                  <input v-model="imeSeznama" type="text" class="form-control">
                </div>

                <div class="form-group">
                  <div class="checkbox">
                    <label>
                      <input type="checkbox" v-model="zasebno">
                      <i class="fa fa-lock" v-if="zasebno"></i>
                      <i class="fa fa-unlock" v-else></i>
                      Zasebno
                    </label>
                  </div>
                </div>
            </form>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-default pull-left" data-dismiss="modal">Zapri</button>
            <button type="button" class="btn btn-primary" @click="dodajMapo()">Potrdi</button>
          </div>
        </div>
        <!-- /.modal-content -->
      </div>
      <!-- /.modal-dialog -->
    </div>
  </div>
</template>

<script>
import Rest from './../../storitve/rest'
import Vue from 'vue'
import { EventBus } from './../../storitve/event-bus'
import Pesem from './../skupno/Pesem.vue'
import Podcast from "./../skupno/Podcast.vue";
import nalaganje from './../../storitve/nalaganje-pesmi'

export default {
  components: {
    Pesem,
    Podcast
  },
  name: 'seznami',
  props: ['mapa'],
  data () {
    return {
      seznami: [],
      mape: [],
      imeSeznama: "",
      zasebno: false,
      imeMape: "Seznami predvajanja",
      breadcrumbs: [],
      slikeSeznamov: true,
      darkMode: false
    }
  },
  watch: {
    mapa: function (staro, novo) {
      this.seznami = []
      this.mape = []
      this.pridobiSezname();
    }
  },
  created: function () {
    if (localStorage.getItem('dark-mode')) {
      this.darkMode = (localStorage.getItem('dark-mode') === 'true')
    }
    this.pridobiSezname();
    if (localStorage.getItem("slike-seznamov") !== null) {
      this.slikeSeznamov = (typeof localStorage.getItem("slike-seznamov") === "boolean" ? localStorage.getItem("slike-seznamov") : localStorage.getItem("slike-seznamov") === 'true')
    }
    if (Vue.prototype.$brezPovezave) {
      this.slikeSeznamov = false
    }
  },
  methods: {
    predvajajSeznam(id, premesaj) {
      nalaganje.pridobiSeznamPaginirano(id, {'limit': 50, 'random': premesaj}).then((data) => {
        let izbrana = 0
        if (premesaj) {
          izbrana = Math.floor(Math.random() * data.pesmi.length)
        }
        this.$emit('predvajaj', [data.pesmi, izbrana]);
      }).catch((err) => {
      })
    },
    seznamVVrsto (idSeznama) {
      Rest.request('POST', `/seznami/pesmi`, {idSeznama: idSeznama}).then((data) => {
        this.$emit('predvajajNaslednjo', data.pesmi);
      }).catch((err) => {
      })
      this.$swal.fire({
        position: 'top',
        title: 'Seznam dodan v vrsto za predvajanje',
        icon: 'success',
        showConfirmButton: false,
        timerProgressBar: true,
        backdrop: null,
        timer: 1000
      })
    },
    spremeniSlikeSeznamov() {
      this.slikeSeznamov = !this.slikeSeznamov
      localStorage.setItem("slike-seznamov", this.slikeSeznamov)
    },
    naloziMapo: function() {
      Rest.request("POST", `/mape/podatki`, { idMape: this.mapa })
        .then(data => {
          this.imeMape = data.ime;
          this.breadcrumbs = data.breadcrumbs
        })
        .catch(err => {
          console.log(err);
        });
    },
    dodajSeznam: function() {
      if (this.imeSeznama.length === 0) {
        window.jQuery("#modal-dodaj").modal("toggle")
        return
      }
      let podatki = {
        imeSeznama: this.imeSeznama,
        zasebno: this.zasebno,
        mapa: this.mapa
      }
      Rest.request('POST', `/seznami/ustvari`, podatki).then((data) => {
        this.seznami = data
        this.imeSeznama = ""
        window.jQuery("#modal-dodaj").modal("toggle")
      }).catch((err) => {
        console.log(err)
      })
    },
    dodajMapo: function() {
      if (this.imeSeznama.length === 0) {
        window.jQuery("#modal-dodaj-mapo").modal("toggle")
        return
      }
      let podatki = {
        imeMape: this.imeSeznama,
        zasebno: this.zasebno,
        mapa: this.mapa
      }
      Rest.request('POST', `/mape/ustvari`, podatki).then((data) => {
        this.mape = data
        this.imeSeznama = ""
        window.jQuery("#modal-dodaj-mapo").modal("toggle")
      }).catch((err) => {
        console.log(err)
      })
    },
    pridobiSezname: function() {
      let podatki = {
        mapa: this.mapa
      }
      Rest.request('POST', `/seznami`, podatki).then((data) => {
        this.seznami = data
      }).catch((err) => {
        console.log(err)
      })
      Rest.request('POST', `/mape`, podatki).then((data) => {
        this.mape = data
      }).catch((err) => {
        console.log(err)
      })
      if (this.mapa) {
        this.naloziMapo()
      } else {
        this.imeMape = "Seznami predvajanja"
        this.breadcrumbs = [{ime: "Seznami predvajanja", id: "", naslov: "/seznami"}]
      }
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
