<template>
  <div>
    <div class="container">
    <section class="content-header">
      <h1>
        Uredi mapo
        <small></small>
      </h1>
      <ol class="breadcrumb">
        <li><a @click="$router.push('/')">Domov</a></li>
        <li v-for="drobtina in breadcrumbs" :key="drobtina.id"><a @click="$router.push(drobtina.naslov)">{{drobtina.ime}}</a></li>
        <!-- <li><a @click="$router.push('/seznami')">Seznami predvajanja</a></li> -->
        <!-- <li><a @click="$router.push('/seznami/' + idMape)">{{imeMape}}</a></li> -->
        <li><a @click="$router.push('/mape/' + idMape + '/uredi')">Uredi mapo</a></li>
      </ol>
    </section>
      <br />
      <div class="col-xs-12">
        <div class="box box-primary">
          <div class="box-header with-border">
            <h3 class="box-title">Uredi mapo</h3>
            <button class="btn btn-danger pull-right" @click="izbrisiMapo()"><i class="fa fa-trash"></i></button>
          </div>
          <!-- /.box-header -->
          <!-- form start -->
          <form>
            <div class="box-body row">
              <div class="col-md-6">
                <div class="form-group">
                  <label>Ime mape</label>
                  <input v-model="imeMape" type="text" class="form-control" />
                </div>

                <div class="form-group">
                  <label>Starš</label>
                  <select v-model="stars" class="form-control">
                    <option :value="''">Koren</option>
                    <option v-for="mapa in mape" :value="mapa.id" :key="mapa.id">{{mapa.ime}}</option>
                  </select>
                </div>
                
                <div class="form-group">
                  <div class="checkbox">
                    <label>
                      <input type="checkbox" v-model="zasebno">
                      <i class="fa fa-lock" v-if="zasebno"></i>
                      <i class="fa fa-unlock" v-else></i>
                      Zasebno
                    </label>
                  </div>
                </div>

                <div class="form-group" v-if="zasebno && skupine.length > 0">
                  Skupna raba s skupinami:
                  <div class="checkbox" v-for="skupina in skupine" :key="skupina.id">
                    <label>
                      <input type="checkbox" :value="skupina.id" v-model="izbraneSkupine">
                      {{skupina.ime}}
                    </label>
                  </div>
                </div>
              </div>
              <div class="col-md-6">
                <uredi-sliko  :imeSeznama="imeMape" :idSeznama="idMape"></uredi-sliko>
              </div>

            </div>
            <!-- /.box-body -->

            <div class="box-footer">
              <button @click.prevent="posodobiMapo()" class="btn btn-primary">Posodobi</button>
            </div>
          </form>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import Rest from "./../../storitve/rest";
import Vue from "vue";
import VueSweetalert2 from 'vue-sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';
import UrediSliko from "./../skupno/UrediSliko.vue";


Vue.use(VueSweetalert2);

export default {
  components: {
    UrediSliko
  },
  name: "uredi-mapo",
  props: ["idMape"],
  data() {
    return {
      imeMape: "",
      zasebno: false,
      mape:[],
      stars: "",
      skupine: [],
      izbraneSkupine: [],
      breadcrumbs: []
    };
  },
  created: function() {
    this.naloziMapo();
    this.pridobiSkupine();
    this.pridobiIzbraneSkupine();
  },
  methods: {
    pridobiSkupine: function() {
      let podatki = {
        idSeznama: this.idSeznama
      };
      Rest.request("POST", `/skupine/pridobiSkupine`, podatki)
        .then(data => {
          this.skupine = data
        })
        .catch(err => {
          console.log(err);
        });
    },
    pridobiIzbraneSkupine: function() {
      let podatki = {
        idMape: this.idMape
      };
      Rest.request("POST", `/skupine/izbraneSkupineMape`, podatki)
        .then(data => {
          this.izbraneSkupine = data
        })
        .catch(err => {
          console.log(err);
        });
    },
    izbrisiMapo: function() {
      this.$swal.fire({
        title: 'Želiš izbrisati mapo?',
        text: "Brisanja mape se ne da razveljaviti.",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Da',
        cancelButtonText: 'Ne'
      }).then((result) => {
        if (result.value) {
          console.log('ok')
          let podatki = {
            idMape: this.idMape
          }

          Rest.request("POST", `/mape/izbrisi`, podatki)
            .then(data => {
              this.$router.push('/seznami')
            })
            .catch(err => {
              console.log(err);
              // th.opozorila.push(err.status + " " + err.statusText);
            });
        }
      })
    },
    naloziMapo: function() {
      Rest.request("POST", `/mape/podatki`, { idMape: this.idMape })
        .then(data => {
          this.imeMape = data.ime;
          this.zasebno = data.zasebno;
          this.stars = data.idStarsa;
          this.breadcrumbs = data.breadcrumbs
        })
        .catch(err => {
          console.log(err);
        });

      Rest.request("GET", `/vse-mape`)
        .then(data => {
          this.mape = data
        })
        .catch(err => {
          console.log(err);
        });
    },
    posodobiMapo: function() {
      let podatki = {
        idMape: this.idMape,
        ime: this.imeMape,
        zasebno: this.zasebno,
        stars: this.stars,
        izbraneSkupine: this.izbraneSkupine
      };
      Rest.request("POST", `/mape/podatki/posodobi`, podatki)
        .then(data => {
          this.$router.push('/seznami')
        })
        .catch(err => {
          console.log(err);
        });
    }
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.pikice{
  text-overflow: ellipsis !important;
  overflow: hidden;
  white-space: nowrap;
}
.dropbox {
  outline: 2px dashed grey; /* the dash box */
  outline-offset: -10px;
  background: lightcyan;
  color: dimgray;
  padding: 10px 10px;
  min-height: 200px; /* minimum height */
  position: relative;
  cursor: pointer;
}

.input-file {
  opacity: 0; /* invisible but it's there! */
  width: 100%;
  height: 200px;
  position: absolute;
  cursor: pointer;
}

.dropbox:hover {
  background: lightblue; /* when mouse over to the drop zone, change color */
}

.dropbox p {
  font-size: 1.2em;
  text-align: center;
  padding: 50px 0;
}
</style>
