<template>
  <div>
    <div class="container">
      <section class="content-header">
        <h1>
          Iskanje
          <small></small>
        </h1>
        <ol class="breadcrumb">
          <li><a @click="$router.push('/')">Domov</a></li>
          <li><a @click="$router.push('/iskanje')">Iskanje</a></li>
        </ol>
      </section>
      <br />
      <div class="col-xs-12">
        <div class="input-group">
          <input type="text" autocomplete="off" name="q" class="form-control" placeholder="Iskanje..." v-model="iskalnoGeslo" @change="pridobiPesmi()">
          <span class="input-group-btn">
            <button type="submit" name="search" id="search-btn" class="btn btn-flat" @click.prevent="pridobiPesmi()"><i class="fa fa-search"></i>
            </button>
          </span>
        </div>
        <div class="checkbox">
          <label>
            <input type="checkbox" v-model="razsirjeno"> Razširjeno iskanje
          </label>
        </div>
        <span v-if="kratkoGeslo">Iskalno geslo mora imeti vsaj 3 znake.</span>
      </div>
      <br />
      <br />
      <br />
      <div v-for="(pesem, index) in pesmi" :key="pesem.id">
        <div class="col-xs-12" @click="predvajajPesem(index)">
          <Pesem :naslov="pesem['ime-pesmi']" :avtor="pesem.avtor" barva="yellow" ikona="fa-music" :album="pesem.album" :opomba="pesem.opomba">
            <div class="pull-right" @click.stop="">
              <div class="btn-group">
                <button type="button" class="btn btn-default" @click.prevent.stop="$router.push('/pesem/'+pesem.id)">
                  <i class="fa fa-cogs"></i>
                </button>
                <button type="button" class="btn btn-default" @click.prevent.stop="predvajajNaslednjo(index)">
                  <i class="fa fa-mail-forward"></i>
                </button>
              </div>
            </div>
          </Pesem>
        </div>
      </div>
      <div class="col-xs-12 text-center" style="margin-bottom: 1em" v-if="!razsirjeno && (pesmi.length > 0 || ytPesmi.length > 0)">
        <button class="btn btn-default" style="margin: auto" @click.prevent="razsirjeno = true; pridobiPesmi()">Poišči več</button>
      </div>
      <!-- yt pesmi -->
      <div v-for="(pesem) in ytPesmi" :key="pesem.id">
        <div class="col-xs-12" @click="$router.push('/nalozi/' + pesem.id)">
          <Pesem :naslov="pesem['ime']" :avtor="pesem.opis" barva="red" ikona="fa-youtube-play" :slika="pesem.slika" :album="pesem.kanal">
            <button type="button" class="btn btn-default pull-right" @click.prevent.stop="predvajajPredogled(pesem)">
              <i class="fa fa-tv"></i>
            </button>
          </Pesem>
        </div>

        <div class="col-xs-12" v-if="predogledId && predogledId === pesem.id" style="margin-bottom: 15px">
          <vue-plyr>
            <div data-plyr-provider="youtube" :data-plyr-embed-id="predogledId"></div>
          </vue-plyr>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Rest from './../../storitve/rest'
import Vue from 'vue'
import { EventBus } from './../../storitve/event-bus'
import Pesem from './../skupno/Pesem.vue'

export default {
  components: {
    Pesem
  },
  name: 'vse-pesmi',
  props: {
  },
  data () {
    return {
      iskalnoGeslo: "",
      pesmi: [],
      seznami: [],
      idOdprtePesmi: "",
      kratkoGeslo: false,
      ytPesmi: [],
      predogledId: null,
      razsirjeno: false
    }
  },
  created: function () {
  },
  methods: {
    predvajajNaslednjo (index) {
      this.$emit('predvajajNaslednjo', this.pesmi[index]);
      this.$swal.fire({
        position: 'top',
        title: 'Pesem dodana v vrsto za predvajanje',
        icon: 'success',
        showConfirmButton: false,
        timerProgressBar: true,
        backdrop: null,
        timer: 1000
      })
    },
    pridobiPesmi: function() {
      if (this.iskalnoGeslo.length < 3) {
        this.kratkoGeslo = true
      } else {
        this.kratkoGeslo = false
        let geslo = this.iskalnoGeslo
        if (this.razsirjeno) {
          let besede = geslo.split(" ")
          for (let i = 0; i < besede.length; i++) {
            if (besede[i].length < 3) {
              besede.splice(i, 1)
              i--
            }
          }
          geslo = besede.join(" ")
        }
        if (geslo.length < 3) {
          this.kratkoGeslo = true
          return
        }
        let podatki = {
          geslo: geslo,
          razsirjeno: this.razsirjeno
        }
        Rest.request('POST', `/iskanje`, podatki).then((data) => {
          this.pesmi = data
        }).catch((err) => {
          console.log(err)
        })

        // pridobi yt
        Rest.request('GET', `/apiKljuci/youtube`).then((data) => {
          let apiKey = data.kljuc
          this.ytPesmi = []
          Rest.request('GET', 'https://www.googleapis.com/youtube/v3/search?part=snippet&q=' + encodeURIComponent(this.iskalnoGeslo) + '&type=video&key=' + apiKey).then((data) => {
            this.ytPesmi = []
            for (let i = 0; i < Math.min(5, data.items.length); i++) {
              this.ytPesmi.push({
                ime: this.zamenjajZnake(data.items[i].snippet.title),
                opis: this.zamenjajZnake(data.items[i].snippet.description),
                id: data.items[i].id.videoId,
                slika: data.items[i].snippet.thumbnails.default.url,
                kanal: this.zamenjajZnake(data.items[i].snippet.channelTitle)
              })
            }
          }).catch((err) => {
            console.log(err)
          })
        })
      }
    },
    predvajajPredogled (pesem) {
      this.predogledId = null
      this.$nextTick(function() {
        this.predogledId = pesem.id
      })
    },
    predvajajPesem: function(index) {
      if (this.pesmi.length > 100) {
        let zacetek = Math.max(0, index - 70)
        this.$emit('predvajaj', [this.pesmi.slice(zacetek, Math.min(index + 70, this.pesmi.length)), index - zacetek]);
      } else {
        this.$emit('predvajaj', [this.pesmi, index]);
      }
    },
    zamenjajZnake (besedilo) {
      besedilo = besedilo.split("&nbsp;").join(" ")
      besedilo = besedilo.split("&quot;").join("'")
      besedilo = besedilo.split("&amp;").join("&")
      besedilo = besedilo.split("&apos;").join("'")
      besedilo = besedilo.split("&lt;").join("<")
      besedilo = besedilo.split("&gt;").join(">")
      besedilo = besedilo.split("&gt;").join(">")
      return besedilo
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
