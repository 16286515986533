<template>
  <div>
    <div class="container">

    <section class="content-header">
      <h1>
        Naloži pesem
        <small></small>
      </h1>
      <ol class="breadcrumb">
        <li><a @click="$router.push('/')">Domov</a></li>
        <li><a @click="$router.push('/nalozi')">Naloži pesem</a></li>
      </ol>
    </section>
      <br />
      <div class="col-xs-12">
        <div class="box box-primary" v-if="!koncano">
          <div class="box-header with-border">
            <h3 class="box-title">{{playlist ? "Dodaj seznam" : "Dodaj pesem"}}</h3>

            <div class="box-tools pull-right">
              <div class="btn-group" data-toggle="btn-toggle">
                <button type="button" class="btn btn-default btn-sm" :class="[playlist || spotify || text ? '' : 'active']" @click="playlist = false; spotify=false; text=false">Pesem</button>
                <button type="button" class="btn btn-default btn-sm" :class="[playlist ? 'active' : '']" @click="playlist = true; spotify=false; text=false">Seznam</button>
                <button type="button" class="btn btn-default btn-sm" :class="[spotify ? 'active' : '']" @click="playlist = false; spotify=true; text=false">Spotify</button>
                <button type="button" class="btn btn-default btn-sm" :class="[text ? 'active' : '']" @click="playlist = false; spotify=false; text=true">Besedilo</button>
              </div>
            </div>
          </div>
          <!-- /.box-header -->
          <!-- form start -->
          <form v-if="!spotify && !text">
            <div class="box-body">
              <div class="form-group">
                <label>YouTube link</label>
                <input v-model="link" type="text" class="form-control" placeholder="https://www.youtube.com/watch?v=xWjOh0Ph8uM" @change="pridobiPodatke()">
              </div>

              <div class="form-group" v-if="!playlist">
                <label>Naslov pesmi</label>
                <input v-model="naslov" type="text" class="form-control">
              </div>

              <div class="form-group">
                <label>Album</label>
                <input v-model="album" type="text" class="form-control">
              </div>

              <div class="form-group">
                <label>Avtor</label>
                <input v-model="avtor" type="text" class="form-control">
              </div>

              <div class="form-group">
                <div class="checkbox">
                  <span class="pull-right clickable" @click="zamenjajImeAvtor()" style="z-index: 1">
                    <i class="fa fa-refresh"></i>
                    Zamenjaj naslov in avtorja
                  </span>
                  <label>
                    <input type="checkbox" v-model="skrita">
                    <i class="fa fa-eye-slash" v-if="skrita"></i>
                    <i class="fa fa-eye" v-else></i>
                    Skrita
                  </label>
                </div>
              </div>

              <div class="form-group">
                <label>Seznami predvajanja</label>
                <div class="input-group">
                  <input type="text" autocomplete="off" name="q" class="form-control" placeholder="Iskanje..." v-model="iskalnoGeslo">
                  <span class="input-group-btn">
                    <button name="search" id="search-btn" class="btn btn-flat" @click.prevent.stop=""><i class="fa fa-search"></i>
                    </button>
                  </span>
                </div>
                <div class="checkbox" v-for="seznam in filtriraniSeznami" :key="seznam.id">
                  <label>
                    <input type="checkbox" v-model="izbraniSeznami" :value="seznam.id">
                    <span v-if="seznam.imeMape">{{seznam.imeMape}}/</span>
                    {{seznam.ime}}
                  </label>
                </div>
              </div>
            </div>
            <!-- /.box-body -->

            <div class="box-footer">
              <button @click.prevent="dodajPesem()" class="btn btn-primary">Potrdi</button>
            </div>
          </form>
          <div v-if="spotify" class="box-body">
            <nalozi-spotify :text="false"></nalozi-spotify>
          </div>
          <div v-if="text" class="box-body">
            <nalozi-spotify :text="true" :zakodiranSeznam="zakodiranSeznam"></nalozi-spotify>
          </div>
        </div>
        <div class="box box-primary" v-else>
          <div class="box-header with-border">
            <h3 class="box-title">Dodaj pesem</h3>
          </div>
          <div class="box-body">
            Pesem bo kmalu dodana<br />
            <a @click="$router.push('/naloge')">Preglej napredek nalaganja</a>
          </div>
          <!-- /.box-body -->

          <div class="box-footer">
            <button @click.prevent="ponastavi()" class="btn btn-primary">Dodaj novo pesem</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Rest from './../../storitve/rest'
import Vue from 'vue'
import { EventBus } from './../../storitve/event-bus.js'
import Pesem from './../skupno/Pesem.vue'
import {parseTitleString} from './../../storitve/parseTitle'
import NaloziSpotify from './komponente/NaloziSpotify.vue'

export default {
  components: {
    NaloziSpotify
  },
  name: 'NaloziPesem',
  props: ['ytid'],
  data () {
    return {
      playlist: false,
      spotify: false,
      link: "",
      album: "",
      avtor: "",
      naslov: "",
      koncano: false,
      seznami: [],
      izbraniSeznami: [],
      skrita: false,
      iskalnoGeslo: "",
      text: false,
      zakodiranSeznam: ""
    }
  },
  mounted () {
    console.log('----------------------------------------')
    let queryString = window.location.search;
    if (!queryString) {
      queryString = window.location.hash.substr(1)
    }
    let token = new URLSearchParams(queryString).get('access_token')
    console.log(token)
    if (token) {
      localStorage.setItem('spotifyToken', token)
      this.$router.push('/nalozi')
      this.spotify = true
    }
  },
  created: function () {
    this.pridobiSezname()
    if (this.ytid) {
      if (this.ytid.length > 20) {
        this.text = true
        this.zakodiranSeznam = this.ytid
      } else {
        this.link = 'https://youtu.be/' + this.ytid
        this.pridobiPodatke()
      }
    }
  },
  computed: {
    filtriraniSeznami () {
      return this.seznami.filter(el => el.ime.toLowerCase().indexOf(this.iskalnoGeslo.toLowerCase()) >= 0)
    }
  },
  methods: {
    zamenjajImeAvtor() {
      let naslov = this.naslov
      this.naslov = this.avtor
      this.avtor = naslov
    },
    pridobiPodatke() {
      if (this.playlist) {
        return
      }
      // popravi url
      let url = new URL(this.link)
      if (url.hostname.endsWith("youtube.com")) {
        let id = url.searchParams.get('v');
        this.link = "https://youtu.be/" + id
      } else if (url.hostname.endsWith("youtu.be")) {
        let id = url.pathname.replace("/", "");
        this.link = "https://youtu.be/" + id
      }

      if (!this.link.match(/https?:\/\/(www|m)\.youtube.com\/watch\?v=...........(&list=.*)?/) && !this.link.match(/https?:\/\/youtu.be\/.........../)){
        return
      }
      let th = this
      Rest.request('GET', `/apiKljuci/youtube`).then((data) => {
          let apiKey = data.kljuc
        let video_id;
        if (this.link.indexOf('v=') != -1){
          video_id = this.link.split('v=')[1];
        } else {
          video_id = this.link.substring(this.link.length - 11, this.link.length)
        }
        let ampersandPosition = video_id.indexOf('&');
        if(ampersandPosition != -1) {
          video_id = video_id.substring(0, ampersandPosition);
        }
        Rest.request('GET', 'https://www.googleapis.com/youtube/v3/videos?part=snippet&id=' + video_id + '&key=' + apiKey).then((data) => {
          let naslov = data.items[0].snippet.localized.title
          console.log(parseTitleString)
          parseTitleString(naslov, (podatki) => {
            console.log(podatki)
            if (podatki.title){
              th.naslov = podatki.title
            }
            if (podatki.artist){
              th.avtor = podatki.artist
            }
          })
        }).catch((err) => {
          console.log(err)
        })
      })
    },
    pridobiSezname() {
      Rest.request('GET', `/vsi-seznami`).then((data) => {
        this.seznami = data
        console.log(data)
      }).catch((err) => {
        console.log(err)
      })
    },
    ponastavi: function() {
      this.link = ""
      this.album = ""
      this.avtor = ""
      this.naslov = ""
      this.koncano = false
    },
    dodajPesem: function() {
      let podatki = {
        povezava: this.link,
        album: this.album,
        avtor: this.avtor,
        naslov: this.naslov,
        izbraniSeznami: this.izbraniSeznami,
        skrita: this.skrita
      }
      Rest.request('POST', (this.playlist ? `/naloziPlaylist` : `/nalozi`), podatki).then((data) => {
        EventBus.$emit('sproziObvestila', 10);
        this.koncano = true;
      }).catch((err) => {
        console.log(err)
      })
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.clickable{
  cursor: pointer; 
}
</style>
