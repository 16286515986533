<template>
  <div>
    <div class="container">
      <section class="content-header">
        <h1>
          Poddaje
          <small></small>
        </h1>
        <ol class="breadcrumb">
          <li>
            <a @click="$router.push('/')">Domov</a>
          </li>
          <li>
            <a @click="$router.push('/poddaje')">Poddaje</a>
          </li>
        </ol>
      </section>
      <br />
      <div class="" :style="'padding-left: 7px; padding-right: 8px'">
        <div v-for="podcast in podcasti"
          class="col-xs-6 col-sm-4 col-md-3"
          @click="$router.push('/poddaja/' + podcast.id)"
          :key="podcast.id"
          style="padding: 7px">
          <Podcast :naslov="podcast.ime" :slika="'/api/v1/slikeSeznamov/' + podcast.id" :novo="podcast.novo">
            <div>
              <button type="button" class="btn btn-default form-control" @click.prevent.stop="urediPodcast(podcast)">
                  <i class="fa fa-cogs"></i> Uredi podcast</button>
            </div>
            <div style="margin-top: 1ex">
              <button type="button" class="btn btn-default form-control" @click.prevent.stop="urediSliko(podcast)">
                  <i class="fa fa-cogs"></i> Uredi sliko</button>
            </div>
          </Podcast>
        </div>
        <div class="col-xs-6 col-sm-4 col-md-3"
          v-if="!$brezPovezave"
          @click="pridobiVsePodcaste()"
          style="padding: 7px"
          data-toggle="modal"
          data-target="#modal-dodaj-podcast">
          <Podcast :naslov="'Dodaj poddajo'" :slika="'/plus.png'"></Podcast>
        </div>
      </div>
    </div>

    <!-- Dodaj poddaje modal -->
    <div class="modal fade" id="modal-dodaj-podcast">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
            <h4 class="modal-title">Dodaj poddajo</h4>
          </div>
          <div class="modal-body">
            <div class="table-responsive" style="margin-bottom: 0px">
              <table class="table table-striped" style="margin-bottom: 0px;">
                <tbody>
                  <tr v-for="(podcast, ind) in vsiPodcasti" :key="'dodajPodcast'+ind">
                    <td><img class="img-thumbnail" :src="nalaganje.pretvoriLokalniUrl('/api/v1/slikeSeznamov/' + podcast.id)" style="height: 50px; min-width: 50px;"/></td>
                    <td style="vertical-align: middle; font-size: 18px; white-space: normal">{{podcast.ime}}</td>
                    <td style="vertical-align: middle; cursor: pointer; white-space: nowrap" @click="dodajPodcast(podcast.id)"><i class="fa fa-plus"></i> Dodaj</td>
                  </tr>
                </tbody>
              </table>
            </div>

            <button class="btn btn-default form-control" @click="$router.push('/dodajPodcast')" data-dismiss="modal"><i class="fa fa-plus"></i> Dodaj novo poddajo</button>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-default pull-right" data-dismiss="modal">Zapri</button>
          </div>
        </div>
        <!-- /.modal-content -->
      </div>
      <!-- /.modal-dialog -->
    </div>

    <!-- Uredi podcast -->
    <div class="modal fade" id="modal-uredi-podcast">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
            <h4 class="modal-title">Uredi poddajo</h4>
          </div>
          <div class="modal-body">
            <uredi :objekt="podcastZaUrejanje" :submitUrl="'/podcasti/uredi'" @koncano="mojiPodcasti()" :kljuci="[
            {ime: 'Ime', tip: 'text', key: 'ime'},
            {ime: 'Rss URL', tip: 'text', key: 'rssUrl'},
            {ime: 'Opis', tip: 'text', key: 'opis'},
            {ime: 'Youtube Podcast', tip: 'checkbox', key: 'youtubePodcast'},
            {ime: 'Youtube Playlist ID', tip: 'text', key: 'youtubePlaylistId'},
            {ime: 'Zasebno', tip: 'checkbox', key: 'zasebno'},
            ]"></uredi>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-default pull-right" data-dismiss="modal">Zapri</button>
          </div>
        </div>
        <!-- /.modal-content -->
      </div>
      <!-- /.modal-dialog -->
    </div>

    <!-- Uredi sliko -->
    <div class="modal fade" id="modal-uredi-podcast-sliko">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
            <h4 class="modal-title">Uredi sliko</h4>
          </div>
          <div class="modal-body">
            <uredi-sliko  :imeSeznama="podcastZaUrejanje.ime" :idSeznama="podcastZaUrejanje.id"></uredi-sliko>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-default pull-right" data-dismiss="modal">Zapri</button>
          </div>
        </div>
        <!-- /.modal-content -->
      </div>
      <!-- /.modal-dialog -->
    </div>

  </div>
</template>

<script>
import Rest from "./../../../storitve/rest";
import Vue from "vue";
import { EventBus } from "./../../../storitve/event-bus";
import Podcast from "./../../skupno/Podcast.vue";
import UrediSliko from "./../../skupno/UrediSliko.vue";
import Uredi from "./../../skupno/Uredi.vue";
import nalaganje from './../../../storitve/nalaganje-pesmi'


export default {
  components: {
    Podcast,
    UrediSliko,
    Uredi
  },
  name: "Podcasti",
  props: {},
  data() {
    return {
      podcasti: [],
      vsiPodcasti: [],
      podcastZaUrejanje: {},
      nalaganje
    };
  },
  created: function() {
    this.mojiPodcasti();
  },
  methods: {
    urediPodcast (podcast) {
      console.log(podcast)
      this.podcastZaUrejanje = podcast
      window.jQuery('#modal-uredi-podcast').modal('toggle')
    },
    urediSliko (podcast) {
      console.log(podcast)
      this.podcastZaUrejanje = podcast
      window.jQuery('#modal-uredi-podcast-sliko').modal('toggle')
    },
    dodajPodcast (idPodcasta) {
      let postdata = {
        idPodcasta: idPodcasta
      };
      window.jQuery('#modal-dodaj-podcast').modal('toggle')
      Rest.request("POST", `/podcasti/naroci`, postdata)
        .then(data => {
          this.mojiPodcasti()
        })
        .catch(err => {
          console.log(err);
        });
    },
    mojiPodcasti: function() {
      nalaganje.pridobiPodcaste()
        .then(data => {
          this.podcasti = data;
        })
        .catch(err => {
          console.log(err);
        });
    },
    pridobiVsePodcaste() {
      let postdata = {
        mapa: this.mapa
      };
      Rest.request("POST", `/podcasti/vsi`, postdata)
        .then(data => {
          this.vsiPodcasti = []
          for (let i = 0; i < data.length; i++) {
            let dodaj = true
            for (let j = 0; j < this.podcasti.length; j++) {
              console.log(this.podcasti[j].id, data[i].id)
              if (this.podcasti[j].id == data[i].id) {
                dodaj = false
              }
            }
            if (dodaj) {
              this.vsiPodcasti.push(data[i])
            }
          }
        })
        .catch(err => {
          console.log(err);
        });
    }
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.pikice {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
</style>
