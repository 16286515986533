import Vue from 'vue'
import jwtDecode from 'jwt-decode'
import nalaganje from './../storitve/nalaganje-pesmi'


// import Auth from './auth'
// import router from '../router/router'
import { EventBus } from './event-bus.js'

let API_URL = window.location.origin
// if (window.location.hostname === 'localhost' || window.location.hostname === '127.0.0.1') {
//   API_URL = 'http://localhost:8080'
// }

const $ = require('jquery')
const TAG = '[REST]'
let Rest = null
Rest = {
  baseURL: API_URL + '/api/v1',
  baseOfBaseUrl: API_URL,

  /*
   * Does actual rest calls.
   * @param type request (HTTP) type.
   * @param endpoint endpoint to use
   * @param data data object
   * @param token access token
   * @param queryParams query (url) parameters
   * */
  request: function (type, endpoint, data, queryParams, token) {
    let th = this
    queryParams = queryParams || {}
    if (token != null) {
      queryParams.access_token = token
    }

    var req = $.param(queryParams)
    if (req) {
      req = '?' + req
    }
    console.log(TAG, type + ' request to ' + endpoint + ' with data = ' + data + ' queryParams = ' + req)
    return new Promise(
      function (resolve, reject) {
        let requestObj = {
          url: (endpoint.startsWith("http")) ? endpoint : nalaganje.pretvoriLokalniUrl(API_URL + '/api/v1' + endpoint + req),
          data: data ? JSON.stringify(data) : null,
          type: type, // POST, GET, PUT, DELETE
          dataType: 'json',
          contentType: 'application/json; charset=UTF-8',
          beforeSend: ((!endpoint.startsWith("http")) ? function (xhr) {
            let accessToken = localStorage.getItem('sessionAccessToken')
            if (accessToken != null) {
              xhr.setRequestHeader('Authorization', 'Bearer ' + accessToken)
            }
          } : null)
        }

        let doneHandler = function (response, textStatus, xhr) {
          console.log(response)

          try {
            console.log(TAG, 'Request success, response = ' + response)
            resolve(response)
          } catch (e) {
            console.log(TAG, 'Failed to parse JSON, response = ' + response + ' exception = ' + e)
            reject(e)
          }
        }

        let failHandler = function (xhr, status, errorThrown) {
          let errorKey = 'Ni povezave'
          if (xhr.status == 200) {
            doneHandler(errorThrown, errorThrown, xhr)
            return
          }
          console.log(xhr)
          switch (xhr.status) {
              // TODO add other codes
            case 400:
              errorKey = 'bad_request'
              break
            case 401:
              errorKey = 'unauthorized'
              break
            case 403:
              errorKey = 'forbidden'
              break
            case 404:
              errorKey = 'not_found'
              break
            case 500:
              errorKey = 'internal_server_error'
              break
          }

          console.log(TAG, 'Request failed, reason = ' + errorKey)

          // Redirect to login
          if (errorKey === 'unauthorized') {
            Vue.vm.$router.push({path: '/prijava'})
          }

          if (errorKey == 'Ni povezave' && endpoint.startsWith("http://localhost:7777")) {
            // Ignoriraj
          } else {
            if (errorKey == 'Ni povezave') {
              if (nalaganje.jeCordova()) {
                // Vue.prototype.$brezPovezave = true
                // EventBus.$emit('posodobiBrezPovezave');
              }
            }
            Vue.vm.$swal.fire({
              position: 'top',
              title: ((xhr.responseJSON && xhr.responseJSON.sporocilo) ? xhr.responseJSON.sporocilo : errorKey),
              icon: 'error',
              showConfirmButton: false,
              timerProgressBar: true,
              backdrop: null,
              timer: 1000
            })
          }

          reject(xhr)
        }

        // Send the request.
        $.ajax(requestObj).done(doneHandler).fail(failHandler);
      })
  }
}

Rest.install = function install (Vue) {
  Vue.prototype.$rest = Rest
}

export default Rest

if (typeof window !== 'undefined' && window.Vue) {
  window.Vue.use(Rest)
}
