<template>
  <div>
    <div v-if="text">
      <div class="form-group">
        <label>Ime seznama</label>
        <input v-model="ime" type="text" class="form-control">
        <label>Url slike</label>
        <input v-model="urlSlike" type="text" class="form-control">
        <label>Avtor</label>
        <input v-model="avtor" type="text" class="form-control">
        <label>Album</label>
        <input v-model="album" type="text" class="form-control">

        <label>Pesmi (vsaka v svoji vrsti)</label>
        <textarea class="form-control" rows="15" placeholder="Pesem 1" v-model="pesmiText"></textarea>
        <br />
        <button class="btn btn-primary" @click="pridobiIzBesedila">Pridobi pesmi</button>
        <button class="btn btn-primary" @click="odstraniCase">Odstrani čase</button>
      </div>
    </div>
    <div v-else>
      <div class="form-group">
        <label>Url seznama</label>
        <input v-model="urlSeznama" type="text" class="form-control">
        <br />
        <button class="btn btn-primary" @click="pridobiSeznam" :disabled="zakleniPridobivanje">Pridobi seznam</button>
      </div>
    </div>
    <div class="" v-if="prikazi && podatkiSeznama.pesmi">
      <table class="table table-striped">
        <thead>
          <tr>
            <th scope="col">#</th>
            <th scope="col">Naslov</th>
            <th scope="col">YouTube video</th>
            <th scope="col">Dodatno</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(pesem, i) in podatkiSeznama.pesmi" :key="i">
            <th scope="row">{{i + 1}}</th>
            <td>{{pesem.ime}}</td>
            <td v-if="prikazi && pesem.videoNaslov" :posodobi="update">{{pesem.videoNaslov}}</td>
            <td v-else></td>
            <td>
              <button type="button" class="btn btn-default" data-toggle="modal" data-target="#modal-izberi-pesem" @click="izbranaPesem = i">
                Zamenjaj video
              </button>
            </td>
          </tr>
        </tbody>
      </table>
      <button class="btn btn-primary" @click="naloziSeznam()">Naloži seznam</button>
    </div>

    <div class="modal fade" id="modal-izberi-pesem" v-if="podatkiSeznama.pesmi && podatkiSeznama.pesmi.length > izbranaPesem">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
            <h4 class="modal-title">Izberi pesem</h4>
          </div>
          <div class="modal-body">
            <ul class="nav nav-pills nav-stacked">
              <li v-for="moznost in podatkiSeznama.pesmi[izbranaPesem].izbire" :key="moznost.id">
                <a class="col-xs-8 pikice"><span class="">{{moznost.ime}}</span>
                </a>
                <a class=" col-xs-4" data-dismiss="modal" @click="podatkiSeznama.pesmi[izbranaPesem].videoNaslov = moznost.ime; podatkiSeznama.pesmi[izbranaPesem].videoId = moznost.id; posodobiPrikaz()"><span class="pull-right text-blue"><i class="fa fa-check"></i> Izberi</span></a>
              </li>
            </ul>
          </div>
        </div>
        <!-- /.modal-content -->
      </div>
      <!-- /.modal-dialog -->
    </div>
  </div>
</template>

<script>
import Rest from './../../../storitve/rest'

export default {
  components: {
  },
  name: 'NaloziSpotify',
  props: ['text', 'zakodiranSeznam'],
  data () {
    return {
      urlSeznama: "",
      spotifyPesmi: [],
      podatkiSeznama: {
        ime: "",
        slika: "",
      },
      ytApiKey: "",
      prikazi: true,
      izbranaPesem: 0,
      pesmiText: "",
      ime: "",
      urlSlike: "",
      avtor: "",
      album: "",
      zakleniPridobivanje: false
    }
  },
  computed: {
  },
  created () {
    this.pridobiAPIKljuc()
    if (this.zakodiranSeznam && this.zakodiranSeznam.length > 1) {
      let podatki = JSON.parse(atob(this.zakodiranSeznam))
      this.urlSlike = podatki.slika
      this.ime = podatki.album
      this.album = podatki.album
      this.avtor = podatki.avtor
      this.pesmiText = ""
      for (let i = 0; i < podatki.pesmi.length; i++) {
        this.pesmiText += podatki.pesmi[i] + "\n"
      }
    }
  },
  methods: {
    odstraniCase () {
      let pesmi = this.pesmiText.split("\n")
      let skupaj = ""
      for (let i = 0; i < pesmi.length; i++) {
        if (pesmi[i].trim() === "") {
          continue
        }
        skupaj += pesmi[i].trim().substr(pesmi[i].indexOf(" ") + 1) + "\n";
      }
      this.pesmiText = skupaj
    },
    naloziSeznam () {
      this.$swal.fire(
        'Uspeh',
        'Sznam bo naložen zaupaj :) če dobiš obvestilo o napaki je mogoče za brez veze. v vsakem primeru počakaj več minut preden poskusiš znova.',
        'success'
      )
      for (let i = 0; i < this.podatkiSeznama.pesmi.length; i++) {
        this.podatkiSeznama.pesmi[i].ime = this.podatkiSeznama.pesmi[i].videoNaslov
      }
      let th = this
      Rest.request('POST', `/pesmi/naloziSpotify`, this.podatkiSeznama).then((data) => {
        EventBus.$emit('sproziObvestila', 200);
        th.podatkiSeznama ={
          ime: "",
          slika: "",
        }
        th.$swal.fire(
          'Uspeh',
          'Seznam predvajanja je uspešno ustvarjen. Pesmi bodo kmalu naložene.',
          'success'
        )
      }).catch((err) => {
        console.log(err)
        // th.$swal.fire(
        //   'Težava',
        //   err,
        //   'error'
        // )
      })
      this.$router.push({path:'/naloge'})
    },
    pridobiIzBesedila () {
      let th = this
      let pesmi = this.pesmiText.split("\n")
      th.podatkiSeznama.ime = this.ime
      th.podatkiSeznama.slika = this.urlSlike
      th.podatkiSeznama.pesmi = []
      for (let i = 0; i < pesmi.length; i++) {
        if (pesmi[i].trim() === "") {
          continue
        }
        th.podatkiSeznama.pesmi.push({
          ime: pesmi[i],
          avtor: th.avtor,
          album: th.album,
        })
        th.pridobiPosnetke(th.podatkiSeznama.pesmi.length - 1)
      }
    },
    pridobiSeznam () {
      let th = this
      this.zakleniPridobivanje = true
      Rest.request('POST', '/pesmi/pridobiSpotify', {url: this.urlSeznama}).then((data) => {
          th.podatkiSeznama.ime = data.name
          th.podatkiSeznama.slika = data.image
          th.podatkiSeznama.pesmi = []
          th.spotifyPesmi = data.tracks
          for (let i = 0; i < th.spotifyPesmi.length; i++) {
            th.podatkiSeznama.pesmi.push({
              ime: th.spotifyPesmi[i].ime,
              avtor: th.spotifyPesmi[i].avtor,
              album: th.spotifyPesmi[i].album,
            })
            th.pridobiPosnetke(th.podatkiSeznama.pesmi.length - 1)
          }
          th.zakleniPridobivanje = false
      })
    },
    posodobiPrikaz () {
      this.prikazi = false
      this.$nextTick(function() {
        this.prikazi = true
      }) 
    },
    prijava () {
      console.log(window.location.href)
      // window.location.href = 'https://accounts.spotify.com/authorize?client_id=bbb4cea8a4414981ad3b3ba138b65811&redirect_uri=http%3A%2F%2Flocalhost%3A8090%2Fnalozi&scope=user-read-private%20user-read-email&response_type=token&state=123'
      window.location.href =
        'https://accounts.spotify.com/authorize?' + 
        'client_id=bbb4cea8a4414981ad3b3ba138b65811' + 
        '&response_type=token' +
        // '&scope=' + encodeURIComponent('user-read-private user-read-email') + 
        '&redirect_uri=' + encodeURIComponent(window.location.href)
    },

    pridobiAPIKljuc () {
      Rest.request('GET', `/apiKljuci/youtube`).then((data) => {
        this.ytApiKey = data.kljuc
      })
    },
    pridobiPosnetke (indexPesmi) {
      let geslo = this.podatkiSeznama.pesmi[indexPesmi].ime + ' ' + this.podatkiSeznama.pesmi[indexPesmi].avtor
      if (this.text) {
        geslo = this.podatkiSeznama.pesmi[indexPesmi].ime
      }
      // pridobi yt
      let apiKey = this.ytApiKey
      Rest.request('GET', 'https://www.googleapis.com/youtube/v3/search?part=snippet&q=' + encodeURIComponent(geslo) + '&type=video&key=' + apiKey).then((data) => {
        let ytPesmi = []
        for (let i = 0; i < Math.min(5, data.items.length); i++) {
          ytPesmi.push({
            ime: this.zamenjajZnake(data.items[i].snippet.title),
            opis: this.zamenjajZnake(data.items[i].snippet.description),
            id: data.items[i].id.videoId,
            slika: data.items[i].snippet.thumbnails.default.url,
            kanal: this.zamenjajZnake(data.items[i].snippet.channelTitle)
          })
        }
        console.log('=======================')
        console.log(ytPesmi)
        if (ytPesmi.length > 0) {
          this.podatkiSeznama.pesmi[indexPesmi].videoNaslov = ytPesmi[0].ime
          this.podatkiSeznama.pesmi[indexPesmi].videoId = ytPesmi[0].id
          this.podatkiSeznama.pesmi[indexPesmi].izbire = ytPesmi
          this.posodobiPrikaz()
        }
      }).catch((err) => {
        console.log(err)
      })
    },
    zamenjajZnake (besedilo) {
      besedilo = besedilo.split("&nbsp;").join(" ")
      besedilo = besedilo.split("&quot;").join("'")
      besedilo = besedilo.split("&amp;").join("&")
      besedilo = besedilo.split("&apos;").join("'")
      besedilo = besedilo.split("&lt;").join("<")
      besedilo = besedilo.split("&gt;").join(">")
      besedilo = besedilo.split("&gt;").join(">")
      return besedilo
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.clickable{
  cursor: pointer; 
}
</style>
