<template>
  <div>
    <div class="container">
    <section class="content-header">
      <h1>
        {{imeSeznama}}
        <small></small>
      </h1>
      <ol class="breadcrumb" style="margin-top: 1em">
        <li><a @click="$router.push('/')">Domov</a></li>
        <li><a @click="$router.push('/nalozeniSeznami')">Seznami predvajanja</a></li>
        <li><a @click="$router.push('/nalozeniSeznami/' + idSeznama)">{{imeSeznama}}</a></li>
      </ol>
    </section>
      <br />
      <div v-for="(pesem, index) in pesmi" :key="pesem.id">
        <div class="col-xs-12" @click="predvajajPesem(index)">
          <Pesem :naslov="pesem['ime-pesmi']" :avtor="pesem.avtor" barva="yellow" ikona="fa-music" :album="pesem.album">
            <div class="pull-right" @click.stop="">
              <div class="btn-group">
                <!-- <button type="button" class="btn btn-default" @click.prevent.stop="$router.push('/pesem/'+pesem.id)">
                  <i class="fa fa-cogs"></i>
                </button> -->
                <button type="button" class="btn btn-default" @click.prevent.stop="predvajajNaslednjo(index)">
                  <i class="fa fa-mail-forward"></i>
                </button>
                <!-- <button type="button" class="btn btn-default" @click.prevent.stop="naloziPesem(index)">
                  <i class="fa fa-download"></i>
                </button> -->
              </div>
            </div>
          </Pesem>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Rest from './../../storitve/rest'
import Vue from 'vue'
import { EventBus } from './../../storitve/event-bus'
import Pesem from './../skupno/Pesem.vue'

export default {
  components: {
    Pesem
  },
  name: 'seznam-predvajanja',
  props: ['idSeznama'],
  data () {
    return {
      pesmi: [],
      imeSeznama: "",
      seznam: {}
    }
  },
  created: function () {
    this.pridobiPesmi();
  },
  methods: {
    dostopniZeton() {
      return localStorage.getItem("sessionAccessToken");
    },
    predvajajNaslednjo (index) {
      this.$emit('predvajajNaslednjo', this.pesmi[index]);
      this.$swal.fire({
        position: 'top',
        title: 'Pesem dodana v vrsto za predvajanje',
        icon: 'success',
        showConfirmButton: false,
        timerProgressBar: true,
        backdrop: null,
        timer: 1000
      })
    },
    pridobiPesmi: function() {
      Rest.request('POST', `http://localhost:7777/api/v1/seznami/pesmiNaSeznamu`, {idSeznama: this.idSeznama}).then((data) => {
        this.pesmi = data.pesmi
        this.imeSeznama = data.imeSeznama
        this.seznam = data.seznam
        for (let i = 0; i < this.pesmi.length; i++) {
            this.pesmi[i].offline = true
        }
      }).catch((err) => {
        console.log(err)
      })
    },
    predvajajPesem: function(index) {
      this.$emit('predvajaj', [this.pesmi, index]);
    },
    click: function () {
      console.log('klik dela');
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
