import Vue from 'vue'
import Rest from './rest'

function listDir(path){
  return new Promise(resolve => {
    window.resolveLocalFileSystemURL(path,
      function (fileSystem) {
        var reader = fileSystem.createReader();
        reader.readEntries(
          function (entries) {
            resolve(entries);
          },
          function (err) {
            resolve(err);
            console.log(err);
          }
        );
      }, function (err) {
        resolve(err);
        console.log(err);
      }
    );
  })
  
}

function glasbenaMapa() {
  return window.cordova.file.dataDirectory + "/Documents"
}

function jeCordova () {
  return (window.hasOwnProperty("cordova"))
}

async function nalozenePesmi() {

  if (jeCordova()) {
    let imena = []
    let files = (await listDir(glasbenaMapa()))
    for (let i = 0; i < files.length; i++) {
      imena.push(files[i].name)
    }
    return imena
  } else {
    return []
  }
}

async function pesemZeNalozena(idPesmi) {
  let nalozena = false
  let files = (await listDir(glasbenaMapa()))
  for (let i = 0; i < files.length; i++) {
    if (files[i].name === idPesmi + ".mp3") {
      nalozena = true
    }
  }
  return nalozena
}

async function vecPesmiZeNalozenih(seznamPesmi) {
  let files = (await listDir(glasbenaMapa()))
  let odgovori = []
  let mnozica = new Set()
  for (let i = 0; i < files.length; i++) {
    mnozica.add(files[i].name)
  }
  console.log(mnozica)
  console.log(seznamPesmi)
  for (let i = 0; i < seznamPesmi.length; i++) {
    odgovori.push(mnozica.has(seznamPesmi[i] + ".mp3"))
  }
  return odgovori
}

function lokalniUrlPesmi(idPesmi) {
  return new Promise((resolve, reject) => {
    resolveLocalFileSystemURL(glasbenaMapa() + "/" + idPesmi + ".mp3", function (entry) {
      resolve(entry.toInternalURL())
    })
  })
}

function naloziPesem(idPesmi, brezObvestila, podcastUrl, preskociPreverjanjeNalozene) {
  return new Promise((resolve, reject) => {
    let url = 'https://glasba.knez.cloud/prenosPesmi/'+idPesmi+'?token='+localStorage.getItem("sessionAccessToken")
    if (podcastUrl) {
      url = podcastUrl
    }
    if (jeCordova()) {
      if (preskociPreverjanjeNalozene) {
        var fileTransfer = new FileTransfer();
        fileTransfer.download(
          url,
          glasbenaMapa() + "/" + idPesmi + ".mp3",
          function(entry) {
            if (!brezObvestila) {
              Vue.vm.$swal.fire({
                position: 'top',
                title: 'Pesem naložena',
                icon: 'success',
                showConfirmButton: false,
                timerProgressBar: true,
                backdrop: null,
                timer: 1000
              })
            }
            console.log('pesem nalozena')
            resolve(entry)
          },
          function(error) {
            Vue.vm.$swal.fire({
              position: 'top',
              title: JSON.stringify(error),
              description: JSON.stringify(error),
              icon: 'error',
              showConfirmButton: false,
              timerProgressBar: true,
              backdrop: null,
              timer: 10000
            })
            console.log("download error source " + error.source);
            console.log("download error target " + error.target);
            console.log("download error code" + error.code);
            reject (error)
          }
        );
      } else {
        pesemZeNalozena(idPesmi).then(nalozena => {
          if (!nalozena) {
            var fileTransfer = new FileTransfer();
            fileTransfer.download(
              url,
              glasbenaMapa() + "/" + idPesmi + ".mp3",
              function(entry) {
                if (!brezObvestila) {
                  Vue.vm.$swal.fire({
                    position: 'top',
                    title: 'Pesem naložena',
                    icon: 'success',
                    showConfirmButton: false,
                    timerProgressBar: true,
                    backdrop: null,
                    timer: 1000
                  })
                }
                console.log('pesem nalozena')
                resolve(entry)
              },
              function(error) {
                Vue.vm.$swal.fire({
                  position: 'top',
                  title: JSON.stringify(error),
                  description: JSON.stringify(error),
                  icon: 'error',
                  showConfirmButton: false,
                  timerProgressBar: true,
                  backdrop: null,
                  timer: 10000
                })
                console.log("download error source " + error.source);
                console.log("download error target " + error.target);
                console.log("download error code" + error.code);
                reject (error)
              }
            );
          }
        })
      }
    } else {
      window.open(url, "_blank")
    }
  })
}

function naloziSeznam(idSeznama) {
  Rest.request('POST', `/seznami/pesmi`, {idSeznama: idSeznama}).then((data) => {
    let seznami = []
    if (localStorage.getItem("seznami")) {
      seznami = JSON.parse(localStorage.getItem("seznami"))
    }
    for (let i = 0; i < seznami.length; i++) {
      if (seznami[i].id === idSeznama) {
        seznami.splice(i, 1)
        i--
      }
    }
    data.id = data.seznam.id
    seznami.push(data)
    localStorage.setItem("seznami", JSON.stringify(seznami))
    let pesmi = data.pesmi
    
    async function nalaganje () {
      let idPesmi = []
      for (let i = 0; i < pesmi.length; i++) {
        idPesmi.push(pesmi[i].id)
      }
      let nalozene = await vecPesmiZeNalozenih(idPesmi)
      console.log('zacetek nalaganja')
      for (let i = 0; i < pesmi.length; i++) {
        if (!nalozene[i]) {
          console.log("Nalaganje", i)
          await naloziPesem(pesmi[i].id, false, false, true)
          console.log(i)
        }
      }
    }
    nalaganje()
  }).catch((err) => {
    console.log(err)
  })
}

function obdelajSeznam(seznam) {
  return new Promise((resolve, reject) => {
    if (jeCordova() && seznam.pesmi) {
      let idPesmi = []
      for (let i = 0; i < seznam.pesmi.length; i++) {
        idPesmi.push(seznam.pesmi[i].id)
      }
      vecPesmiZeNalozenih(idPesmi).then(nalozene => {
        for (let i = 0; i < seznam.pesmi.length; i++) {
          if (nalozene[i]) {
            seznam.pesmi[i].nalozena = true
            seznam.pesmi[i].offline = true
            lokalniUrlPesmi(seznam.pesmi[i].id).then((url) => {
              seznam.pesmi[i].lokalniURL = url
            })
          } else {
            seznam.pesmi[i].nalozena = false
            seznam.pesmi[i].offline = false
          }
        }
        resolve(seznam)
      })
    } else {
      resolve(seznam)
    }
  })
}

function pridobiSeznam(idSeznama) {
  return new Promise((resolve, reject) => {
    if (jeCordova() && Vue.prototype.$brezPovezave) {
      let seznami = []
      if (localStorage.getItem("seznami")) {
        seznami = JSON.parse(localStorage.getItem("seznami"))
      }
      for (let i = 0; i < seznami.length; i++) {
        if (seznami[i].id === idSeznama) {
          obdelajSeznam(seznami[i]).then(seznam => {
            resolve(seznam)
          })
          break;
        }
      }
    } else {
      Rest.request('POST', `/seznami/pesmi`, {idSeznama: idSeznama}).then((data) => {
        obdelajSeznam(data).then(seznam => {
          resolve(seznam)
        })
      }).catch(err => reject(err))
    }
  })
}

function pridobiSeznamPaginirano(idSeznama, dodatno) {
  return new Promise((resolve, reject) => {
    if (jeCordova() && Vue.prototype.$brezPovezave) {
      let seznami = []
      if (localStorage.getItem("seznami")) {
        seznami = JSON.parse(localStorage.getItem("seznami"))
      }
      for (let i = 0; i < seznami.length; i++) {
        if (seznami[i].id === idSeznama) {
          obdelajSeznam(seznami[i]).then(seznam => {
            resolve(seznam)
          })
          break;
        }
      }
    } else {
      let objekt = dodatno
      objekt['idSeznama'] = idSeznama
      Rest.request('POST', `/seznami/pesmi`, objekt).then((data) => {
        obdelajSeznam(data).then(seznam => {
          resolve(seznam)
        })
      }).catch(err => reject(err))
    }
  })
}

function nalozeniSeznami() {
  let nalozeniSeznami = []
  let seznami = []
  if (localStorage.getItem("seznami")) {
    seznami = JSON.parse(localStorage.getItem("seznami"))
  }
  for (let i = 0; i < seznami.length; i++) {
    nalozeniSeznami.push(seznami[i].seznam)
  }
  return nalozeniSeznami
}

function pretvoriLokalniUrl(url) {
  if (jeCordova()) {
    if (url.startsWith("https://192.168.1.241:8090")) {
      url = url.substring("https://192.168.1.241:8090".length)
    }
    if (url.startsWith("file://")) {
      url = url.substring("file://".length)
    }
    if (url.startsWith("/")) {
      url = "https://glasba.knez.cloud" + url
    }
  }
  return url
}

function naloziPodcast(idPodcasta, idEpizode) {
  return new Promise((resolve, reject) => {
    pesemZeNalozena(idEpizode).then((nalozeno) => {
      if (!nalozeno) {
        Rest.request('POST', `/podcasti/epizode`, {idPodcasta: idPodcasta, vse: false}).then((data) => {
          let podcasti = []
          if (localStorage.getItem("podcasti")) {
            podcasti = JSON.parse(localStorage.getItem("podcasti"))
          }
          for (let i = 0; i < podcasti.length; i++) {
            if (podcasti[i].id === idPodcasta) {
              podcasti.splice(i, 1)
              i--
            }
          }
          podcasti.push(data)
          localStorage.setItem("podcasti", JSON.stringify(podcasti))
      
          let epizode = data.epizode
          for (let i = 0; i < epizode.length; i++) {
            if (epizode[i].id === idEpizode) {
              if (epizode[i].ytEpizoda) {
                naloziPesem(epizode[i].id, false, "https://glasba.knez.cloud/podcast/" + epizode[i].nalozenaEpizodaId).then(() => {
                  resolve("nalozeno")
                }).catch((err) => {
                  reject(err)
                })
              } else {
                naloziPesem(epizode[i].id, false, epizode[i].epizodaUrl).then(() => {
                  resolve("nalozeno")
                }).catch((err) => {
                  reject(err)
                })
              }
              break
            }
          }
        }).catch((err) => {
          console.log(err)
        })
      } else {
        Vue.vm.$swal.fire({
          position: 'top',
          title: 'Epizoda je že bila naložena',
          icon: 'success',
          showConfirmButton: false,
          timerProgressBar: true,
          backdrop: null,
          timer: 1000
        })
        resolve("nalozeno")
      }
    })
  })
}

function pridobiPodcastEpizodo(idPodcasta, idEpizode) {
  return new Promise((resolve, reject) => {
    if (jeCordova() && Vue.prototype.$brezPovezave) {
      let podcast = {}
      let podcasti = []
      if (localStorage.getItem("podcasti")) {
        podcasti = JSON.parse(localStorage.getItem("podcasti"))
      }
      for (let i = 0; i < podcasti.length; i++) {
        if (podcasti[i].id === idPodcasta) {
          podcast = podcasti[i]
          break
        }
      }

      let epizoda = {}
      for (let i = 0; i < podcast.epizode.length; i++) {
        if (podcast.epizode[i].id === idEpizode) {
          epizoda = podcast.epizode[i]
        }
      }

      obdelajPodcastEpizodo(epizoda).then( (ep) => {
        resolve(ep)
      })
    } else {
      Rest.request('POST', `/podcasti/epizoda`, {idEpizode: idEpizode}).then((epizoda) => {
        obdelajPodcastEpizodo(epizoda).then( (ep) => {
          resolve(ep)
        })
      }).catch((err) => {
        reject(err)
      })
    }
  })
}

function pridobiPodcast(idPodcasta, vse) {
  return new Promise((resolve, reject) => {
    if (jeCordova() && Vue.prototype.$brezPovezave) {
      let podcast = {}
      let podcasti = []
      if (localStorage.getItem("podcasti")) {
        podcasti = JSON.parse(localStorage.getItem("podcasti"))
      }
      for (let i = 0; i < podcasti.length; i++) {
        if (podcasti[i].id === idPodcasta) {
          podcast = podcasti[i]
          break
        }
      }

      let obljube = []
      for (let i = 0; i < podcast.epizode.length; i++) {
        obljube.push(obdelajPodcastEpizodo(podcast.epizode[i]))
      }

      Promise.all(obljube).then((odgovori) => {
        podcast.epizode = odgovori
        console.log(podcast)
        resolve(podcast)
     });
    } else {
      Rest.request('POST', `/podcasti/epizode`, {idPodcasta: idPodcasta, vse: vse}).then((podcast) => {
        let obljube = []
        for (let i = 0; i < podcast.epizode.length; i++) {
          obljube.push(obdelajPodcastEpizodo(podcast.epizode[i]))
        }

        Promise.all(obljube).then((odgovori) => {
          podcast.epizode = odgovori
          resolve(podcast)
        })
      })
    }
  })
}

function obdelajPodcastEpizodo(epizoda) {
  return new Promise((resolve, reject) => {
    if (jeCordova()) {
      let casiPodcastov = []
      if (localStorage.getItem("casiPodcastov")) {
        casiPodcastov = JSON.parse(localStorage.getItem("casiPodcastov"))
      }
      for (let i = 0; i < casiPodcastov.length; i++) {
        if (casiPodcastov[i].idEpizode === epizoda.id) {
          epizoda.mestoPoslusanjaSekunde = casiPodcastov[i].cas
          if (casiPodcastov[i].trajanje) {
            epizoda.trajanjeSekunde = casiPodcastov[i].trajanje
          }
        }
      }
      pesemZeNalozena(epizoda.id).then( (nalozena) => {
        if (nalozena) {
          epizoda.nalozena = true
          lokalniUrlPesmi(epizoda.id).then((url) => {
            epizoda.epizodaUrl = url
            resolve(epizoda)
          })
        } else {
          resolve(epizoda)
        }
      })
    } else {
      resolve(epizoda)
    }
  })
}

function pridobiPodcaste() {
  return new Promise((resolve, reject) => {
    if (jeCordova() && Vue.prototype.$brezPovezave) {
      let podcasti = []
      if (localStorage.getItem("podcasti")) {
        podcasti = JSON.parse(localStorage.getItem("podcasti"))
      }
      for (let i = 0; i < podcasti.length; i++) {
        delete podcasti[i].epizode
        delete podcasti[i].narocnina
      }
      resolve(podcasti)
    } else {
      Rest.request("POST", `/podcasti/moji`, {})
      .then(data => {
        resolve(data);
      })
      .catch(err => {
        reject(err);
      });
    }
  })
  
}

function posljiCasPodcasta(podatki) {
  return new Promise((resolve, reject) => {
    let podcasti = []
    if (localStorage.getItem("podcasti")) {
      podcasti = JSON.parse(localStorage.getItem("podcasti"))
    }
    for (let i = 0; i < podcasti.length; i++) {
      for (let j = 0; j < podcasti[i].epizode.length; j++) {
        if (podcasti[i].epizode[j].id === podatki.idEpizode) {
          podcasti[i].epizode[j].mestoPoslusanjaSekunde = podatki.cas
          if (podatki.trajanje) {
            podcasti[i].epizode[j].trajanjeSekunde = podatki.trajanje
          }
        }
      }
    }
    localStorage.setItem("podcasti", JSON.stringify(podcasti))


    if (jeCordova() && Vue.prototype.$brezPovezave) {
      let casiPodcastov = []
      if (localStorage.getItem("casiPodcastov")) {
        casiPodcastov = JSON.parse(localStorage.getItem("casiPodcastov"))
      }
      for (let i = 0; i < casiPodcastov.length; i++) {
        if (casiPodcastov[i].idEpizode === podatki.idEpizode) {
          casiPodcastov.splice(i, 1)
          i--
        }
      }
      casiPodcastov.push(podatki)
      localStorage.setItem("casiPodcastov", JSON.stringify(casiPodcastov))
      resolve(null)
    } else {
      Rest.request("POST", `/podcasti/nastaviCas`, podatki)
        .then(data => {resolve(data)})
        .catch(err => {
          reject(err);
        });

      let casiPodcastov = []
      if (localStorage.getItem("casiPodcastov")) {
        casiPodcastov = JSON.parse(localStorage.getItem("casiPodcastov"))
      }
      for (let i = 0; i < casiPodcastov.length; i++) {
        Rest.request("POST", `/podcasti/nastaviCas`, casiPodcastov[i])
        .then(data => {})
        .catch(err => {});
      }
      localStorage.setItem("casiPodcastov", JSON.stringify([]))
    }
  })
}

function vsePesmiNaNalozenihSeznamih() {
  let vsePesmi = new Set()
  let seznami = []
  if (localStorage.getItem("seznami")) {
    seznami = JSON.parse(localStorage.getItem("seznami"))
  }
  for (let i = 0; i < seznami.length; i++) {
    for (let j = 0; j < seznami[i].pesmi.length; j++) {
      vsePesmi.add(seznami[i].pesmi[j].id)
    }
  }

  let podcasti = []
  if (localStorage.getItem("podcasti")) {
    podcasti = JSON.parse(localStorage.getItem("podcasti"))
  }
  for (let i = 0; i < podcasti.length; i++) {
    for (let j = 0; j < podcasti[i].epizode.length; j++) {
      vsePesmi.add(podcasti[i].epizode[j].id)
    }
  }
  console.log(vsePesmi)
  return vsePesmi
}

function odstraniNeuporabljenePesmi() {
  nalozenePesmi().then(nalozene => {
    let uporabljenePesmi = vsePesmiNaNalozenihSeznamih()
    for (let i = 0; i < nalozene.length; i++) {
      let idPesmi = nalozene[i].split(".")[0]
      if (!uporabljenePesmi.has(idPesmi)) {
        lokalniUrlPesmi(idPesmi).then(naslovPesmi => {
          window.resolveLocalFileSystemURL (naslovPesmi, 
            function (fileEntry) { 
              fileEntry.remove(
                function () { 
                  console.log('pesem odstranjena')
                }, 
                function (error) {
                    alert('Unable to remove file.');
                }
              ); 
            } 
          ); 
        })
      }
    }
  })
}

function izbrisiPesemPoId(idPesmi) {
  lokalniUrlPesmi(idPesmi).then(naslovPesmi => {
    window.resolveLocalFileSystemURL (naslovPesmi, 
      function (fileEntry) { 
        fileEntry.remove(
          function () { 
            console.log('pesem odstranjena')
          }, 
          function (error) {
              alert('Unable to remove file.');
          }
        ); 
      } 
    ); 
  })
}

function odstraniSeznam(idSeznama) {
  let seznami = []
  if (localStorage.getItem("seznami")) {
    seznami = JSON.parse(localStorage.getItem("seznami"))
  }
  for (let i = 0; i < seznami.length; i++) {
    if (seznami[i].id === idSeznama) {
      seznami.splice(i, 1)
      i--
    }
  }
  localStorage.setItem("seznami", JSON.stringify(seznami))
  odstraniNeuporabljenePesmi()
}

let nalaganje = null
nalaganje = {
    jeCordova,
    nalozenePesmi,
    pesemZeNalozena,
    naloziPesem,
    lokalniUrlPesmi,
    pretvoriLokalniUrl,
    naloziSeznam,
    pridobiSeznam,
    nalozeniSeznami,
    naloziPodcast,
    pridobiPodcast,
    pridobiPodcastEpizodo,
    pridobiPodcaste,
    posljiCasPodcasta,
    vecPesmiZeNalozenih,
    obdelajSeznam,
    odstraniNeuporabljenePesmi,
    odstraniSeznam,
    vsePesmiNaNalozenihSeznamih,
    izbrisiPesemPoId,
    pridobiSeznamPaginirano
}
export default nalaganje