<template>
  <div>
    <div class="container">
      <section class="content-header">
        <h1>
          Skupno poslušanje
          <small></small>
        </h1>
        <ol class="breadcrumb">
          <li><a @click="$router.push('/')">Domov</a></li>
          <!-- <li><a @click="$router.push('/skupine')">Skupine</a></li> -->
        </ol>
      </section>
      <br/>
      <div class="col-md-12">
        <h4 class="text-center">Začelo se je skupinsko predvajanje glasbe.</h4>
        <span class="text-center">Če se predvajanje ne začne samo, poskusi pritisniti tipko <i class="fa fa-play"></i></span>
        <div class="text-center text-muted" style="font-size: 300px; opacity: 0.4;">
          <i class="fa fa-headphones"></i>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Rest from './../../storitve/rest'

export default {
  components: {
  },
  name: 'SkupnoPoslusanje',
  props: ['idPredvajanja'],
  data () {
    return {
    }
  },
  created: function () {
    this.pridobiPesem()
  },
  methods: {
    pridobiPesem: function() {
      if (!localStorage.getItem("sessionAccessToken") || localStorage.getItem("sessionAccessToken").split(".").length !== 3) {
        localStorage.setItem("sessionAccessToken", this.idPredvajanja)
      }
      let postdata = {
        idPoslusanja: this.idPredvajanja
      }
      Rest.request('POST', `/skupnoPoslusanje/pridobiSkupnoPoslusanje`, postdata).then((data) => {
        let pesem = data.pesem
        pesem.mestoPoslusanjaSekunde = data.sekunde
        pesem.mestoPoslusanjatrenutek = new Date().getTime()
        pesem.idSkupnegaPoslusanja = data.id
        this.$emit('predvajajPodcast', pesem);
      }).catch((err) => {
        console.log(err)
      })
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
