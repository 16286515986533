<template>
  <div>
    <div class="container">
      <section class="content-header">
        <h1>
          {{naslov}}
          <small></small>
        </h1>
        <ol class="breadcrumb">
          <li><a @click="$router.push('/')">Domov</a></li>
          <li><a @click="$router.push('/poglej-pesem/'+idPesmi)">{{naslov}}</a></li>
        </ol>
      </section>
      <br />
      <div class="col-xs-12">
        <div class="box box-primary">
          <div class="box-header with-border">
            <h3 class="box-title">Predvajaj pesem</h3>
            <button class="btn btn-primary pull-right" @click="$router.push('/pesem/'+idPesmi)"><i class="fa fa-gears"></i></button>
          </div>
          <!-- /.box-header -->
          <!-- form start -->
          <div class="box-body" style="text-align: center;">
            <div style="max-height: 400px; overflow: auto;">
              <!-- pesem slika -->
              <div class="ikona-pesmi" v-if="!urlSlike">
                <i class="fa fa-music"></i>
              </div>
              <div v-else>
                <div class="img-thumbnail">
                  <div
                    :style="`background:url('` + urlSlike + `') center no-repeat; height:204px; width:204px;`"
                  ></div>
                </div>
              </div>
              <br/>
              <span style="font-size: 18px">{{naslov}}</span>
              <br />
              <span>Avtor: {{avtor}}</span>
              <br />
              <span>Album: {{album}}</span>
              <br/>
              <br/>
              <button class="btn btn-block btn-primary" @click="predvajajPesem()"><i class="fa fa-play"></i> Predvajaj</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Rest from './../../storitve/rest'

export default {
  components: {
  },
  name: 'koren',
  props: ['idPesmi'],
  data () {
    return {
      album: "",
      avtor: "",
      naslov: "",
      seznami: [],
      koncano: false,
      skrita: false,
      iskalnoGeslo: "",
      urlSlike: null,
      pesem: null
    }
  },
  created: function () {
    this.naloziPesem()
  },
  computed: {
    filtriraniSeznami () {
      return this.seznami.filter(el => el.imeSeznama.toLowerCase().indexOf(this.iskalnoGeslo.toLowerCase()) >= 0)
    }
  },
  methods: {
    predvajajPesem () {
      this.$emit('predvajaj', [[this.pesem], 0]);
    },
    pridobiUrlSlike(video_id) {
      this.urlSlike = "https://i.ytimg.com/vi/" + video_id + "/hqdefault.jpg";
    },
    izbrisiPesem: function() {
      this.$swal.fire({
        title: 'Želiš izbrisati pesem?',
        text: "Brisanja pesmi se ne da preklicati.",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Da',
        cancelButtonText: 'Ne'
      }).then((result) => {
        if (result.value) {
          console.log('ok')
          let podatki = {
            idPesmi: this.idPesmi
          }

          Rest.request("POST", `/pesem/izbrisi`, podatki)
            .then(data => {
              this.$router.push('/')
            })
            .catch(err => {
              console.log(err);
              // th.opozorila.push(err.status + " " + err.statusText);
            });
        }
      })
    },
    naloziPesem: function() {
      let podatki = {
        idPesmi: this.idPesmi
      }
      Rest.request('POST', `/pesem/podrobnosti`, podatki).then((data) => {
        this.album = data.album
        this.avtor = data.avtor
        this.naslov = data['ime-pesmi']
        this.seznami = data.seznami
        this.skrita = data.skrita
        if (data.ytId) {
          this.pridobiUrlSlike(data.ytId)
        }
        this.pesem = data
      }).catch((err) => {
        console.log(err)
      })
    },
    posodobiPesem: function() {
      let spremenjeniSeznami = []
      for (let i = 0; i < this.seznami.length; i++) {
        if (this.seznami[i].spremenjeno) {
          spremenjeniSeznami.push(this.seznami[i])
        }
      }
      let podatki = {
        idPesmi: this.idPesmi,
        album: this.album,
        avtor: this.avtor,
        naslov: this.naslov,
        seznami: spremenjeniSeznami,
        skrita: this.skrita
      }
      Rest.request('POST', `/pesem/podrobnosti/posodobi`, podatki).then((data) => {
        this.album = data.album
        this.avtor = data.avtor
        this.naslov = data['ime-pesmi']
        this.seznami = data.seznami
        this.$swal.fire(
            'Uspešno posodobljeno',
            'Pesem uspešno posodobljena',
            'success'
          )
      }).catch((err) => {
        console.log(err)
      })
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.ikona-pesmi {
  height: 150px;
  width: 150px;
  text-align: center;
  font-size: 75px;
  background: #3c8dbc;
  color: white;
  vertical-align: middle;
  padding: 20px;
  margin-left: auto;
  margin-right: auto;
}
.slika-pesmi {
  height: 150px;
  /* width: 150px; */
  text-align: center;
  font-size: 75px;
  /* background: #3c8dbc; */
  color: white;
  vertical-align: middle;
  margin-left: auto;
  margin-right: auto;
}
</style>
