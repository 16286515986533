import Vue from 'vue'
import App from './App.vue'
import router from './router/router.js'
import Spotify from 'spotify-web-api-node'
import VueSpotify from 'vue-spotify'
require("./../bower_components/jquery/dist/jquery.min.js")
require("./../bower_components/bootstrap/dist/js/bootstrap.min.js")
require("./../bower_components/jquery-slimscroll/jquery.slimscroll.min.js")
require("./../bower_components/fastclick/lib/fastclick.js")
require("./../bower_components/pace/pace.min.js")
require("./../staticno/js/adminlte.min.js")

Vue.config.productionTip = false

const vm = new Vue({
  render: h => h(App),
  router: router
})
vm.$mount('#app')
Vue.vm = vm
// new Vue({
//   render: h => h(App)
// }).$mount('#app')

Vue.use(VueSpotify, new Spotify())

// const prod = process.env.NODE_ENV === 'production'
console.log('delanje service workerja')
const prod = true
const shouldSW = 'serviceWorker' in navigator && prod
if (shouldSW) {
  console.log('pred registracijo')
  navigator.serviceWorker.register('/service-worker.js').then(() => {
    console.log("Service Worker Registered!")
  })
} else {
  console.log('service worker ni na voljo')
}