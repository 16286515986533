<template>
  <div>
    <div class="container">
    <section class="content-header">
      <h1>
        Posnetki
        <small></small>
      </h1>
      <ol class="breadcrumb" style="margin-top: 1em">
        <li><a @click="$router.push('/')">Domov</a></li>
        <li><a @click="$router.push('/posnetki')">Posnetki</a></li>
      </ol>
    </section>
      <br />
      <div class="col-md-12">
        <div class="box box-primary">
          <div class="box-header with-border">
            <h3 class="box-title">Nalozi posnetek</h3>
          </div>
          <!-- /.box-header -->
          <!-- form start -->
          <form>
            <div class="box-body">
              <div class="form-group">
                <label>YouTube link</label>
                <input v-model="povezava" type="text" class="form-control" placeholder="https://www.youtube.com/watch?v=xWjOh0Ph8uM" @change="pridobiPodatke()">
              </div>

              <div class="form-group">
                <label>Ime posnetka</label>
                <input v-model="ime" type="text" class="form-control">
              </div>

              <div class="form-group">
                <label>Kvaliteta</label>
                <select class="form-control" v-model="kvaliteta">
                  <option value="1080">1080p</option>
                  <option value="720">720p</option>
                  <option value="480">480p</option>
                  <option value="360">360p</option>
                  <option value="240">240p</option>
                </select>
              </div>
            </div>
            <div class="box-footer">
              <button @click.prevent="naloziPosnetek()" class="btn btn-primary">Potrdi</button>
            </div>
          </form>
        </div>
      </div>
      <div v-for="(pesem) in posnetki" :key="pesem.ime">
        <div class="col-xs-12">
          <Pesem :naslov="pesem['ime']" :avtor="(Math.round(pesem.velikost / 100000) / 10) + ' MB'" barva="red" ikona="fa-youtube-play" :album="pesem.datum">
            <div class="pull-right">
              <div class="btn-group">
                <div class="nav">
                  <button type="button" class="btn btn-default dropdown-toggle" data-toggle="dropdown">
                    <i class="fa fa-caret-down"></i>
                  </button>
                  <ul class="dropdown-menu" style="left:-130px">
                    <!-- <li><a href="#" @click="prenesiInIzbrisiVideo(pesem.ime)">Prenesi in izbriši</a></li> -->
                    <li><a href="#" @click="prenesiVideo(pesem.ime)">Samo prenesi</a></li>
                    <li><a href="#" @click="izbrisiVideo(pesem.ime)">Izbriši</a></li>
                  </ul>
                </div>
                <!-- <button type="button" class="btn btn-default" @click.prevent.stop="naloziPesem(index)">
                  <i class="fa fa-download"></i>
                </button> -->
              </div>
            </div>
          </Pesem>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import Rest from './../../storitve/rest'
import Vue from 'vue'
import Axios from 'axios'
import { EventBus } from './../../storitve/event-bus'
import Pesem from './../skupno/Pesem.vue'

export default {
  components: {
    Pesem
  },
  name: 'Video',
  props: ['idSeznama'],
  data () {
    return {
      posnetki: [],
      povezava: "",
      ime: "",
      kvaliteta: 480,
      stPreverjanjVideov: 1,
      intervalid1: null
    }
  },
  created: function () {
    th.intervalid1 = setInterval(() => {
      if (th.stPreverjanjVideov > 0) {
        th.stPreverjanjVideov--
        th.pridobiPosnetke()
      }
    },3000);
  },
  beforeDestroy () {
    clearInterval(this.intervalid1)
  },
  created: function () {
    this.pridobiPosnetke();
  },
  beforeDestroy () {
  },
  methods: {
    dostopniZeton() {
      return localStorage.getItem("sessionAccessToken");
    },
    izbrisiVideo(ime) {
      Rest.request('POST', `/videi/izbrisi`, {ime: ime}).then((data) => {
        this.pridobiPosnetke()
      }).catch((err) => {
        console.log(err)
      })
    },
    prenesiVideo(ime) {
      // this.downloadItem('/video/'+ime, ime, () => {console.log('koncano')});
      window.open(window.location.origin + '/video/'+encodeURI(ime), "_blank");
    },
    prenesiInIzbrisiVideo(ime) {
      this.downloadItem('/video/'+ime, ime, () => {this.izbrisiVideo(ime)});
      // window.open(window.location.origin + '/video/' + ime, "_blank");
    },
    downloadItem (url, label, callback) {
      url = window.location.origin + url
      console.log(url)
      Axios.get(url, { responseType: 'blob' })
        .then(response => {
          const blob = new Blob([response.data], { type: 'video/mp4' })
          const link = document.createElement('a')
          link.href = URL.createObjectURL(blob)
          link.download = label
          link.click()
          URL.revokeObjectURL(link.href)
          callback()
        }).catch(console.error)
    },
    naloziPosnetek () {
      if (this.ime.length < 3) {
        this.$swal.fire({
          position: 'top',
          title: 'Ime mora imeti vsaj 3 znake.',
          icon: 'error',
          showConfirmButton: false,
          timerProgressBar: true,
          backdrop: null,
          timer: 1000
        })
      } else {
        Rest.request('POST', `/videi/nalozi`, {povezava: this.povezava, ime: this.ime, kvaliteta: this.kvaliteta}).then((data) => {
          this.$swal.fire({
            position: 'top',
            title: 'Posnetek bo kmalu naložen',
            icon: 'success',
            showConfirmButton: false,
            timerProgressBar: true,
            backdrop: null,
            timer: 1000
          })
          EventBus.$emit('sproziObvestila', 20);
          this.stPreverjanjVideov = 200
          this.$router.push('/naloge')
        }).catch((err) => {
          console.log(err)
        })
      }
    },
    pridobiPosnetke: function() {
      Rest.request('POST', `/videi/nalozeni`, {}).then((data) => {
        this.posnetki = data
      }).catch((err) => {
        console.log(err)
      })
    },
    pridobiPodatke() {
      if (this.playlist) {
        return
      }
      if (!this.povezava.match(/https?:\/\/(www|m)\.youtube.com\/watch\?v=...........(&list=.*)?/) && !this.povezava.match(/https?:\/\/youtu.be\/.........../)){
        return
      }
      let th = this
      Rest.request('GET', `/apiKljuci/youtube`).then((data) => {
          let apiKey = data.kljuc
        let video_id;
        if (this.povezava.indexOf('v=') != -1){
          video_id = this.povezava.split('v=')[1];
        } else {
          video_id = this.povezava.substring(this.povezava.length - 11, this.povezava.length)
        }
        let ampersandPosition = video_id.indexOf('&');
        if(ampersandPosition != -1) {
          video_id = video_id.substring(0, ampersandPosition);
        }
        Rest.request('GET', 'https://www.googleapis.com/youtube/v3/videos?part=snippet&id=' + video_id + '&key=' + apiKey).then((data) => {
          let naslov = data.items[0].snippet.localized.title
          this.ime = naslov
        }).catch((err) => {
          console.log(err)
        })
      })
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
