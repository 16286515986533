<template>
  <div style="text-align:center; cursor: pointer">
    <div style="color: white">
      <span class="" style="position: absolute; left: 23px; top:17px; font-size: 24px;" v-if="novo">
        <i class="fa fa-asterisk"></i>
      </span>
      <span class="" style="position: absolute; left: 23px; top:17px; font-size: 16px;" v-if="vSkupini">
        <i class="fa fa-group"></i>
      </span>
      <span class="" style="position: absolute; left: 23px; top:17px; font-size: 20px;" v-else-if="zasebno">
        <i class="fa fa-lock"></i>
      </span>
      <span class="" style="position: absolute; right: 23px; top:17px; font-size: 30px;" v-if="mapa">
        <i class="fa fa-folder-o"></i>
      </span>
      <button v-if="predvajaj" class="btn btn-circle btn-default" :style="'text-aligh: center; vertical-align: middle; height:35px; width:35px; padding: 0px; position: absolute; left: 21px; top: ' + (sirinaSlike - 32) + 'px; font-size: 18px;'"
      @click.stop.prevent="$emit('play')">
        <span class="" style="padding-left:4px">
          <i class="fa fa-play"></i>
        </span>
      </button>
      
      <button v-if="premesaj" class="btn btn-circle btn-default" :style="'text-aligh: center; vertical-align: middle; height:35px; width:35px; padding: 0px; position: absolute; left: ' + (predvajaj ? 61 : 21) + 'px; top: ' + (sirinaSlike - 32) + 'px; font-size: 18px;'"
      @click.stop.prevent="$emit('shuffle')">
        <span class="" style="padding-left:0px">
          <i class="fa fa-random"></i>
        </span>
      </button>
    </div>
    <div class="thumbnail" style="margin-bottom: 0px" @contextmenu.stop.prevent="meni = !meni" @mouseover="hover = true"
    @mouseleave="hover = false">
      <div>
        <img ref="slika" class="" :style="'width:100%; ' + visinaSlike" :src ="nalaganje.pretvoriLokalniUrl(slika)" />
      </div>
      <div v-if="naslov" class="caption pikice">
        {{naslov}}
      </div>
      <div v-if="meni" class="caption pikice">
        <slot></slot>
      </div>
    </div>
    <!-- <span style="position: relative; top: -30px; color: white; font-size: 20px">{{naslov}}</span> -->
  </div>
</template>

<script>
import nalaganje from './../../storitve/nalaganje-pesmi'

export default {
  components: {
  },
  name: 'podcast',
  props: ['naslov', 'slika', 'zasebno', 'vSkupini', 'mapa', 'eventi', 'novo', 'niKvadrat'],
  data () {
    return {
      visinaSlike: '',
      meni: false,
      sirinaSlike: 0,
      hover: false,
      nalaganje: nalaganje,
    }
  },
  computed: {
    predvajaj() {
      return this.eventi && this.eventi.includes('play')
    },
    premesaj() {
      return this.eventi && this.eventi.includes('shuffle')
    }
  },
  mounted() {
    this.nastaviVisino()
    window.addEventListener("resize", this.resizeHandeler);
    let th = this
    setTimeout(function () {
      th.nastaviVisino();
    }, 2000)
  },
  destroyed() {
    window.removeEventListener("resize", this.resizeHandeler);
  },
  methods: {
    resizeHandeler (e) {
      this.nastaviVisino()
    },
    nastaviVisino () {
      if (!this.niKvadrat) {
        this.visinaSlike = 'height: ' + this.$refs.slika.clientWidth + 'px';
        this.sirinaSlike = this.$refs.slika.clientWidth;
      }
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
  .pikice{
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }
  .info-box {
    cursor: pointer;
  }
  .flip-card {
    background-color: transparent;
    perspective: 1000px;
  }

  .flip-card-inner {
    position: relative;
    width: 100%;
    height: 100%;
    text-align: center;
    transition: transform 0.6s;
    transform-style: preserve-3d;
    box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
  }

  .flip-card:hover .flip-card-inner {
    transform: rotateY(180deg);
  }

  .flip-card-front, .flip-card-back {
    position: absolute;
    width: 100%;
    height: 100%;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
  }

  .flip-card-front {
    background-color: #bbb;
    /* color: black; */
  }

  .flip-card-back {
    background-color: #2980b9;
    color: white;
    transform: rotateY(180deg);
  }
  .btn-circle {
    border-radius: 50%;
  }
</style>
